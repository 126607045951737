<template>
	<div class="main">
		<div class="loginbox">
			<div class="bgbox">
				<img src="../../assets/image/login_bg.jpg" alt="" />
			</div>
			<div class="logins">
				<div class="weixin" @click="loginClick">
					<img src="../../assets/image/weixin_logo.png"  alt=""/>
					点击直接微信登录
				</div>
			</div>
		</div>

		<el-dialog
			:visible.sync="isShow"
			width="560px">
			<div><iframe id="loginer" :src="iframeSrc" frameborder="0" scrolling="no" width="560" height="500" style=" margin:0 auto;"></iframe></div>
		</el-dialog>

	</div>
</template>

<script>
	import ApiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			return {
				isShow: false,
				iframeSrc: '',
				tabList: []
			};
		},
		created() {
			var option = {
				code: this.$route.query.code || "",
			};
			if (option && option.code) {
				this.wxlogin();
			}
		},
		methods: {
			loginClick() {
				this.isShow = true;
				this.wxlogin();
			},
			wxlogin() {
				var that = this;
				var option = {
					code: this.$route.query.code || "",
				};
				var param = {};
				if (option && option.code) {
					param = { code: option.code }
				} else {
					param = { page_url: window.location.href }
				}
				this.Ajax(ApiUrl.user_weixin_login, param, function(data, code) {
					if (code == 0) {
						sessionStorage.setItem("userLogInInfo", JSON.stringify(data));
						that.GoToChangeTab(1);
					} else if (code == 40002) {
						that.iframeSrc = data.href;
						console.log(JSON.stringify(data))
					} else {
						that.isShow = false;
						that.$message({
							message: '抱歉，您不是企业用户，暂无法登录',
							type: 'warning',
							offset: 330,
							duration: 2000
						});
						that.$router.go(0)
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	html,
	body {
		width: 100%;
		height: 100%;
		background: #f7f7f7;
	}

	.main {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;

		.loginbox {
			display: flex;
			flex-direction: row;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

			.bgbox {
				width: 568px;
				height: 600px;

				img {
					width: 568px;
					height: 600px;
				}
			}

			.logins {
				width: 568px;
				height: 600px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #FFFFFF;

				.weixin {
					width: 75%;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					background-color: #07C160;
					color: #FFFFFF;
					border-radius: 10px;
					height: 49px;

					img {
						height: 30px;
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>
