<template>
  <div id="app">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
      duration="500"
      appear
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import "animate.css";
export default {
  beforeCreate() {
    document.title = "机工新阅读";
  },

  methods: {},
};
</script>
<style lang="scss">
	

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f7f7f7;
}
.appPC {
  min-width: 1157px;
  overflow: auto;
}
.van-image__img {
  object-fit: cover;
  overflow: auto;
}
.el-image {
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
}
</style>
