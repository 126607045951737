<template>
	<div class="aplayer">
		<div class="box">
			<audio style="margin-top: -9999999;"
				ref="audio"
				@canplay="canplayFunc"
				@timeupdate="timeupdateFunc"
				src="http://wechatappdev-10011692.file.myqcloud.com/apprToXjJCE2967/audio/ab414f7801ee8da5c41ea43bd09c9ef5/ab414f7801ee8da5c41ea43bd09c9ef5press.mp3">
			</audio>
			<div class="control">
				<img src="../../assets/image/up.png" alt="" width="15px" @click="prev" />
				<img src="../../assets/image/audio.png" alt="" width="40px" v-if="paused" @click="clickFunc('play')" />
				<img src="../../assets/image/pause.png" alt="" width="40px" v-if="!paused" @click="clickFunc('pause')" />
				<img src="../../assets/image/down.png" alt="" width="15px" @click="next" />
			</div>
			<div class="cover">
				<img src="https://images.hzmedia.com.cn/11120620/m/20200309145237995.jpg" style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;" />
				<img class="icon" src="../../assets/image/ermai.png" alt="" />
			</div>
			<div class="wrapper">
				<div class="title">第一特性：跨越创新的第二曲线</div>
				<div class="slider">
					<span class="timebar">{{format(current)}}</span>
					<el-slider v-model="current" :min="0" :max="duration" :format-tooltip="format" @input="sliderInput"
						@change="sliderChange"></el-slider>
					<span class="timebar" v-if="duration != null">{{format(duration)}}</span>
					<span class="timebar" v-if="duration == null && tagData && tagData.audio_length">{{tagData.audio_length}}</span>
				</div>
			</div>
			<div class="btns">
				<div class="play-rate" @click="clickFunc('speed')">{{playSpeed}}x</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				current: 0, //当前进度(s)
				duration: 0, //总时长(s)
				paused: true, //是否处于暂停状态
				loading: false, //是否处于读取状态
				seek: false, //是否处于拖动状态
				playSpeed: 1, //是否处于拖动状态
			}
		},
		methods: {
			clickFunc(val) {
				if (val == 'play') {
					this.paused = !this.paused;
					this.$refs.audio.play();
				} else if (val == 'pause') {
					this.paused = !this.paused;
					this.$refs.audio.pause();
				}else if (val == 'speed') { //减慢音频播放速度，速率范围[0.5，3]
					if (this.playSpeed < 3) {
						this.playSpeed += 0.5;
						this.$refs.audio.playbackRate = this.playSpeed;
					}else if(this.playSpeed == 3){
						this.playSpeed = 0.5;
						this.$refs.audio.playbackRate = this.playSpeed;
					}
				} else if (val == 'refresh') {
					this.$refs.audio.playbackRate = 1;
					this.playSpeed = 1;
					this.$refs.audio.load();
					this.$refs.audio.play();
				}
			},
			timeupdateFunc(e) {
				this.current = parseInt(e.target.currentTime);
			},
			canplayFunc(e) {
				this.duration = parseInt(e.target.duration);
			},
			//格式化时长
			format(num) {
				if (num == 0) {
					return "00:00:00";
				} else {
					return '0'.repeat(2 - String(Math.floor(num / 3600)).length) + Math.floor(num / 3600) + ':' + '0'.repeat(2 - String(Math.floor((num % 3600) / 60)).length) + Math.floor((num % 3600) / 60) + ':' + '0'.repeat(2 - String(
						Math.floor((num % 3600) % 60)).length) + Math.floor((num % 3600) % 60)
				}
			},
			//滑块变动
			sliderChange(value) {
				this.current = value;
				this.$refs.audio.currentTime = this.current;
				this.$refs.audio.play();
			},
			sliderInput(value) {
				this.seek = true;
				this.current = value;
			},
			prev(){
				
			},
			next(){
				
			}
		}

	}
</script>

<style lang="scss" scoped>
	.aplayer {
		width: 100%;
		height: 66px;
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: #ffffff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
		display: flex;
		justify-content: center;
		align-items: center;


		.box {
			width: 1037px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;

			.control {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 150px;

				img {
					cursor: pointer;
				}
			}

			.cover {
				width: 50px;
				height: 50px;
				cursor: pointer;
				margin: 0 40px;
				position: relative;
				border-radius: 50%;
				box-shadow: 0 0 4px rgba(0, 0, 0, .2);

				.icon {
					width: 20px;
					height: 20px;
					position: absolute;
					top: 15px;
					left: 15px;
				}
			}

			.wrapper {
				width: 560px;
				display: flex;
				flex-direction: column;

				.title {
					font-size: 15px;
				}

				.slider {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.timebar {
						font-size: 14px;
						padding-top: -4px;
					}
				}
			}

			.btns {
				margin-left: 40px;

				.play-rate {
					font-size: 15px;
					cursor: pointer;
				}
			}
		}
		
		/deep/.el-slider__button-wrapper {
			top: -18px;
		}
		
		/deep/.el-slider__button {
			border: 0 !important;
			cursor: pointer;
			background-color: rgb(255, 255, 255);
			box-shadow: 0 0 4px rgba(0, 0, 0, .2);
		}
		
		/deep/.el-slider__runway {
			height: 3px !important;
			background-color: rgb(233, 233, 233);
			width: 400px;
		}
		
		/deep/.el-slider__bar {
			background-color: #E7584E !important;
			height: 3px !important;
		
		}

	}





	
</style>
