<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="10"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      style="position: fixed;"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>
    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
          <div class="logo" @click="GoToIndex" style="cursor: pointer">
            <img
              style="width: 100%; height: 100%"
              src="../../assets/image/logo.png"
              alt=""
            />
          </div>

          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height:70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            class="tab-as"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: item.product_type == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96;padding-left:5px"
              >LIVE</span
            >
          </div>
        </div>
      </div>
      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->

    <div class="teachars">
      <div class="maxbox">
        <!-- logo -->
        <div class="logos">
          <div class="logo">
            <img src="../../assets/image/tclogo.png" alt="" />
          </div>
          <div class="logfont">COMPETITION JUDGE</div>
        </div>
        <!-- 嘉宾 -->
        <div class="guests" ref="hedBox" style="max-height: 750px">
          <div class="gusd" @click="goToMorePage">
            <img
              style="width: 100%; height: 100%"
              src="../../assets/image/tearb.png"
              alt=""
            />
          </div>
          <div class="introd" v-for="(item, index) in listData" :key="index">
            <div class="head" @click="goToDetailPage(item)">
              <img
                style="width: 100%; height: 100%; border-radius: 50%"
                :src="item.image"
                alt=""
              />
            </div>
            <div class="names">{{ item.name }}</div>
            <div class="duce" v-for="(ites, inz) in item.titleList" :key="inz">
              <p>{{ ites }}</p>
            </div>
          </div>
        </div>
        <div @click="goToMorePage" class="puldown" ref="botm">查看全部嘉宾</div>
      </div>
      <foo-ter />
    </div>
  </div>
</template>

<script>
import fooTer from "../../components/footer";
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      num: 13,
      tabList: [],
      productTypeData: {},
      busy: false,
      listData: [],
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
    };
  },
  components: {
    fooTer,
  },
  created() {
    var that = this;
    document.title = "师资 - 机工新阅读";
    this.setupList();
    this.setupListData();
    this.hotKeywordsGet();

    window.addEventListener("resize", function() {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
	GoToIndex(){
		if(this.tabList && this.tabList.length>0){
			this.GoToChangeTab(this.tabList[0].product_type)
		}
	},
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
      // console.log(screenWidth);
    },
    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.$refs.inputRef.blur();
      this.isInputing2 = false;
      var url = "/SearchPage";
      var query = { inputStr: this.input_content };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      this.input_content = "";
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    loadMore() {},
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }else if (item.product_type == 13) {
            item.title = "师资";
          }
          if(item.product_type != 11){
        	  that.tabList.push(item);
          }
        });
        that.tabList.push({
        	title:'院校阅读服务',
        	img_url:'',
        	product_type:15,
        	product_count:0
        })
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
    //ListData
    setupListData() {
      var that = this;
      var url = apiUrl.getList;
      var param = { searchType: 10, cateId: 39, corpId: 2, orderBy: "normal" };
      this.AjaxTeacher(url, param, (data) => {
        that.listData = data.rows;
      });
    },
    //Page: Detail
    goToDetailPage(item) {
      localStorage.setItem("toPagePath", "GuestDetail");
      var url = "GuestDetail";
      var query = {
        name: item.name,
        id: item.id,
      };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
    //Page: More
    goToMorePage() {
      var url = "GuestList";
      var query = {};
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.teachars {
  width: 100%;
  min-width: 1157px;
  background: #f7f7f7;
  cursor: default;
  .maxbox {
    width: 1157px;
    margin: 0 auto;
    min-height: 800px;

    // border: 1px solid red;
    position: relative;
    background: #fff;
    .logos {
      height: 92px;
      width: 100%;

      padding-top: 80px;
      margin-bottom: 80px;
      margin-top: 3px;
      .logo {
        width: 220px;
        height: 60px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .logfont {
        height: 22px;
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #e6564e;
        line-height: 22px;
        text-align: center;
      }
    }
    .guests::-webkit-scrollbar {
      display: none;
    }
    .guests {
      margin-top: 30px;
      width: 100%;
      // height: 1000px;

      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      overflow: hidden;

      .gusd {
        width: 207px;
        height: 199px;
        // border: 1px solid blue;
        position: absolute;
        right: -160px;
        top: 580px;
      }

      .introd {
        width: 280px;
        height: 300px;

        // display: inline-block;
        margin-top: 60px;
        // margin-left: 52px;
        .head {
          width: 127px;
          height: 127px;
          border-radius: 50%;
          // border: 1px solid red;
          margin: 0 auto;
        }
        .names {
          margin-top: 15px;
          height: 33px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2b2b2b;

          letter-spacing: 1px;
          text-align: center;
        }
        .duce {
          width: 100%;

          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          // font-weight: 500;
          color: #cccccc;
          // line-height: 22px;
          text-align: center;
          // margin-top: 15px;
        }
      }
      .introd:hover {
        cursor: pointer;
				.names{
												color: #E6564E;
				}
      }
    }
    .puldown {
      width: 293px;
      height: 55px;
      background: #e6564e;
      border-radius: 8px 8px 0px 0px;
      color: #fff;
      font-size: 20px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 55px;
      margin: 0 auto;
      margin-top: 20px;
      // border: 1px solid;
    }
    .puldown:hover {
      cursor: pointer;
    }
  }
}
</style>
