<template>
	<div
		class="maxBox"
		ref="main"
		style="height: 100vh; overflow: auto"
		v-infinite-scroll="loadMore"
		:infinite-scroll-immediate-check="true"
		infinite-scroll-distance="10"
		@scroll="handleScroll"
		@click="removeInputState()">
		<BackTop
			:bottom="300"
			:right="backTopRight"
			style="position: fixed;"
			:class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
			on-click="scrollToTop()">
			<div class="top" @click="scrollToTop">
				<div class="divg">
					<img
						style="width: 100%; height: 100%"
						src="../../assets/image/shang.png"
						alt="" />
				</div>
			</div>
		</BackTop>
		<div class="topNav">
			<div class="logipt">
				<!-- logo -->
				<div class="logoBox">
					<div class="logo" @click="GoToIndex" style="cursor: pointer">
						<img
							style="width: 100%; height: 100%"
							src="../../assets/image/logo.png"
							alt="" />
					</div>

					<!-- <router-link to="/searchpage"> -->
					<div
						class="inputs"
						style="width: 485px !important; background: #f7f7f7ff">
						<img src="../../assets/image/search.png" alt="" />
						<input
							ref="inputRef"
							class="ipus"
							style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
							confirm-type="search"
							placeholder-class="text"
							placeholder="书名、作者、关键字"
							@focus="input_focusIpt"
							v-on:keyup.enter="input_search(input_content)"
							v-model="input_content" />
						<!-- @blur="input_blurIpt" -->
						<div
							class="btnBox"
							style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
							@click="input_search()">
							搜索
						</div>
					</div>
					<div class="showContent" v-if="isInputing2">
						<div class="titleBg">
							<div class="title">热门搜索</div>
							<div class="clear"></div>
						</div>
						<div
							class="item"
							v-for="(item, index) in hotKeywords"
							:key="index"
							@click="input_search(item.title)">
							{{ item.title }}
						</div>
					</div>
					<!-- </router-link> -->
				</div>
				<!-- logins -->
				<!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
				<!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
			</div>
			<!-- tab栏 -->
			<div
				class="tablistBg"
				v-if="isShowNav"
				style="height:70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);"></div>
			<div
				class="tablistBg"
				:class="isShowNav ? 'topNavShow' : ''"
				style="margin-top: 0px">
				<div class="tablist">
					<div
						class="tab-as"
						v-for="(item, index) in tabList"
						:key="index"
						:class="{ tabActive: item.product_type == num }"
						@click="GoToChangeTab(item.product_type)"
						style="color: #333333">
						{{ item.title }}
						<span
							v-if="item.product_type == 11"
							:class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
							style="color: #47cd96;padding-left:5px">LIVE</span>
					</div>
				</div>
			</div>
			<div class="hovers" ref="hoves">
				<div class="vipmoy">
					<img class="viplo" src="../../assets/image/viplogo.png" alt="" />
					<span class="spanv">[续费VIP]</span>
				</div>
				<div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
				<div class="times">2021.08.10 到期</div>
				<div class="fenge"></div>
				<div class="fosnam">
					<p>我的订单</p>
					<p>我的卡券</p>
				</div>
				<div class="fonbot">
					<p>物流查询</p>
					<p>我的企业</p>
					<p>关于我们</p>
					<p>作者助手</p>
				</div>
				<div class="nect">退出</div>
			</div>
		</div>
		<!-- content -->

		<div class="teachars">

			<div class="bannerBox">
				
				<!-- 轮播banner -->
				<div class="banner" v-if="banners && banners.length>0">
					<el-carousel :interval="5000" type="card" height="375px" indicator-position="none">
						<el-carousel-item v-for="(item, index) in banners" :key="index">
							<img @click="GoToDetail(item.skip_type, item.skip_target)"
								style="width: 100%; height: 100%; border-radius: 4px" :src="item.img_url_pc" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
				
				<div class="mainbody">
					
					<!-- 讲师分类 -->
					<div class="navbox">
						<div class="nav" :class="{selected:classifySelected.id == item.id}" v-for="(item,index) in classifyList" @click="selectedClassify(item)">
							<img class="icon" :src="classifySelected.id == item.id ?item.imgClickedUrl:item.imgUrl" />
							<span>{{item.name}}</span>
						</div>
					</div>
					
					<!-- 讲师列表 -->
					<div class="lecturerBox">
						<div class="lecturerItem" v-for="(item,index) in listData" @click="goToDetail(item)">
							<div class="lecturerImage">
								<img :src="item.imgUrl" style="width: 100%;"/>
							</div>
							<div class="lecturerInfo">
								<div class="name">{{item.name}}</div>
								<div class="title" v-html="item.title.replace(/\r\n/g,'<br>')"></div>
								<div class="classify" v-if="item.classifyList && item.classifyList.length>0">
									<span class="border" v-for="(tem,dex) in item.classifyList" v-if="dex<5">{{tem.name}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				

			</div>


			<foo-ter />
		</div>
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				num: 13,
				tabList: [],
				productTypeData: {},
				busy: false,//是否加载更多
				listData: [],
				isShowNav: false,
				input_content: "",
				hotKeywords: [], //热门搜索
				isInputing2: this.isInputing,
				isShowScrollBtn: false,
				backTopRight: 200,
				isShowScrollBtn2: false,
				banners: [],
				product_type: 13,
				search: {
					page_index: 1,
					page_size: 6,
					classifyId: 0,
					keyword: ''
				},
				classifyList:[],//讲师分类类别
				classifySelected:{},//选中的讲师分类
				listData: [], //讲师列表
			};
		},
		components: {
			fooTer,
		},
		created() {
			var that = this;
			document.title = "师资 - 机工新阅读";
			this.setupList();
			this.hotKeywordsGet();
			this.setupBannerList();
			this.getClassifyList();

			window.addEventListener("resize", function() {
				that.css_caculateBackTopRight();
			});
			this.css_caculateBackTopRight();
		},
		methods: {
			//Banner
			setupBannerList() {
				var that = this;
				var url = apiUrl.banner_get;
				var param = {
					product_type: this.product_type
				};
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (!item.img_url_pc || item.img_url_pc == "") {
							item.img_url_pc = item.img_url;
						}
					});
					that.banners = data;
				});
			},
			//讲师列表
			listDataGet(index) {
				var that = this;
				that.search.page_index = index;
				that.search.classifyId = that.classifySelected.id;
				this.Ajax(apiUrl.lecturer_get_list, that.search, (data)=> {
					if (data) {
						if (that.search.page_index == 1) {
							that.listData = [];
							that.listData = data;
						} else {
							that.listData = that.listData.concat(data);
						}
						if (data.length != that.search.page_size) {
							that.busy = false;
						} else {
							that.busy = true;
						}
					} else {
						that.busy = false;
					}
				}, function(data, code) {
					that.busy = false;
				});
			},
			//加载更多
			loadMore() {
				if(!this.busy) return;
				this.listDataGet(this.search.page_index + 1);
			},
			getClassifyList(){
				var that = this;
				var url = apiUrl.lecturer_get_classify_list;
				this.Ajax(url, {}, (data) => {
					that.classifyList = data;
					that.classifySelected = data[0];
					that.listDataGet(1);
				});
			},
			selectedClassify(item){
				this.classifySelected = item;
				this.listDataGet(1);
			},
			goToDetail(item){
				localStorage.setItem("toPagePath", "Teachers2Detail");
				var url = "Teachers2Detail";
				var query = {
					name: item.name,
					id: item.id,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			GoToIndex() {
				if (this.tabList && this.tabList.length > 0) {
					this.GoToChangeTab(this.tabList[0].product_type)
				}
			},
			css_caculateBackTopRight() {
				var screenWidth = document.body.offsetWidth;
				if (screenWidth < 1200) {
					this.isShowScrollBtn2 = false;
				} else {
					this.isShowScrollBtn2 = true;
				}
				var right = (screenWidth - 1157) / 2 - 30;
				this.backTopRight = right || 200;
				// console.log(screenWidth);
			},
			scrollToTop() {
				this.$refs.main.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			removeInputState() {
				this.isInputing = false;
				this.input_blurIpt();
				this.$forceUpdate();
			},
			hotKeywordsGet() {
				var that = this;
				this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
					if (data && data.length > 0) {
						that.hotKeywords = data;
					}
				});
			},
			input_search(title) {
				if (title && title != "") this.input_content = title;
				this.$refs.inputRef.blur();
				this.isInputing2 = false;
				var url = "/SearchPage";
				var query = { inputStr: this.input_content };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				this.input_content = "";
			},
			input_focusIpt() {
				setTimeout(() => {
					this.isInputing2 = true;
					this.$refs.inputRef.focus();
					this.$forceUpdate();
				}, 200);
			},
			input_blurIpt() {
				this.isInputing2 = false;
				this.$refs.inputRef.blur();
				// setTimeout(() => {
				// this.isInputing2 = true;
				//   this.$forceUpdate();
				// }, 500);
			},
			handleScroll(e, item) {
				let scrollY = e.target.scrollTop;
				console.log(e.target.scrollTop);
				var height = 90;
				if (scrollY >= height) {
					this.isShowNav = true;
					this.isInputing = false;
					this.input_blurIpt();
				} else {
					this.isShowNav = false;
				}
				this.isShowScrollBtn = scrollY > 200;
				this.$forceUpdate();
			},
			setupList() {
				var that = this;
				this.tabList = [];
				var url = apiUrl.shuyuan_navbar_get;
				var param = { client_type: 2 };
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (item.product_type == 11) {
							item.title = "直播";
						}
						that.tabList.push(item);
					});
					that.productTypeData = data;
				});
			},
			handellet() {
				if (this.$refs.hoves.style.display == "none") {
					this.$refs.hoves.style.display = "block";
				} else if ((this.$refs.hoves.style.display = "block")) {
					this.$refs.hoves.style.display = "none";
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.teachars {
		width: 100%;
		min-width: 1157px;
		background: #f7f7f7;
		cursor: default;

		.bannerBox {
			width: 1157px;
			margin: 0 auto;
			background: #fff;
		}

		.banner {
			height: 420px;
			width: 100%;
			border: 1px solid #fff;
			background: #fff;
			margin: 0 auto;
			margin-bottom: 12px;

			.el-carousel {
				margin-top: 23px;

				.el-carousel__item {
					width: 750px;
					margin-left: -85px;
				}
			}
		}

		.mainbody{
			display: flex;
			flex-direction: row;
			width: 100%;
			margin-top: 10px;
		}
		.navbox {
			display: flex;
			flex-direction: column;
			width: 280px;
			position: sticky;
			top:70;

			.nav {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 225px;
				height: 56px;
				border-radius: 0 8px 8px 0;
				margin: 8px 0;
				cursor: pointer;
				box-sizing: border-box;
				border-left: 6px solid #fff;

				.icon {
					width: 24px;
					margin-left: 30px;
				}

				span {
					color: #999999;
					font-size: 18px;
					padding-left: 20px;
				}

				&.selected {
					border-left: 6px solid #E4574E;
					background-color: #FCEEED;
					box-sizing: border-box;
					
					
					span {
						color: #E4574E !important;
						font-size: 20px;
					}
				}
			}
		}
		
		.lecturerBox {
			justify-content: space-between;
			background-color: #fff;
			flex: 1;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding: 10px 8px 50px 8px;
			box-sizing: border-box;
			overflow-y: scroll;
			justify-content:flex-start;
		
			.more {
				width: 100%;
				font-size: 13px;
				display: flex;
				flex-direction: column;
				color: #E4574E;
				align-items: center;
		
				image {
					width: 16px;
					margin-top: 5px;
				}
			}
			
			.lecturerItem {
				margin: 0 42px 42px 0;
				box-shadow: 0 2px 6px #ccc;
				background-color: #F5F5F5;
				display: flex;
				flex-direction: column;
				width: 236px;
				height: 330px;
				z-index: 36;
				border-radius: 0 32px 10px 10px;
				overflow: hidden;
				
				.lecturerImage {
					z-index: 9;
					background-color: #999999;
					width: 100%;
					height: 192px;
					overflow-y: hidden;
					box-sizing: border-box;
					border-radius: 0 32px 0 32px;
				}
				
				.lecturerInfo {
					width: 100%;
					background: linear-gradient(to bottom, #F5F5F5, #C7C8C8);
					font-size: 14px;
					padding: 15px;
					box-sizing: border-box;
					border-radius: 0 0 10px 10px;
					cursor: pointer;
					
					.name {
						color: #E4574E;
						border-left: 5px solid #E4574E;
						font-weight: bold;
						padding-left: 12px;
						margin-bottom: 3px;
						margin-left: -15px;
						font-size: 18px;
					}
		
					.title {
						font-weight: bold;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						min-height: 63px;
					}
		
					.classify {
						margin-top: 5px;
		
						.border {
							border: 1px solid #E4574E;
							color: #E4574E;
							padding: 2px 4px;
							border-radius: 4px;
							margin-right: 5px;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
		
		.lecturerItem:hover{
			background-color: #FEE6DA;
		}
		
		.lecturerItem:hover .lecturerInfo{
			background: linear-gradient(to right, #FEE6DA, #FADCDA);
		}

		.maxbox {
			width: 1157px;
			margin: 0 auto;
			min-height: 800px;

			// border: 1px solid red;
			position: relative;
			background: #fff;

			.logos {
				height: 92px;
				width: 100%;

				padding-top: 80px;
				margin-bottom: 80px;
				margin-top: 3px;

				.logo {
					width: 220px;
					height: 60px;
					margin: 0 auto;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.logfont {
					height: 22px;
					font-size: 16px;
					font-family: PingFang-SC-Bold, PingFang-SC;
					font-weight: bold;
					color: #e6564e;
					line-height: 22px;
					text-align: center;
				}
			}

			.guests::-webkit-scrollbar {
				display: none;
			}

			.guests {
				margin-top: 30px;
				width: 100%;
				// height: 1000px;

				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				overflow: hidden;

				.gusd {
					width: 207px;
					height: 199px;
					// border: 1px solid blue;
					position: absolute;
					right: -160px;
					top: 580px;
				}

				.introd {
					width: 280px;
					height: 300px;

					// display: inline-block;
					margin-top: 60px;

					// margin-left: 52px;
					.head {
						width: 127px;
						height: 127px;
						border-radius: 50%;
						// border: 1px solid red;
						margin: 0 auto;
					}

					.names {
						margin-top: 15px;
						height: 33px;
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #2b2b2b;

						letter-spacing: 1px;
						text-align: center;
					}

					.duce {
						width: 100%;

						font-size: 14px;
						font-family: PingFang-SC-Medium, PingFang-SC;
						// font-weight: 500;
						color: #cccccc;
						// line-height: 22px;
						text-align: center;
						// margin-top: 15px;
					}
				}

				.introd:hover {
					cursor: pointer;

					.names {
						color: #E6564E;
					}
				}
			}

			.puldown {
				width: 293px;
				height: 55px;
				background: #e6564e;
				border-radius: 8px 8px 0px 0px;
				color: #fff;
				font-size: 20px;
				font-family: PingFang-SC-Regular, PingFang-SC;
				font-weight: 400;
				color: #ffffff;
				text-align: center;
				line-height: 55px;
				margin: 0 auto;
				margin-top: 20px;
				// border: 1px solid;
			}

			.puldown:hover {
				cursor: pointer;
			}
		}
	}
</style>
