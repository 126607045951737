<template>
  <div class="tmsd">
    <div class="disz" @click="handleshow(idm)">
      <span class="span1"> {{ ien.title }}</span>
      <div style="margin-right:40px">
        <img
          ref="mgs"
          class="jiant"
          :class="isShow ? 'UPBoxImgAC' : 'jiant'"
          src="../../assets/image/icon_live.png"
          alt=""
        />
        <span class="span2" v-show="isShow">收起</span>
        <span class="span3" v-show="!isShow">展开</span>
      </div>
    </div>
    <div style="margin-left: 57px" class="dtals" v-show="isShow">
      {{ ien.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ien: {
      type: Object,
      default: {},
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  methods: {
    handleshow(index) {
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
.tmsd {
  padding-top: 39px;
  background: #fff;
  cursor: default;
  // border: 1px solid red;
  .disz {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .span1 {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 550;
      color: #333333;
      margin-left: 62px;
      letter-spacing: 1px;
    }
    .jiant {
      width: 7px;
      height: 12px;
      margin-bottom: 2px;
      margin: 0 5px;
    }

    .UPBoxImgAC {
      transform: rotate(90deg);
      height: 26upx;
      width: 18upx;
      margin-top: 14upx;
    }

    .span2 {
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e6564e;
      letter-spacing: 1px;

      // padding-bottom: 20px;
    }
    .span3 {
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e6564e;
      letter-spacing: 1px;
    }
  }
  .disz:hover {
    cursor: pointer;
  }

  .dtals {
    font-size: 20px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #666666;
    width: 1016px;
    line-height: 38px;
    // border: 1px solid blue;
    margin-left: 60px !important;
  }
}
</style>
