<template>
	<!-- <div class="booksMes" :style="checkStyle" @click="goToDetailPage(item.product_type,item.id)">
		<img :src="item.img_url_compressed" alt="" />
		<div class="text">{{item.title}}</div>
		<div class="textB">已读：{{item.progress}}</div>
		<div class="bgBooks" v-show="hediImg" @click.stop="delSelect">
			<div class="box" @click="handleimg" ref="bOx">
				<img v-show="isCheck" src="../../assets/image/duihao1.png" alt="" />
			</div>
		</div>
	</div> -->

	<div class="bookBox" :style="checkStyle" @click="goToDetailPage(item.product_type,item.id)">
		<img class="bookimg" :src="item.img_url_compressed" alt="" :title="item.title" />
		<div class="tits">{{item.title}}</div>
		<span>已读{{item.progress}}</span>
		<div class="bgBooks" v-show="hediImg" @click.stop="handleimg">
			<div class="box" ref="bOx">
				<img src="../../assets/image/duihao1.png" alt="" v-show="isCheck" />
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import checkimg from "./cheCkimg.vue";

	export default {
		props: {
			item: {
				type: Object,
				default: {}
			},
			hediImg: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isCheck: false,
				//checkStyle: this.item.product_type == 11 ? "width:49%" : ""
				checkStyle: this.item.product_type == 11 ? "" : ""
			};
		},

		methods: {
			handleimg() {
				this.isCheck = !this.isCheck
				// this.$refs.bOx.style.border="none"
			},
			delBook() {
				this.hediImg = !this.hediImg
			},
			goToDetailPage(product_type, id) {
				this.GoToDetail(product_type, id);
			},
		},

	}
</script>

<style lang="scss">
	
	.bookBox:hover{
		cursor: pointer;
	}
	.bookBox {
		width: 130px;
		// height: 241px;
		// border: 1px solid blue;
		margin-left: 42px;
		display: inline-block;
		margin-bottom: 20px;
		position: relative;

		.bgBooks {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 173px;
			// height: 100%;
			background-color: rgba(0, 0, 0, .5);

			.box {
				position: absolute;
				bottom: 10px;
				right: 10px;
				width: 20px;
				height: 20px;
				border: 1px solid #d8d8d8;
				border-radius: 50%;
				display: flex;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
		}

		.bookimg {
			width: 100%;
			height: 173px;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
			border-radius: 6px;
		}

		.tits {
			// margin-top: 3px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			// padding: 10upx 0;
			line-height: 1.5;
			height: 54px;
			text-align: left;
		}

		span {
			font-size: 15px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #999999;
		}
	}
</style>
