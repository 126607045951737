<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="10"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      style="position: fixed;"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>
    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
          <div class="logo" @click="GoToIndex" style="cursor: pointer">
            <img
              style="width: 100%; height: 100%"
              src="../../assets/image/logo.png"
              alt=""
            />
          </div>

          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height:70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            class="tab-as"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: item.product_type == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="item.product_type == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96;padding-left:5px"
              >LIVE</span
            >
          </div>
        </div>
      </div>
      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->

    <div class="subjct">
      <div class="subvon">
        <div class="banner" v-if="banners && banners.length>0">
          <el-carousel
            :interval="5000"
            type="card"
            height="375px"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item, index) in banners" :key="index">
              <img
                @click="GoToDetail(item.skip_type, item.skip_target)"
                style="width: 100%; height: 100%"
                :src="item.img_url_pc"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="tkls">
          <div class="lsTop">
            <h2>小编推荐</h2>
            <span @click="goToAllBookPage()">更多 ></span>
          </div>
          <div class="lscent">
            <div
              class="lups"
              @click="goToDetailPage(item)"
              v-for="(item, idex) in groupAll.list"
              :key="idex"
            >
              <img
                style="width: 100%; height: 100%; border-radius: 6px"
                :src="item.img_url"
                alt=""
              />
              <div class="divz">
                <div
                  class="divbo"
                  v-for="(product, idx) in item.products"
                  :key="idx"
                  v-show="product.product_count > 0"
                >
                  <img
                    src="../../assets/image/icon24.png"
                    alt=""
                    v-if="product.product_type == 1"
                  />
                  <img
                    src="../../assets/image/icon23.png"
                    alt=""
                    v-if="product.product_type == 3"
                  />
                  <img
                    src="../../assets/image/icon25.png"
                    alt=""
                    v-if="product.product_type == 4"
                  />
                  <span>({{ product.product_count }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <foo-ter />
    </div>
  </div>
</template>

<script>
import apiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
import fooTer from "../../components/footer";
export default {
  data() {
    return {
      num: 12,
      tabList: [],
      productTypeData: {},
      busy: false,
      groupAllSearch: {
        page_index: 1,
        page_size: 10,
        order_by: "start_at:desc",
      },
      groupAll: {},
      banners: [],
      product_type: 12,
      busy: false,
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
    };
  },
  components: {
    fooTer,
  },
  created() {
    var that = this;
    document.title = "专题 - 机工新阅读";
    this.setupList();
    this.setupBannerList();
    this.groupAllGet();
    this.hotKeywordsGet();

    window.addEventListener("resize", function() {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
	GoToIndex(){
		if(this.tabList && this.tabList.length>0){
			this.GoToChangeTab(this.tabList[0].product_type)
		}
	},
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
      // console.log(screenWidth);
    },
    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.$refs.inputRef.blur();
      this.isInputing2 = false;
      var url = "/SearchPage";
      var query = { inputStr: this.input_content };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      this.input_content = "";
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    //Next PageList
    loadMore() {
      if (this.busy) return;

      var that = this;
      if (that.groupAll.count > that.groupAllSearch.page_index) {
        that.groupAllSearch.page_index += 1;
        this.busy = true;
        that.groupAllGet();
      }
    },
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }else if (item.product_type == 13) {
            item.title = "师资";
          }
          if(item.product_type != 11){
        	  that.tabList.push(item);
          }
        });
        that.tabList.push({
        	title:'院校阅读服务',
        	img_url:'',
        	product_type:15,
        	product_count:0
        })
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
    //Banner
    setupBannerList() {
      var that = this;
      var url = apiUrl.banner_get;
      var param = { product_type: this.product_type };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (!item.img_url_pc || item.img_url_pc == "") {
            item.img_url_pc = item.img_url;
          }
        });
        that.banners = data;
      });
    },
    //ListData
    groupAllGet() {
      var that = this;
      var url = apiUrl.group_all_get;
      var param = that.groupAllSearch;
      this.Ajax(url, param, function(data) {
        if (data.list && data.list.length > 0) {
          if (that.groupAllSearch.page_index == 1) {
            that.groupAll = data;
          } else {
            var list = that.groupAll.list;
            that.groupAll = data;
            that.groupAll.list = list.concat(that.groupAll.list);
          }
        }
        that.busy = false;
      });
    },
    //Page Detail
    goToDetailPage(item) {
      this.GoToDetail(this.product_type, item.id);
    },
    //Page AllBook
    goToAllBookPage() {
      localStorage.setItem("toPagePath", "subject");
      var query = {
        type: this.product_type,
        type2Sort: 1,
      };
      this.GoToAllBook(query);
    },
  },
};
</script>

<style lang="scss">
.subjct {
  width: 100%;
  min-width: 1157px;
  background: #f7f7f7;
  cursor: default;
  .subvon {
    width: 1157px;
    margin: 0 auto;
    min-height: 800px;
    .banner {
      height: 420px;
      width: 1157px;
      border: 1px solid #fff;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 12px;
      .el-carousel {
        margin-top: 23px;
        .el-carousel__item {
          width: 750px;
          // height: 224px;
          margin-left: -85px;
        }
        // .el-carousel__item:nth-child(2n) {
        //   background-color: red;
        // }

        // .el-carousel__item:nth-child(2n + 1) {
        //   background-color: yellow;
        // }
      }
    }
    .tkls {
      width: 100%;
      border: 1px solid #fff;
      margin-top: 21px;
      background: #fff;
      .lsTop {
        width: 1056px;
        height: 38px;
        // border: 1px solid red;

        margin: 31px 0 0 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 27px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #2b2b2b;
          letter-spacing: 1px;
        }
        span {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #999999;
          letter-spacing: 1px;
        }
      }
      .lsTop:hover {
        cursor: pointer;
      }
      .lscent {
        width: 1056px;
        // height: 1000px;

        margin: 27px 0 0 44px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .lups {
          width: 510px;
          height: 191px;
          border-radius: 6px;
          position: relative;
          margin-bottom: 12px;

          .divz {
            // width: 222px;
            height: 36px;
            background: #000000;
            border-radius: 8px 0px 8px 0px;
            opacity: 0.7;
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            z-index: 1000;
            color: #fff !important;

            .divbo {
              display: flex;
              align-items: center;

              margin: 0px 8px;
              img {
                width: 25px;
                height: 25px;
                margin-right: 2px;
                // border: 1px solid red;
              }
            }
          }
        }
        .lups:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
