<template>
	<div class="audio">
		<div class="maxBox">
			<div class="mianb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/Aubook' }">有声书</el-breadcrumb-item>
					<el-breadcrumb-item>{{ detailData.title }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="dTals">
				<div class="magsL">
					<div class="magz">
						<img
							:src="detailData.img_url_compressed"
							style="
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              "
							alt="" />
						<img class="ems" src="../../assets/image/ermai.png" alt="" />
					</div>
				</div>
				<div class="totlR">
					<div class="rtops">
						<div class="tits">{{ detailData.title }}</div>
						<div class="addys">{{ detailData.anchor }}</div>
					</div>
					<div class="rbotms">
						<div class="botLf" style="background: none; box-shadow: none">
							<!-- <p class="p1">开通vip</p>
              <p class="p2">(vip会员电子书、有声书免费学习)</p> -->
						</div>
						<div class="botRt">
							<div class="rt1" style="color: #d8d8d8">
								播放次数：
								<span style="color: #000"> {{ detailData.view_count }}</span>
							</div>
							<div class="rt2" style="color: #d8d8d8">
								<div class="tssz">
									有<span style="color: #000">{{
                    detailData.readers_count
                  }}</span>人正在听此书:
								</div>

								<div
									class="tsd"
									@click="goToPersonPage(detailData.id)"
									v-if="
                    detailData.readers_list &&
                      detailData.readers_list.length > 0
                  ">
									<!-- 123 -->
									<img
										v-for="(item, indexA) in detailData.readers_list"
										:key="indexA"
										v-show="indexA < 3"
										style="width: 30px; height: 30px; border-radius: 50%"
										:src="item.avatar"
										alt="" />
									<img
										style="width: 9px; height: 9px"
										src="../../assets/image/icon_rigth.png"
										alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="hongb">
					<div class="qrcode">
						<div class="qr-code" id="qrcode_id" ref="qrcode_id"></div>
					</div>

					<div class="p1">微信收听</div>
					<div class="p2">{{ detailData.title }}</div>
				</div>
				<!-- <div class="zxsm">
          <img class="olas" src="../../assets/image/loms.png" alt="" />
          <p>扫码</p>
          <p>在线</p>
          <p>收听</p>
          <div class="saomaBg" v-if="detailData.qrcode_url">
            <div class="qrBg">
              <img class="saoma" src="../../assets/image/saoma.png" alt="" />
              <div class="qrcode" id="qrcode_id" ref="qrcode_id"></div>
              <div class="foas">
                <p class="pm">扫描二维码</p>
                <p class="pn">在线收听有声书</p>
              </div>
            </div>
          </div>
        </div> -->
			</div>
			<div class="tabs">
				<div
					class="icons"
					@click="goToEBookDetailPage(item)"
					v-for="item in detailData.products"
					v-if="item.prodyct_type= 1">
					<img src="../../assets/image/bookicon.png" alt="" />
					<span>电子书</span>
				</div>
				<el-tabs v-model="activeName" class="eltabs" type="border-card">
					<el-tab-pane class="news" label="边听边看" name="first">
						<div class="titsa">{{ nowResource.title }}</div>
						<div class="txtmsg" v-if="nowResource.title">
							<p>音频由机械工业出版社出品</p>
							<p v-if="nowResource.content">文稿由讯飞语音转写技术自动生成</p>
							<p v-if="!nowResource.content">文稿正在生成中......</p>
						</div>
						<div class="conten">
							<p
								v-if="nowResource.content"
								class="description"
								v-html="nodes(nowResource.content)"></p>
						</div>
					</el-tab-pane>
					<el-tab-pane class="hots" label="详情" name="second">
						<div class="netsl">
							<div
								class="tags"
								v-for="item in detailData.product_classes"
								:key="item.id"
								@click="goToAllBookPage(item)">
								{{ item.title }}
							</div>
						</div>
						<div class="mess">
							<div
								v-if="detailData.desc"
								class="description"
								v-html="setmaxwidth(detailData.desc)"></div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<foo-ter />

		<div class="aplayer">
			<div class="box">
				<audio style="margin-top: -9999999; display: none;"
					ref="audio"
					@canplay="canplayFunc"
					@timeupdate="timeupdateFunc"
					@play="paused=false"
					@pause="paused=true"
					@ended="onendedFunc"
					@seeked="seek=false"
					@seeking="seek=true"
					:autoplay="playaudio"
					:src="nowResource.audio_url"
					controls
					oncontextmenu="return false"
					controlslist="nodownload">
				</audio>
				<div class="control">
					<img src="../../assets/image/up.png" alt="" width="15px" @click="prev" />
					<img src="../../assets/image/audio.png" alt="" width="40px" v-if="paused" @click="clickFunc('play')" />
					<img src="../../assets/image/pause.png" alt="" width="40px" v-if="!paused" @click="clickFunc('pause')" />
					<img src="../../assets/image/down.png" alt="" width="15px" @click="next" />
				</div>
				<div class="cover">
					<img :src="detailData.img_url_compressed" style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;" />
					<img class="icon" src="../../assets/image/ermai.png" alt="" />
				</div>
				<div class="wrapper">
					<div class="title">{{nowResource.title}}</div>
					<div class="slider" @mousedown="seek=true" @mouseup="seek=false">
						<span class="timebar">{{format(current)}}</span>
						<el-slider v-model="current" :min="0" :max="duration" :format-tooltip="format" @change="sliderChange"></el-slider>
						<span class="timebar" v-if="duration != null">{{format(duration)}}</span>
						<span class="timebar" v-if="duration == null && nowResource && nowResource.audio_length">{{nowResource.audio_length}}</span>
					</div>
				</div>
				<div class="btns">
					<el-popover placement="top" v-model="popover">
						<ul>
							<li v-for="item in playbackRates" @click="speedChange(item)" :class="{'actived':item == playSpeed}">
								<span v-if="item != '1.0'">{{item}}x</span>
								<span v-if="item == '1.0'">正常倍数</span>
							</li>
						</ul>
						<div class="play-rate" slot="reference">倍数 {{playSpeed}}x</div>
					</el-popover>
				</div>
				<div class="catalogR" @click="drawer = !drawer">
					<img src="../../assets/image/icon_10.png" alt="" />
					<span>共{{detailData.resource_count}}章</span>
				</div>

			</div>
		</div>
		<el-drawer
			title="目录"
			:visible.sync="drawer"
			:with-header="false"
			style="min-width:500px;">
			<div class="popupTitle">
				<div class="diva">目录</div>
				<div class="divb" @click="changeSortType">
					<div class="dism">排序</div>
					<div class="divimg">
						<img
							class="ilmge"
							:class="sort == 0 ? '' : 'sort2'"
							src="../../assets/image/colors.png"
							alt="" />
					</div>
				</div>
			</div>
			<div class="fenj"></div>
			<div class="catalogue">
				<div
					class="losl"
					v-for="(item, index) in resourceData.list"
					:key="index"
					:class="{ 'actived':item.resource_id == nowResource.resource_id }"
					@click="clickCatalogue(index)">
					<span class="spone">{{ item.title }}</span>
					<!-- <span class="sptow" v-if="item.is_try==1">试读</span> -->
				</div>
			</div>

		</el-drawer>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				activeName: "first",
				detailSearch: {
					id: null,
				},
				resourceSearch: {
					product_id: null,
					page_index: 1,
					page_size: 10000,
					order_by: "created_at:asc",
					resource_type: 2, //资源类型，0 全部，1 图文，2 音频，3 视频
					is_try: -1, //是否试读，0 否，1 是，-1 全部
				},
				detailData: {},
				resourceData: {
					list: [],
				}, //播放资源
				nowResource: {}, //正在播放资源
				nodes(str) {
					if (str) {
						str = str.replace(/<br\s*.?>/g, "<br/><br/>");
					}
					return str;
				},
				sort: 0,
				current: 0, //当前进度(s)
				duration: 0, //总时长(s)
				paused: true, //是否处于暂停状态
				seek: false, //是否处于拖动状态
				playSpeed: '1.0',
				idPlan: 0,
				continue: true,
				playaudio: false,
				playbackRates: ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2.0'], //播放速度
				read_timestamp: Date.parse(new Date()),
				drawer: false,
				popover: false,
				isLogin: false
			};
		},
		components: {
			fooTer,
			qrcode: QRCode,
		},
		created() {
			this.isLogin = sessionStorage.hasOwnProperty("userLogInInfo");
			document.title = "有声书 - 机工新阅读";
			var option = {
				id: this.$route.query.id || "",
			};
			if (option && option.id) {
				this.detailSearch.id = option.id;
				var that = this;
				if (option && option.id) {
					this.detailSearch.id = option.id;
					this.setupDetailData(() => {
						that.setupResourceData();
					});
				}
			}
		},
		mounted() {
			//添加监听
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('监听 beforeunload')
		},
		destroyed() {
			//解除监听
			window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('解除 beforeunload')
		},
		methods: {
			//页面退出时提交下记录
			beforeunloadHandler(e) {
				this.stopPlayer();
			},
			//前往锚点
			toPagePoint(id) {
				this.$nextTick(() => {

				});
			},
			clickFunc(val) {
				if (val == 'play') {
					this.paused = false;
					this.seek = false;
					if (this.nowResource && this.nowResource.curr_time > 0) {
						this.$refs.audio.currentTime = this.nowResource.curr_time;
						this.nowResource.curr_time = 0;
					}
					this.$refs.audio.play();
					this.read_timestamp = Date.parse(new Date());
				} else if (val == 'pause') {
					this.paused = true;
					this.$refs.audio.pause();
					this.stopPlayer();
				} else if (val == 'speed') { //减慢音频播放速度，速率范围[0.5，3]
					if (this.$refs.audio.playbackRate < 3) {
						this.$refs.audio.playbackRate += 0.5;
						var num = new Number(this.$refs.audio.playbackRate);
						this.playSpeed = num.toFixed(1);
					} else if (this.$refs.audio.playbackRate == 3) {
						this.$refs.audio.playbackRate = 0.5;
						var num = new Number(this.$refs.audio.playbackRate);
						this.playSpeed = num.toFixed(1);
					}
				} else if (val == 'refresh') {
					this.$refs.audio.playbackRate = 1;
					this.playSpeed = 1;
					this.$refs.audio.load();
					this.$refs.audio.play();
				}
			},
			timeupdateFunc(e) {
				if (!this.seek) {
					this.current = parseInt(e.target.currentTime);
				}
			},
			canplayFunc(e) {
				console.log("canplayFunc")
				var that = this;
				if (e.target.duration != null) {
					that.duration = parseInt(e.target.duration);
				} else {
					that.duration = that.nowResource.audio_length;
				}
				that.current = that.nowResource.curr_time;

				if (that.autoplayBySrcChange) {
					setTimeout(function() {
						that.clickFunc('play');
					}, 100);
				}
			},
			onendedFunc(e) {
				console.log("播放完毕")
				var that = this;
				if (that.continue) {
					that.current = 0;
					that.next();
				} else {
					that.paused = true
					that.current = 0;
				}
			},
			//格式化时长
			format(num) {
				if (num == 0) {
					return "00:00:00";
				} else {
					return '0'.repeat(2 - String(Math.floor(num / 3600)).length) + Math.floor(num / 3600) + ':' + '0'.repeat(2 - String(Math.floor((num % 3600) / 60)).length) + Math.floor((num % 3600) / 60) + ':' + '0'.repeat(2 - String(
						Math.floor((num % 3600) % 60)).length) + Math.floor((num % 3600) % 60)
				}
			},
			//播放倍数调整
			speedChange(value) {
				this.playSpeed = value;
				var num = new Number(value);
				this.$refs.audio.playbackRate = num;
				console.log("倍数：" + this.$refs.audio.playbackRate);
				this.popover = false;
			},
			//滑块变动
			sliderChange(value) {
				this.seek == false;
				this.current = value;
				this.$refs.audio.currentTime = this.current;
				this.$refs.audio.play();
			},
			prev() {
				this.stopPlayer();
				//上一章
				if (this.idPlan > 0) {
					this.playaudio = true;
					this.clickAudioResource(this.idPlan - 1);
				} else {
					// uni.showToast({
					// 	title: "已经是第一章了.",
					// 	icon: 'none',
					// 	duration: 2000
					// });
				}
			},
			//Next
			next() {
				this.stopPlayer();
				//下一章
				if (this.idPlan < this.resourceData.list.length - 1) {
					this.playaudio = true;
					this.clickAudioResource(this.idPlan + 1);
				} else {
					//已经是最后一章了
					// uni.showToast({
					// 	title: "已经是最后一章了.",
					// 	icon: 'none',
					// 	duration: 2000
					// });
				}
			},
			//停止播放记录当前时间
			stopPlayer() {
				var that = this;
				if (this.isLogin) {
					if (this.nowResource && this.nowResource.resource_type && this.nowResource.product_id && this.nowResource.resource_id) {
						var now_timestamp = Date.parse(new Date()); //当前时间戳
						var duration = (now_timestamp - this.read_timestamp) / 1000; //阅读时间，单位秒
						var curr_time = this.$refs.audio.currentTime;
						var param = {
							product_id: this.nowResource.product_id || "",
							resource_id: this.nowResource.resource_id || "",
							curr_time: parseInt(curr_time) || 0,
							duration: duration,
						}
						this.Ajax(apiUrl.resource_progress_update, param, function(data, code) {
							if (code == 0) {
								that.read_timestamp = Date.parse(new Date());
								console.log("提交音频记录")
							}
						});
					}
				}
			},
			clickAudioResource(index) {
				//试听目录切换试听
				var that = this;
				var resource = that.resourceData.list[index];
				if (resource.is_try != 1 && resource.is_available != 1) {
					//不可试听
					this.$alert('登录并订阅后才能播放', '错误', {
						confirmButtonText: '确定',
						callback: action => {
							// this.$message({
							//   type: 'info',
							//   message: `action: ${ action }`
							// });
						}
					});
				} else {
					that.autoplayBySrcChange = true;
					that.nowResourceDataGet(index);

					that.read_timestamp = Date.parse(new Date());
					//刷新目录
					// var unAutoPlay = true; //不自动播放
					// setTimeout(function() {
					// 	that.setupResourceData(unAutoPlay);
					// }, 1000);
				}
			},
			//Detail
			setupDetailData(callback) {
				var that = this;
				var url = apiUrl.column_detail_get;
				var param = that.detailSearch;
				this.Ajax(url, param, (data, code) => {
					if (data) {
						that.detailData = data;
						document.title = data.title + " - 有声书 - 机工新阅读";
						if (data.qrcode_url && data.qrcode_url != "") {
							// setTimeout(() => {
							that.setupQrCode(data.qrcode_url);
							// }, 500);
						}

						if (callback) callback();
					
					}
				});
			},
			//ResourceData
			setupResourceData(unAutoPlay) {
				//资源列表
				var that = this;
				that.resourceSearch.product_id = that.detailSearch.id;
				this.Ajax(apiUrl.resource_list_get, that.resourceSearch, (data, code) => {
					if (data) {
						if (that.resourceSearch.page_index == 1) {
							that.resourceData = data;
							if (unAutoPlay) {
								return
							}

							//历史记录
							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id != '') {
								for (let i = 0; i < that.resourceData.list.length; i++) {
									if ((that.resourceData.list[i].is_try == 1 || that.resourceData.list[i].is_available == 1) && that.resourceData.list[i].resource_id == that.detailData.resource_id) {
										that.nowResourceDataGet(i);
										return;
									}
								}
							}


							//试听内容
							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id == '') {
								for (let i = 0; i < that.resourceData.list.length; i++) {
									if (that.resourceData.list[i].is_try == 1 || that.resourceData.list[i].is_available == 1) {
										that.nowResourceDataGet(i);
										return;
									}
								}
								that.nowResourceDataGet(0);
							}

						} else {
							that.resourceData.list.push.apply(that.resourceData.list, data.list);

							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id != '') {
								data.list.forEach((item, index) => {
									if ((item.is_try == 1 || item.is_available == 1) && item.resource_id == that.detailData.resource_id) {
										var num = parseInt(item.num) - 1;
										that.nowResourceDataGet(num);
										//that.loading = false;
										return;
									}
								});
							}
						}
					}
					// console.log(nowResource.content)
				});
			},
			//Current Resource Detail
			nowResourceDataGet(index) {
				//获取正在播放的资源
				var that = this;
				var resource = that.resourceData.list[index];
				var url = apiUrl.resource_detail_get;
				var param = {
					product_id: that.detailSearch.id,
					resource_id: resource.resource_id,
				};
				this.Ajax(url, param, (data) => {
					if (data) {
						if (data.is_try == 1 || data.is_available == 1) {
							that.idPlan = index;

							if (that.playaudio) {
								data.curr_time = 0;
							}
							that.nowResource = data;
						}
					}
				});
			},
			//QrCode
			setupQrCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					background: "#f0f", // 背景色
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			//Page: EbookDetail
			goToEBookDetailPage(item) {
				//var id = this.detailData.landing_page_id;
				//this.GoToDetail(1, this.detailData.landing_page_id);
				this.GoToDetail(1, item.id);
			},
			//Page: AllBook
			goToAllBookPage(item) {
				localStorage.setItem("toPagePath", "aubook");

				var query = {
					type: 3,
					level: item.level,
					id: item.id,
				};
				this.GoToAllBook(query);
			},
			//Page: Read personList
			goToPersonPage(id) {
				localStorage.setItem("toPagePath", "aubook");
				var url = "seebook";
				var query = {
					product_type: this.detailData.product_type,
					product_name: this.detailData.title,
					id: id,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				// this.$router.push({
				//   path: url,
				//   query: query,
				// });
			},
			setmaxwidth(str) {
				if (str) {
					str = str.replace(/\<img/gi, '<img style="max-width:100%;height:auto"');
				}
				return str;
			},
			changeSortType() {
				if (this.resourceData.list) {
					this.sort = this.sort == 0 ? 1 : 0;
					this.resourceData.list = this.resourceData.list.reverse();
				}
			},
			clickCatalogue(index) {
				if (!this.paused) {
					this.stopPlayer();
				}
				this.clickAudioResource(index);
				this.drawer = !this.drawer;
				this.playaudio = false;
			},
		}
	};
</script>


<style lang="scss" scoped>
	.audio {
		width: 100%;
		background: #f7f7f7;
		min-width: 1157px;
		cursor: default;
		position: relative;

		.maxBox {
			width: 1157px;
			// background: yellow;
			// border: 1px solid red;
			margin: 0 auto;

			.mianb {
				width: 100%;
				height: 75px;
				// border: 1px solid red;

				margin: 0 auto;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #363636;

				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #e6554d !important;
				}
			}

			.dTals {
				width: 100%;
				height: 275px;
				// border: 1px solid red;
				display: flex;
				background: #fff;
				margin-bottom: 40px;
				position: relative;

				.hongb {
					width: 162px;
					height: 178px;
					// border: 1px solid red;
					position: absolute;
					right: 60px;
					top: -20px;
					background-image: url("../../assets/image/hongb.png");
					background-size: 100%;

					.qrcode {
						width: 120px;
						height: 120px;
						border: 5px solid #fff;
						margin: 9px 0px 0 14px;

						.qr-code {
							width: 100%;
							height: 100%;

							//
						}
					}

					div {
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ffffff;

						letter-spacing: 2px;
						text-align: center;
					}

					.p1 {
						margin-top: 7px;
						// border: 1px solid red;
					}

					.p2 {
						text-align: center;
						width: 130px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0 auto;

						// border: 1px solid red;
					}
				}

				// .zxsm {
				//   width: 70px;
				//   height: 150px;
				//   background: #e6564e;
				//   border-radius: 8px;
				//   position: fixed;
				//   right: 300px;
				//   top: 270px;
				//   // display: flex;
				//   // align-items: center;
				//   // justify-content: center;
				//   z-index: 9;
				//   .saomaBg {
				//     height: 245px;
				//     width: 200px;
				//     position: fixed;
				//     right: 370px;
				//     top: 260px;
				//     display: none;
				//     z-index: 9;
				//     .qrBg {
				//       width: 100%;
				//       height: 100%;
				//       position: relative;

				//       .saoma {
				//         width: 100%;
				//         height: 100%;
				//       }

				//       .qrcode {
				//         position: absolute;
				//         top: 30px;
				//         left: 30px;
				//         width: 130px;
				//         height: 130px;
				//       }
				//       .foas {
				//         // border: 1px solid red;
				//         width: 100%;
				//         // border: 1px solid red;
				//         position: absolute;
				//         bottom: 20px;
				//         right: 5px;

				//         p {
				//           text-align: center;
				//           font-size: 16px;
				//           font-family: PingFang-SC-Medium, PingFang-SC;
				//           font-weight: 500;
				//           color: #000000;
				//           letter-spacing: 1px;
				//         }
				//       }
				//     }
				//   }
				//   &:hover {
				//     .saomaBg {
				//       display: block;
				//     }
				//   }
				//   .olas {
				//     width: 37.4px;
				//     height: 37.4px;
				//     margin-top: 17px;
				//     margin-left: 17px;
				//   }
				//   p {
				//     font-size: 18px;
				//     font-family: PingFang-SC-Heavy, PingFang-SC;
				//     font-weight: 800;
				//     color: #ffffff;
				//     margin-left: 16px;
				//     letter-spacing: 3px;
				//   }
				// }

				.magsL {
					width: 306px;
					height: 100%;

					display: flex;
					align-items: center;

					.magz {
						width: 164px;
						height: 164px;
						cursor: pointer;
						// border: 1px solid red;
						margin: 0 auto;
						position: relative;
						border-radius: 50%;

						// border: 1px solid red;
						.ems {
							width: 44px;
							height: 44px;
							position: absolute;
							top: 60px;
							left: 60px;
						}
					}
				}

				.totlR {
					width: 851px;
					height: 100%;

					.rtops {
						width: 370px;

						margin-top: 30px;
						// display: flex;
						// flex-direction: column;
						justify-content: space-between;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;

						.tits {
							// height: 42px;
							font-size: 24px;
							// font-family: PingFang-SC-Bold, PingFang-SC;
							font-weight: bold;
							color: #2b2b2b;
							// line-height: 42px;
							letter-spacing: 1px;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
						}

						.addys {
							display: flex;
							align-items: center;
							margin-right: 60px;
							margin-top: 14px;

							font-weight: 500;
							color: #666666;
							font-size: 18px;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
						}
					}

					.rbotms {
						width: 100%;
						height: 74px;

						margin-top: 50px;
						display: flex;
						justify-content: space-between;

						.botLf {
							width: 282px;
							height: 74px;
							background: #e6564e;
							box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
							border-radius: 8px;
							text-align: center;

							.p1 {
								height: 33px;
								font-size: 24px;
								font-family: PingFang-SC-Bold, PingFang-SC;
								font-weight: bold;
								color: #ffffff;
							}

							.p2 {
								height: 22px;
								font-size: 15px;
								font-family: SFUDINMitAlt;
								color: #ffffff;
							}
						}

						.botRt {
							width: 412px;
							height: 72px;

							// margin-bottom: 20px;
							.rt1 {
								width: 100%;
								height: 28px;
								font-size: 18px;
							}

							.rt2 {
								width: 100%;
								height: 30px;
								display: flex;
								margin-top: 14px;

								font-size: 18px;

								.tssz {
									display: flex;
									align-items: center;
									margin-right: 50px;
								}

								.tsd {
									display: flex;
									align-items: center;
									cursor: pointer;

									img {
										margin-right: 5px;
									}
								}
							}
						}
					}
				}
			}

			.tabs {
				margin-top: 30px;
				width: 100%;
				background: #fff;
				// border: 1px solid red;
				box-shadow: none !important;
				border: none !important;
				position: relative;

				.icons {
					// width: 134px;
					height: 48px;
					background: #ffffff;
					cursor: pointer;
					// background: cornflowerblue;
					// border: 1px solid red;
					padding: 0 18px;
					position: absolute;
					right: 0;
					top: -15px;
					border-radius: 24px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					position: absolute;
					right: 0;
					z-index: 999;
					font-size: 20px;

					// color: red;
					img {
						width: 30px;
						height: 30px;
						margin-right: 5px;
					}

					span {
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #333333;
						font-size: 20px !important;
						// color: red;
					}
				}

				.eltabs {
					box-shadow: none !important;
					border: none !important;

					.news {
						width: 100%;
						height: auto;

						.titsa {
							// background: red;
							text-align: center;
							margin-top: 80px;
							height: 42px;
							font-size: 26px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							color: #e6564e;
						}

						.txtmsg {
							width: 1040px;
							margin: 38px auto 0 auto;
							font-size: 16px;
							line-height: 2;
							color: #C5C5C5;
						}

						.conten {
							margin: 0 auto;
							margin-top: 38px;
							width: 1040px;
							// height: 352px;
							font-size: 20px;
							font-family: PingFang-SC-Medium, PingFang-SC;
							font-weight: 500;
							color: #666666;
							line-height: 48px;

							// background: cornflowerblue;
							margin-bottom: 80px;
						}
					}

					.hots {
						.netsl {
							width: 1040px;
							margin: 0 auto;
							margin-top: 40px;
							margin-bottom: 31px;
							font-size: 26px;
							font-family: PingFang-SC-Medium, PingFang-SC;
							font-weight: 500;
							color: #666666;
							line-height: 48px;
							display: flex;

							.tags {
								margin-right: 12px;
								background: #eeeeee;
								border-radius: 14px;
								font-size: 19px;
								font-family: PingFang-SC-Medium, PingFang-SC;
								font-weight: 500;
								color: #666666;
								line-height: 29px;
								letter-spacing: 1px;
								padding: 0 15px;
							}
						}

						.title {
							width: 1048px;
							height: 632px;
							margin: 0 auto;
						}

						.mess {
							// border: 1px solid red;

							.description {
								width: 800px;
								// border: 1px solid blue;
								margin: 0 auto !important;
								font-size: 20px;
							}
						}
					}
				}

				.el-tabs--border-card>.el-tabs__header {
					border-bottom: none;
				}

				.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
					font-size: 22px !important;
					font-family: PingFang-SC-Heavy, PingFang-SC;
					// font-weight: 800;
					color: #2b2b2b;
					background-color: #f5f7fa;
					border-bottom: 42px solid #fff;
					border-left: 15px solid #fff;
					border-right: 15px solid transparent;
					font-size: 20px !important;
				}

				// border-bottom: 43px solid #fff;
				// border-left: 15px solid #fff;
				// border-right: 15px solid transparent;
				.el-tabs--border-card>.el-tabs__header #tab-second.is-active {
					background-color: #f5f7fa;
					border-bottom: 40px solid #fff;
					border-left: 15px solid #f5f7fa;
					border-right: 15px solid #fff;
					font-size: 20px !important;
				}

				.el-tabs--border-card>.el-tabs__header #tab-second {
					height: 41px;
					font-size: 20px !important;
				}

				.el-tabs--border-card>.el-tabs__header #tab-first {
					height: 41px;
					font-size: 24px !important;
				}

				.el-tabs__item {
					font-size: 20px !important;
					// background: red;
				}

				.el-tabs__item:hover {
					color: #2b2b2b !important; //修改鼠标移动到表头时候字体颜色，默认淡蓝色
					cursor: pointer; //鼠标移动到表头时候鼠标样式，默认小手
				}
			}
		}

		.aplayer {
			width: 100%;
			height: 66px;
			position: fixed;
			bottom: 0;
			left: 0;
			background-color: #ffffff;
			box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 9999999;


			.box {
				width: 1037px;
				margin: 0 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				position: absolute;

				.control {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 150px;

					img {
						cursor: pointer;
					}
				}

				.cover {
					width: 50px;
					height: 50px;
					cursor: pointer;
					margin: 0 40px;
					position: relative;
					border-radius: 50%;
					box-shadow: 0 0 4px rgba(0, 0, 0, .2);

					.icon {
						width: 20px;
						height: 20px;
						position: absolute;
						top: 15px;
						left: 15px;
					}
				}

				.wrapper {
					width: 560px;
					display: flex;
					flex-direction: column;

					.title {
						font-size: 15px;
					}

					.slider {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;

						.timebar {
							font-size: 14px;
							padding-top: -4px;
						}
					}
				}

				.btns {
					margin-left: 40px;

					.play-rate {
						border: 1px solid #333;
						padding: 2px 5px;
						border-radius: 5px;
						font-size: 12px;
						cursor: pointer;
					}
				}

				.catalogR {
					margin-left: 40px;
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 20px;
						height: 20px;
					}

					span {
						font-size: 12px;
						margin-top: 3px;
					}
				}
			}

			/deep/.el-slider__button-wrapper {
				top: -18px;
			}

			/deep/.el-slider__button {
				border: 0 !important;
				cursor: pointer;
				background-color: rgb(255, 255, 255);
				box-shadow: 0 0 4px rgba(0, 0, 0, .2);
			}

			/deep/.el-slider__runway {
				height: 3px !important;
				background-color: rgb(233, 233, 233);
				width: 400px;
			}

			/deep/.el-slider__bar {
				background-color: #E7584E !important;
				height: 3px !important;

			}
		}


		/deep/:focus {
			outline: 0;
		}

		.popupTitle {
			width: 100%;
			height: 45px;

			margin-top: 80px;
			display: flex;
			justify-content: space-between;

			.diva {
				font-size: 24px;
				font-family: PingFang-SC-Bold, PingFang-SC;
				font-weight: bold;
				color: #333333;
				margin-left: 65px;
			}

			.divb {
				display: flex;
				align-items: center;
				margin-right: 61px;

				.dism {
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #e6564e;
				}

				.divimg {
					width: 18px;
					height: 25px;
					margin-left: 10px;

					.ilmge {
						width: 100%;
						height: 100%;
					}

					.sort2 {
						transform: rotate(180deg);
					}
				}
			}
		}

		.fenj {
			width: 460px;
			height: 2px;
			background: #eeeeee;
			margin: 0 auto;
			margin-top: 25px;
		}

		.catalogue {
			width: 460px;
			// min-width: 460px;
			//height: 610px;
			height: 700px;
			margin-left: 55px;
			margin-top: 40px;
			// border: 1px solid red;
			overflow-y: auto;
			padding-bottom: 55px;

			.losl {
				width: 100%;
				max-height: 60px;
				display: flex;
				justify-content: space-between;
				margin-bottom: 24px;
				cursor: pointer;

				&.actived {
					color: #e6554d;
				}

				.spone {
					font-size: 20px;
					line-height: 1.5;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
				}

				.sptow {
					width: 45px;
					height: 32px;
					font-size: 22px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #e6564e;
					line-height: 32px;
				}
			}
		}

		::-webkit-scrollbar {
			width: 0px;
		}
	}
</style>
<style>
	.el-popper {
		z-index: 100000000 !important;
		min-width: 100px !important;
		width: 100px !important;
	}

	.el-popper ul {
		margin: 0;
		padding: 0;
		list-style: none !important;
		text-align: center;
		font-size: 14px;
	}

	.el-popper ul li {
		line-height: 1.75;
		color: #515a6e;
	}

	.el-popper ul li:hover,
	.el-popper ul li.actived {
		color: #E7584E;
	}
</style>
