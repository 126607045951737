<template>
  <div id="iframeBg">
    <div class="allBox">
      <div class="navPC">
        <div class="nav">
          <ul>
            <li>
              <span class="pointer" @click="event_navToPage_pc(1, '')"
                >机工新阅读</span
              >
            </li>
            <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
            </li>
            <li>
              <span class="pointer" @click="event_navToPage_pc(2, '')"
                >师资</span
              >
            </li>
            <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
            </li>
            <li>
              <span class="pointer color">搜索</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="contentPC">
        <div class="contentBox">
          <div class="inpBox">
            <div class="inputBoxOll">
              <input
                class="inp"
                type="text"
                v-model="search_keyword"
                @keyup.enter="search(search_keyword)"
                @blur="search(search_keyword)"
                placeholder="名字、职业、领域、关键字"
              />
              <div class="inpBtn">
                <span>搜索</span>
              </div>
            </div>

            <div class="screen">
              <div
                class="screenBox"
                :class="isPop ? 'screenBoxACs' : ''"
                @click="showPop((state = 0))"
              >
                <span class="isSpan">筛选</span>
                <img
                  v-if="isPop == false"
                  src="../../../assets/img/iconBotton.png"
                  alt
                />
                <img
                  class="isImg"
                  v-else
                  src="../../../assets/img/down.png"
                  alt
                />
                <div class="UPpop screenBoxAC" v-if="isPop">
                  <span
                    class="states"
                    :class="habitationType == 0 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(0)"
                    >不限</span
                  >
                  <span
                    class="states"
                    :class="habitationType == 10 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(10)"
                    >国内</span
                  >
                  <span
                    class="states"
                    :class="habitationType == 20 ? 'state' : ''"
                    @click="event_selectHabitationTypeClick(20)"
                    >海外</span
                  >
                </div>
              </div>
              <div
                class="screenBox"
                :class="isPops ? 'screenBoxACs' : ''"
                @click="showPop((state = 1))"
              >
                <span class="isSpan">排序</span>
                <img
                  v-if="isPops == false"
                  src="../../../assets/img/iconBotton.png"
                  alt
                />
                <img
                  class="isImg"
                  v-else
                  src="../../../assets/img/down.png"
                  alt
                />
                <div class="UPpop screenBoxAC" v-if="isPops">
                  <span
                    class="states"
                    :class="search_orderBy == 'normal' ? 'state' : ''"
                    @click="event_orderByTypeClick(0)"
                    >综合</span
                  >
                  <div class="UPpopBox" @click="event_orderByTypeClick(1)">
                    <span
                      class="spanS"
                      :class="search_orderBy != 'normal' ? 'state' : ''"
                      @click="showImg"
                      >入驻时间</span
                    >
                    <div class="UPpopBoxAc">
                      <img
                        class="upImg"
                        v-if="isImg == true"
                        src="../../../assets/img/up.png"
                        alt
                      />
                      <img
                        class="upImg"
                        v-if="isImg == false"
                        src="../../../assets/img/upAc.png"
                        alt
                      />
                      <img
                        class="upImg"
                        v-if="isImg1 == true"
                        src="../../../assets/img/down_Icon1.png"
                      />
                      <img
                        class="upImg"
                        v-if="isImg1 == false"
                        src="../../../assets/img/down_Icon1Ac.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="personMesList">
            <div class="history" v-show="isShow">
              <div class="histotyBox">
                <p>
                  热门搜索
                  <img src="../../../assets/img/hot.png" alt />
                </p>
                <div class="label">
                  <span
                    v-for="(item, i) in hotList"
                    :key="i"
                    @click="event_hotListItemClick(item)"
                    >{{ item }}</span
                  >
                </div>
              </div>
              <div class="histotyBox">
                <p>历史搜索</p>
                <div class="label">
                  <span
                    v-for="(item, i) in historyList"
                    :key="i"
                    @click="event_hotListItemClick(item)"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="ishide">
              <el-tabs
                v-model="activeName"
                @tab-click="event_tabbarTitleClick_pc"
                class="tabs"
              >
                <el-tab-pane label="全部" name="全部">
                  <ul class="UlBox">
                    <li
                      class="personLi"
                      v-for="(item, i) in LecturerList"
                      :key="i"
                      @click="event_lecturerItemClick(item)"
                    >
                      <div class="personLiBox">
                        <el-image class="personPhoto" :src="item.image">
                          <div slot="placeholder" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                          <div slot="error" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                        </el-image>
                        <!-- <img class="personPhoto" :src="item.image || require('../../../assets/img/avatar_place.png')" alt /> -->
                        <div class="personMes">
                          <div class="personMesName">
                            <span class="name">{{ item.name }}</span>
                            <img
                              class="collect"
                              v-show="item.isFavorite == 1"
                              src="../../../assets/img/icom8.png"
                              alt
                            />
                          </div>
                          <div class="personMesoll">
                            <div class="personMesollP">
                              <p
                                v-for="(titleItem, j) in item.titleList"
                                :key="j"
                              >
                                {{ titleItem }}
                              </p>
                            </div>
                            <img
                              class="rightIocn"
                              src="../../../assets/img/youjiantou.png"
                              alt
                            />
                          </div>
                          <div class="sort">
                            <span
                              class="labelBox"
                              v-for="(tagItem, j) in item.tagList"
                              :key="j"
                              >{{ tagItem }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- 分页 -->
                  <!-- hide-on-single-page -->
                  <div class="pagination">
                    <el-pagination
                      background
                      layout="pager"
                      :total="totalListNum"
                      @current-change="handleSizeChange"
                      hide-on-single-page
                      :current-page="currentPage"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="嘉宾" name="嘉宾">
                  <ul class="UlBox">
                    <li
                      class="personLi"
                      v-for="(item, i) in LecturerList"
                      :key="i"
                      @click="event_lecturerItemClick(item)"
                    >
                      <div class="personLiBox">
                        <el-image class="personPhoto" :src="item.image">
                          <div slot="placeholder" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                          <div slot="error" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                        </el-image>
                        <!-- <img class="personPhoto" :src="item.image || require('../../../assets/img/avatar_place.png')" alt /> -->
                        <div class="personMes">
                          <div class="personMesName">
                            <span class="name">{{ item.name }}</span>
                            <img
                              class="collect"
                              v-show="item.isFavorite == 1"
                              src="../../../assets/img/icom8.png"
                              alt
                            />
                          </div>
                          <div class="personMesoll">
                            <div class="personMesollP">
                              <p
                                v-for="(titleItem, j) in item.titleList"
                                :key="j"
                              >
                                {{ titleItem }}
                              </p>
                            </div>
                            <img
                              class="rightIocn"
                              src="../../../assets/img/youjiantou.png"
                              alt
                            />
                          </div>
                          <div class="sort">
                            <span
                              class="labelBox"
                              v-for="(tagItem, j) in item.tagList"
                              :key="j"
                              >{{ tagItem }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- 分页 -->
                  <!-- hide-on-single-page -->
                  <div class="pagination">
                    <el-pagination
                      background
                      layout="pager"
                      :total="totalListNum"
                      @current-change="handleSizeChange"
                      hide-on-single-page
                      :current-page="currentPage"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="职业领域" name="职业领域">
                  <ul class="UlBox">
                    <li
                      class="personLi"
                      v-for="(item, i) in LecturerList"
                      :key="i"
                      @click="event_lecturerItemClick(item)"
                    >
                      <div class="personLiBox">
                        <el-image class="personPhoto" :src="item.image">
                          <div slot="placeholder" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                          <div slot="error" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                        </el-image>
                        <!-- <img class="personPhoto" :src="item.image || require('../../../assets/img/avatar_place.png')" alt /> -->
                        <div class="personMes">
                          <div class="personMesName">
                            <span class="name">{{ item.name }}</span>
                            <img
                              class="collect"
                              v-show="item.isFavorite == 1"
                              src="../../../assets/img/icom8.png"
                              alt
                            />
                          </div>
                          <div class="personMesoll">
                            <div>
                              <p
                                v-for="(titleItem, j) in item.titleList"
                                :key="j"
                              >
                                {{ titleItem }}
                              </p>
                            </div>
                            <img
                              class="rightIocn"
                              src="../../../assets/img/youjiantou.png"
                              alt
                            />
                          </div>
                          <div class="sort">
                            <span
                              class="labelBox"
                              v-for="(tagItem, j) in item.tagList"
                              :key="j"
                              >{{ tagItem }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- 分页 -->
                  <!-- hide-on-single-page -->
                  <div class="pagination">
                    <el-pagination
                      background
                      layout="pager"
                      :total="totalListNum"
                      @current-change="handleSizeChange"
                      hide-on-single-page
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="擅长专业" name="擅长专业">
                  <ul class="UlBox">
                    <li
                      class="personLi"
                      v-for="(item, i) in LecturerList"
                      :key="i"
                      @click="event_lecturerItemClick(item)"
                    >
                      <div class="personLiBox">
                        <el-image class="personPhoto" :src="item.image">
                          <div slot="placeholder" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                          <div slot="error" class="personPhoto">
                            <img
                              class="personPhoto"
                              src="../../../assets/img/avatar_place.png"
                              alt
                            />
                          </div>
                        </el-image>
                        <!-- <img class="personPhoto" :src="item.image || require('../../../assets/img/avatar_place.png')" alt /> -->
                        <div class="personMes">
                          <div class="personMesName">
                            <span class="name">{{ item.name }}</span>
                            <img
                              class="collect"
                              v-show="item.isFavorite == 1"
                              src="../../../assets/img/icom8.png"
                              alt
                            />
                          </div>
                          <div class="personMesoll">
                            <div>
                              <p
                                v-for="(titleItem, j) in item.titleList"
                                :key="j"
                              >
                                {{ titleItem }}
                              </p>
                            </div>
                            <img
                              class="rightIocn"
                              src="../../../assets/img/youjiantou.png"
                              alt
                            />
                          </div>
                          <div class="sort">
                            <span
                              class="labelBox"
                              v-for="(tagItem, j) in item.tagList"
                              :key="j"
                              >{{ tagItem }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- 分页 -->
                  <!-- hide-on-single-page -->
                  <div class="pagination">
                    <el-pagination
                      background
                      layout="pager"
                      :total="totalListNum"
                      @current-change="handleSizeChange"
                      hide-on-single-page
                    ></el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import elementResizeDetectorMaker from "element-resize-detector";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import Vue from "vue";

export default {
  components: {
    fooTer,
  },
  data() {
    return {
      isMobile: true,
      isShow: true,
      ishide: false,
      isPop: false,
      isPops: false,
      isImg: false,
      isImg1: false,
      isAsc: false,
      isSpan: false,
      hotList: [],
      historyList: [],
      userId: "",
      currentPage: 1,
      pageSize: 10,
      totalListNum: 0,
      LecturerList: [],
      search_keyword: "",
      search_orderBy: "normal",
      selectCategory: { name: "", id: 0 }, //cateId
      search_searchType: 0, //0全部 10：嘉宾；20：职业领域；30：擅长专业
      habitationType: 0, //0未设置  10国内  20海外

      //vant list refresh
      loading: false,
      finished: false,
      refreshing: false,
      activeName: "全部",
      navLevel: 3,
    };
  },

  methods: {
    // PCtab切换
    event_navToPage_pc(type, info) {
      if (type == 1) {
        //机工新阅读
        //测试代码:
        this.$router.push({
          path: "teachers",
          query: {},
        });
      } else if (type == 2) {
        //师资   / 热门
        this.$router.push({
          path: "GuestList",
          query: {},
        });
      } else if (type == 3) {
        //讲师信息
        this.$router.push({
          path: "GuestDetail",
          query: {
            id: info || "",
          },
        });
      }
    },
    // event_navToPage_pc(type, info) {
    //   var navIndex = type - this.navLevel;
    //   if (type == 1) {
    //     //机工新阅读
    //     //测试代码:
    //     navIndex += 1;
    //     this.$router.go(navIndex);
    //   } else if (type == 2) {
    //     //师资   / 热门
    //     this.$router.go(navIndex);
    //   } else if (type == 3) {
    //     //讲师信息
    //     this.$router.go(navIndex);
    //   }
    // },

    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleSizeChange(e) {
      this.currentPage = e;
      this.LecturerList = [];
      this.onLoad();
    },

    onLoad() {
      //网络加载
      if (this.refreshing) {
        this.refreshing = false;
      }

      var that = this;
      if (this.finished) {
        return;
      }
      var data = {
        cateId: this.selectCategory ? this.selectCategory.id : 0,
        keyword: this.search_keyword ? this.search_keyword : "",
        orderBy: this.search_orderBy ? this.search_orderBy : "normal",
        searchType: this.search_searchType ? this.search_searchType : "0",
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      var userId = this.userId || "";
      if (userId) data["userId"] = userId;
      if (this.habitationType == 10 || this.habitationType == 20)
        data["habitationType"] = this.habitationType;

      this.AjaxTeacher(ApiUrl.getLecturerList, data, function (res) {
        that.currentPage = res.currentPage;
        if (that.currentPage == 1) that.LecturerList = [];
        var tempArray = that.LecturerList;
        tempArray = tempArray.concat(res.rows);
        that.LecturerList = tempArray;
        that.totalListNum = res.total;
        that.loading = false;
        that.finished = that.LecturerList.length == res.total;
        if (that.isMobile) {
          //手机
          if (!that.finished) that.currentPage += 1;
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    setupData() {
      this.getHotList();
      this.getHistoryList();
    },
    getHotList() {
      var that = this;
      this.AjaxTeacher(ApiUrl.getHotKeywordList, {}, function (res) {
        that.hotList = res;
      });
    },
    getHistoryList() {
      var historyKeyWordStr = localStorage.getItem("historyKeyWordStr") || "";
      if (historyKeyWordStr != "") {
        this.historyList = historyKeyWordStr.split(",") || [];
      } else {
        this.historyList = [];
      }
    },

    addHistoryName(addName) {
      addName = addName.replace(/\s+/g, "");

      if (addName == "") return;
      var historyKeyWordStr = localStorage.getItem("historyKeyWordStr") || "";
      if (historyKeyWordStr != "") {
        this.historyList = historyKeyWordStr.split(",") || [];
      } else {
        this.historyList = [];
      }
      var tempArr = [];
      tempArr.push(addName);
      for (var i = 0; i < this.historyList.length; i++) {
        var item = this.historyList[i];
        if (item != addName) tempArr.push(item);
      }

      this.historyList = tempArr;
      localStorage.removeItem("historyKeyWordStr");
      localStorage.setItem("historyKeyWordStr", this.historyList.join(","));
    },
    event_tabbarTitleClick(name, title) {
      this.search_searchType = 0;
      if (title == "全部") this.search_searchType = 0;
      if (title == "嘉宾") this.search_searchType = 10;
      if (title == "职业领域") this.search_searchType = 20;
      if (title == "擅长专业") this.search_searchType = 30;
      this.search(this.search_keyword);
    },
    event_tabbarTitleClick_pc(name) {
      var title = name.name;
      this.event_tabbarTitleClick(name, title);
    },
    event_hotListItemClick(name) {
      this.search(name);
    },
    search(name) {
      var inputV = document.getElementById("search");
      if (inputV) inputV.blur();
      this.LecturerList = [];
      this.search_keyword = name;
      this.addHistoryName(name);
      console.log(name);
      if (name) {
        this.isShow = false;
        this.ishide = true;
        this.isSpan = true;
      } else {
        this.ishide = false;
        this.isShow = true;
        this.isSpan = false;
      }
      this.getLecturerList();
    },
    clearIpt() {
      this.search_keyword = "";
      this.isSpan = false;
      this.ishide = false;
      this.isShow = true;
    },
    getLecturerList() {
      this.finished = false;
      this.currentPage = 1;
      this.onLoad();
    },

    event_selectHabitationTypeClick(type) {
      this.habitationType = type;
      this.getLecturerList();
    },
    event_orderByTypeClick(type) {
      if (type == 0) {
        this.isAsc = false;
        this.search_orderBy = "normal";
      } else {
        this.search_orderBy = this.isAsc ? "timeDesc" : "timeAsc";
        this.isAsc = !this.isAsc;
      }
      this.getLecturerList();
    },

    showPop(state) {
      console.log(state);
      if (state == 0) {
        this.isPop = !this.isPop;
      } else {
        this.isPops = !this.isPops;
      }
    },

    close() {
      this.isPop = false;
      this.isPops = false;
    },
    showImg() {
      this.isImg = !this.isImg;
      this.isImg1 = !this.isImg1;
    },
    event_lecturerItemClick(item) {
      var name = item.name || "嘉宾";
      var id = item.id || "";
      if (id == "") {
        console.log("嘉宾不能为空");
        return;
      }
      //跳转页面
      var url = "GuestDetail";
      var query = {
        name: name,
        id: id,
      };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
  },
  beforeRouteEnter(to, from, next) {
    var flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    var isMobile = !(flag === null);

    next((vm) => {
      vm.isMobile = isMobile;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "GuestList") {
      from.meta.keepAlive = false;
    } else {
      from.meta.keepAlive = true;
    }
    next();
  },
  created() {
    document.title = "师资 - 机工新阅读";
    this.userId = localStorage.getItem("userId");
    this.setupData();
  },
  mounted() {
    if (this.rightNavShow) {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      var app = document.getElementById("iframeBg");
      erd.listenTo(app, (element) => {
        _this.$nextTick(() => {
          var iHeight = app.offsetHeight + 11;
          iHeight = iHeight > 500 ? iHeight : 500;
          parent.window.postMessage(
            { msgType: 1, msgData: { iHeight: iHeight } },
            "*"
          );
        });
      });
    }
  },
  watch: {
    LecturerList(val) {
      const that = this;
      if (!this.isMobile) {
        const erd = elementResizeDetectorMaker();
        var app = document.getElementById("iframeBg");
        erd.listenTo(app, (element) => {
          that.$nextTick(() => {
            var iHeight = app.offsetHeight + 11;
            iHeight = iHeight > 500 ? iHeight : 500;

            parent.window.postMessage(
              { msgType: 1, msgData: { iHeight: iHeight } },
              "*"
            );
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
// PC
.contentPC {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  padding-top: 11px;
  cursor: default;
  .contentBox {
    width: 100%;
    height: 100%;
    max-width: 1157px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    .inpBox {
      width: 93%;
      height: 79px;
      display: flex;
      border-bottom: 1px solid #eeeeee;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      .inputBoxOll {
        display: flex;
        align-items: center;
        .inpBtn {
          width: 117px;
          height: 45px;
          background: #e6554d;
          // background: linear-gradient(194deg, #55c87f 0%, #4fae82 100%);
          border-radius: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          cursor: pointer;
          span {
            color: #fff;
            font-size: 19px;
          }
        }
      }
    }
    .inp {
      width: 317px;
      height: 45px;
      background: #f7f7f7;
      border-radius: 23px;
      background-image: url(../../../assets/img/search.png);
      background-size: 22.5px;
      background-repeat: no-repeat;
      padding-left: 50px;
      background-position: 21px;
      color: #2b2b2b;
      font-size: 18px;
    }
    .inp::-webkit-input-placeholder {
      color: #c9c9c9;
      font-size: 18px;
      /* font-weight: bold; */
    }
    .screen {
      height: 100%;
      display: flex;
      width: 40%;
      align-items: center;
      font-size: 18px;
      color: #1c1c1c;
      justify-content: space-evenly;
    }
    .screen img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-left: 5px;
      margin-top: 2px;
      transition: all 0.5s;
    }
    .screenBox {
      cursor: pointer;
      width: 34%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 9px;
      padding-bottom: 5px;
      .UPpop {
        position: absolute;
        top: 38px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 11;
        padding-bottom: 16px;
        .states {
          width: 80%;
          text-align: center;
          border-bottom: 1px solid #eeeeee;
          padding: 13px 0px;
          color: #8a8a8a;
        }
        .state {
          color: #e6554d;
        }
        .headnav {
          position: relative;
          .addIcon {
            position: absolute;
            right: 5px;
            top: 13px;
          }
        }
        .UPpopBox {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .UPpopBoxAc {
            display: flex;
            flex-direction: column;
          }
          .spanS {
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }
    }
    .screenBoxAC {
      background-color: #fff;

      box-shadow: 0px 17px 15px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 12px 12px;
    }
    .screenBoxACs {
      background-color: #fff;

      box-shadow: 0px 17px 15px 0px rgba(0, 0, 0, 0.1);
      // border-radius: 0px 0px 12px 12px;
      .isSpan {
        color: #e6554d !important;
      }
      .isImg {
        transform: rotate(180deg);
      }
    }
    .personMesList ul::-webkit-scrollbar {
      display: none;
    }
    .personMesList {
      width: 100%;
      // height: 8.3rem;
      // max-height: 900px;
      min-height: 500px;
      white-space: nowrap;

      .tabs {
        width: 93%;
        margin: 0 auto;
      }
      .tabs /deep/ .el-tabs__active-bar {
        background-color: #e6554d;
        height: 3px;
      }
      .tabs /deep/ .el-tabs__nav-wrap::after {
        height: 0;
      }
      .tabs /deep/ .el-tabs__item.is-active {
        color: #e6554d;
      }
      .tabs /deep/ .el-tabs__item:hover {
        color: #e6554d;
      }
      .history {
        height: auto;
        width: 93%;
        margin: 0 auto;
        padding: 0;
        margin-top: 0.5rem;
        .histotyBox {
          margin-top: 30px;
          p {
            color: #2b2b2b;
            font-size: 0.24rem;
            font-weight: bold;
          }
          .label {
            display: flex;
            flex-wrap: wrap;
            span {
              padding: 5px 12px;
              background-color: #f9f9f9;
              color: #dadada;
              font-size: 0.21rem;
              border-radius: 5px;
              margin-right: 8px;
              margin-top: 8px;
              cursor: pointer;
            }
          }
        }
      }
      .UlBox {
        width: 100%;
        // height: 834.961px;
        overflow: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        li {
          width: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 167px;
          cursor: pointer;
          .personLiBox {
            width: 80%;
            border-bottom: 1px solid #eeeeee;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 20px 0;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0 auto;
            .personPhoto {
              width: 122px;
              height: 122px;
              border-radius: 50%;
              object-fit: cover;
            }
            .personMes {
              width: 64%;
              .personMesName {
                display: flex;

                align-items: center;
                .name {
                  color: #2b2b2b;
                  font-size: 27px;
                  font-weight: bold;
                }
                .collect {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                  margin-left: 12px;
                }
              }
              .mes {
                color: #cccccc;
                font-size: 15px;
              }
              .personMesoll {
                display: flex;
                color: #cccccc;
                font-size: 15px;
                justify-content: space-between;
                .personMesollP {
                  width: 100%;
                  p {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
                .rightIocn {
                  width: 30px;
                  height: 25px;
                  object-fit: cover;
                }
              }
              .sort {
                margin-top: 5px;
                span {
                  display: inline-block;
                  padding: 2px 10px;
                  background-color: #e0f3f7;
                  color: #52c4c6;
                  font-size: 15px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      .pagination {
        margin: 50px 0;
      }
      .pagination /deep/ .el-pagination {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.navPC {
    width: 100%;
    height: 75px;
    background-color: #F4F4F4;
  .nav {
    width: 100%;
    height: 100%;
    max-width: 1157px;
    margin: 0 auto;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        height: 100%;
        margin-right: 16px;
        display: flex;
        align-items: center;
        color: #363636;
        font-size: 16px;
        // font-weight: bold;
        .top {
          margin-top: 4px;
        }
        .pointer {
          cursor: pointer;
            font-weight: 700;
            color: #303133;
        }
          .pointer:hover{ 
            color:#429efd;
          }
        .color {
            color: #e6554d;
            font-weight: 400;
        }
          .color:hover{ 
            color:#e6564e;
            cursor: auto;
          }
      }
    }
  }
}
// 手机样式
.content {
  width: 100%;
  margin-top: 50px;
  .tabsBox {
    width: 100%;
    margin: 0 auto;
    margin-top: -15px;
    .personMes {
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding-bottom: 5px;
      .profile_photo {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        .logo {
          border-radius: 50%;
          overflow: hidden;
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 2rem;
          border-radius: 50%;
        }
      }
      .person {
        width: 68%;
        .namemes {
          display: flex;
          align-items: center;
          .name {
            color: #fe8608;
            font-size: 18px;
            font-weight: bold;
          }
          img {
            width: 0.8rem;
            height: 0.8rem;
            margin-left: 6px;
          }
        }
        .personLable {
          display: flex;
          align-items: end;
          justify-content: space-between;
          font-size: 12px;
          color: #cccccc;
          margin: 3px 0;
          max-height: 50px;
          min-height: 50px;
          overflow: hidden;
          img {
            width: 23px;
            height: 22px;
            object-fit: cover;
          }
          .label {
            display: flex;
            flex-direction: column;
            width: 90%;
            height: 100%;
            p {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 1.5;
            }
          }
        }
        .lableBox {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          height: 23px;
          max-height: 23px;
          overflow: hidden;
          span {
            display: inline-block;
            padding: 2px 5px;
            background-color: #e0f3f7;
            color: #52c4c6;
            font-size: 12px;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: 5px;
          }
        }
        .lableBox2 {
          background-color: #fff5e5;
          color: #fe8608;
        }
      }
    }
    .searcperone {
      width: 80%;
      margin: 0 auto;
      .head {
        height: 50px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .headnav {
          display: flex;
          align-items: center;
          margin-left: 44px;
          font-size: 14px;
          color: #1c1c1c;
          position: relative;
          .UPpop {
            width: 5rem;
            right: -26px;
            top: -9px;
            position: absolute;
            background-color: #fff;
            border-radius: 0 0 8px 8px;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            z-index: 100;
            align-items: center;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            .UPpopBox {
              width: 100%;
              display: flex;
              padding: 10px 0;
              border-bottom: 1px solid #eeeeee;
              text-align: center;
              align-items: center;
              span {
                padding: 0;
                border: 0;
              }
              .UPpopBoxAc {
                display: flex;
                flex-direction: column;
                .upImg {
                  width: 12px;
                  height: 9px;
                  margin: 0;
                }
              }
            }
            span {
              width: 100%;
              padding: 10px 0;
              border-bottom: 1px solid #eeeeee;
              text-align: center;
              font-size: 14px;
              color: #8a8a8a;
            }
            .state {
              color: #e6554d;
            }
            .headnav {
              width: 100%;
              position: relative;
              .addIcon {
                position: absolute;
                right: 5px;
                top: 13px;
              }
            }
          }
          .UPpop :last-child {
            border: 0;
          }
          .fix_Uppop {
            // right: 0.4rem;
          }
          img {
            width: 14px;
            height: 10px;
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
  }
  .tabsBox /deep/ .van-tab {
    flex: initial;
    padding: 0 12px;
  }
  .tabsBox /deep/ .van-tabs--line .van-tabs__wrap {
    border-bottom: 12px solid #f9f9f9;
  }
  .tabsBox /deep/ .van-tabs__nav--line {
    width: 84%;
    margin: 0 auto;
  }
  .tabsBox /deep/ .van-tabs__line {
    width: 20px;
    background-color: #e6554d;
  }
  .inptBox {
    display: flex;
    justify-content: center;
    padding: 30px 20px;
    .dleSpan {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 30%;
      background-color: #f7f7f7;
      border-radius: 0 20px 20px 0;
      span {
        font-size: 14px;
        color: #3a3a3a;
      }
    }
    .ipt {
      width: 80%;
      height: 35px;
      background: #f7f7f7;
      border-radius: 20px 0 0 20px;
      font-size: 14px;
      color: #3a3a3a;
      padding: 0 42px;
      padding-right: 5px;
      background-image: url(../../../assets/img/search.png);
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: 14px center;
    }
    .ipt::-webkit-input-placeholder {
      text-align: center;
      font-size: 14px;
      color: #c9c9c9;
    }
  }
  .history {
    width: 100%;

    .historyList {
      width: auto;

      margin-left: 25px;
      p {
        color: #2b2b2b;
        font-size: 17px;
        font-weight: bold;
      }
      .historyname {
        display: flex;
        flex-wrap: wrap;
        span {
          background-color: #f9f9f9;
          margin-top: 10px;
          font-size: 14px;
          color: #dadada;
          padding: 2px 8px;
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
    .historyjil {
      margin-top: 25px;
    }
  }
}
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}
.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}
// 蒙城
.pop {
  position: absolute;
  width: 100%;
  height: 200%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
}
</style>
