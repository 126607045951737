<template>
  <div class="livDtl">
    <div class="mianb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
        <el-breadcrumb-item><a href="/livebook">直播</a></el-breadcrumb-item>
        <el-breadcrumb-item>{{ detailData.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cents">
      <div class="mageL">
        <div class="mageb">
          <img
            style="
			width: 100%;
            /*height: 100%;
            object-fit: cover;
            border-radius: 9px;
			*/"
            :src="detailData.image_url"
            alt=""
          />
        </div>
      </div>
      <div class="centR">
        <div class="tits">
          {{ detailData.title }}
        </div>
        <span class="span1">浏览次数：{{ detailData.view_count }}</span>
        <!-- <div class="moys">免费</div> -->
        <p>{{ detailData.begindate }} - {{ detailData.enddate }}</p>
        <div class="xlive">
          <div class="span2">线上直播 - 小鹅通直播</div>
          <!-- <img src="../../assets/image/icon_7.png" alt="" /> -->
        </div>
        <div class="sfons">
          <div
            class="amn1"
            v-if="detailData.webcast_url && detailData.webcast_url != ''"
            @click="goToOutWebPage"
          >
            直播合作
          </div>
          <div
            class="amn2"
            v-if="detailData.webcast_url && detailData.webcast_url != ''"
            @click="goToOutWebPage"
          >
            立即前往
          </div>
        </div>
      </div>
    </div>
    <div class="ksoal" v-if="lecturerList.length > 0">
      <h5>讲师</h5>
      <div class="thbox">
        <div
          class="thors"
          v-for="(item, index) in lecturerList"
          :key="index"
          @click="goToAutoBookDetail(item)"
        >
          <div class="mlge">
            <!-- <img style="width: 100%; height: 100%; border-radius: 50%" alt="" /> -->
            <img
              style="
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              "
              alt=""
              :src="item.detail_image"
            />
            <!-- src="../../assets/image/buddha.png" -->
          </div>
          <div class="mlRgt">
            <div class="titi">{{ item.name }}</div>
            <p>{{ item.description }}</p>
          </div>
          <div class="iconts">
            <img src="../../assets/image/icon_rigth.png" alt="" />
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div class="cDtal">
      <div class="dTlft">
        <div class="tilm">直播详情</div>
        <div
          class="span1"
          v-for="(iem, imd) in detailData.product_classes"
          :key="imd"
          @click="goToAllBookPage(iem)"
        >
          {{ iem.title }}
        </div>

        <div class="mgs" v-html="detailData.description">
          <!-- <img style="width:100%;height:100%" src="" alt="" /> -->
        </div>
      </div>
      <div class="dTrgt">
        <div class="rgTop">
          <div class="tols">其他直播</div>
          <div class="cozm" @click="goToAllBookPage2()">更多 ></div>
          <div
            class="rtmgs"
            v-for="(ims, dex) in detailData.others"
            :key="dex"
            @click="goToOtherDetailPage(ims.product_type, ims.id)"
          >
            <div class="mgebox">
              <img
                style="
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 8px;
                "
                :src="ims.img_url"
                alt=""
              />
            </div>
            <div class="scorls">
              {{ ims.title }}
            </div>
          </div>
        </div>
        <div class="rgBton">
          <div class="tuis">推荐图书</div>
          <div
            @click="goToOtherDetailPage(ies.product_type, ies.id)"
            class="dans"
            v-for="ies in detailData.books"
            :key="ies.id"
          >
            <img
              style="width: 100%; height: 100%; border-radius: 6px"
              :src="ies.img_url"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../components/footer";
import ApiUrl from "../../js/apiUrl.js";
import comm from "../../js/comm.js";
export default {
  data() {
    return {
      liveId: "",
      detailData: [],
      lecturerList: [],
      product_type: 11,
    };
  },
  created() {
    document.title = "直播LIVE - 机工新阅读";
    this.liveId = this.$route.query.id || "";
    this.setupLiveDetail();
  },
  methods: {
    //Detail
    setupLiveDetail() {
      var that = this;
      var url = ApiUrl.webcast_detail_get;
      var param = { id: that.liveId };
      this.Ajax(url, param, (data) => {
        that.detailData = data;
        document.title = data.title + " - 直播LIVE - 机工新阅读";
        // data.lecturer_ids = [3];
        if (data.lecturer_ids && data.lecturer_ids.length > 0) {
          that.setupLeatureList(data.lecturer_ids);
        }
      });
    },
    //Leature List
    setupLeatureList(idArr) {
      var that = this;
      this.lecturerList = [];
      idArr.forEach((item) => {
        //网络请求
        this.AjaxTeacher(ApiUrl.getDetailById, { id: parseInt(item) }, function(
          res
        ) {
          // 修改视频图片数据
          var lecturer = {
            name: res.name || "",
            description: res.description || "",
            detail_image: res.detail_image || "",
            id: res.id || "",
          };
          that.lecturerList.push(lecturer);
        });
      });
    },
    //Page: AllBook
    goToAllBookPage(item) {
      localStorage.setItem("toPagePath", "livebook");
      var query = {
        type: this.detailData.product_type,
        level: item.level,
        id: item.id,
      };
      this.GoToAllBook(query);
    },
    //Page: AllBook
    goToAllBookPage2(item) {
      localStorage.setItem("toPagePath", "livebook");
      var query = {
        type: 11,
        type2Sort: 1,
      };
      this.GoToAllBook(query);
    },
    //Page: WebSide
    goToOutWebPage() {
      window.open(this.detailData.webcast_url, "_blank");
    },
    //Page: Other Detail
    goToOtherDetailPage(type, id) {
      if (type == 11) {
        this.liveId = id;
        this.setupLiveDetail();
      } else {
        this.GoToDetail(type, id);
      }
    },
    //Gusture Detail
    goToAutoBookDetail(item) {
      localStorage.setItem("toPagePath", "GuestDetail");
      var url = "GuestDetail";
      var query = {
        name: item.name,
        id: item.id,
      };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
  },

  components: {
    fooTer,
  },
};
</script>

<style lang="scss">
.livDtl {
  width: 100%;
  background: #f7f7f7;
  cursor: default;
  .mianb {
    width: 1157px;
    height: 71px;

    margin: 0 auto;
    display: flex;
    align-items: center;
    // background: #fff;
    .el-breadcrumb {
      font-size: 16px;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: #e6554d !important;
    }
  }
  .cents {
    width: 1157px;
    height: 360px;
    background: #fff;
    // border: 1px solid red;
    margin: 0 auto;
    display: flex;
    .mageL {
      width: 503px;
      height: 100%;

      margin-left: 40px;
      margin-right: 65px;
      .mageb {
        width: 503px;
        height: 300px;
        margin-top: 30px;
		overflow-y: hidden;
		border-radius: 8px;
      }
    }
    .centR {
      width: 500px;
      height: 100%;

      .tits {
        font-size: 24px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        letter-spacing: 1px;
        margin-bottom: 10px;
        margin-top: 59px;
      }
      .span1 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #cccccc;
        line-height: 23px;
        margin-bottom: 57px;
      }
      .moys {
        font-size: 33px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #e6564e;
        margin-bottom: 26px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #666666;
        margin-bottom: 14px;
      }
      .xlive {
        display: flex;
        justify-content: space-between;
        margin-bottom: 57px;
        .span2 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
        }
        img {
          width: 29px;
          height: 29px;
        }
      }
      .sfons {
        display: flex;
        justify-content: flex-start;
        .amn1 {
          display: inline-block;
          width: 156px;
          height: 48px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          font-size: 18px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #b0b0b0;
          line-height: 48px;
          text-align: center;
          margin-right: 21px;
        }
        .amn2 {
          display: inline-block;
          width: 156px;
          height: 48px;
          background: #e6564e;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          font-size: 18px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
        }
      }
      .sfons:hover {
        cursor: pointer;
      }
    }
  }
  .ksoal {
    width: 1157px;
    height: 221px;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-top: 22px;
    background: #fff;
    h5 {
      font-size: 24px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #2b2b2b;
      letter-spacing: 1px;

      margin: 25px 0 25px 75px;
    }
    h5:hover {
      cursor: default;
    }
    .thbox {
      width: 100%;

      display: flex;
      // justify-content: space-around;
      margin-left: 30px;
      .thors {
        height: 110px;
        width: 488px;
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
        .mlge {
          height: 100%;
          width: 110px;
        }
        .mlRgt {
          height: 100%;
          width: 266px;
          // border: 1px solid red;
          .titi {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            letter-spacing: 1px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          p {
            font-size: 18px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #cccccc;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-top: 10px;
          }
        }
        .iconts {
          height: 100%;
          width: 7px;
          // background: cornflowerblue;
          display: flex;
          align-items: center;
          img {
            width: 7px;
            height: 13px;
          }
        }
      }
      .thors:hover {
        cursor: pointer;
      }
    }
  }
  .cDtal {
    width: 1157px;
    // height: 1500px;

    // border: 1px solid red;
    margin: 0 auto;
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    .dTlft {
      width: 826px;
      // border: 1px solid blue;
      background: #fff;
      .tilm {
        font-size: 24px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #2b2b2b;
        margin-top: 32px;
        letter-spacing: 1px;
        margin-bottom: 26px;
        margin-left: 37px;
      }
      .tilm:hover {
        cursor: default;
      }
      .span1 {
        display: inline-block;
        padding: 0 20px;
        // width: 62px;
        height: 27px;
        background: #eeeeee;
        border-radius: 14px;
        margin-left: 37px;

        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        text-align: center;
      }
      .span1:hover {
        cursor: pointer;
      }

      .mgs {
        width: 744px;
        // height: 925px;
        // border: 1px solid;
        margin-top: 23px;
        margin-left: 41px;
        margin-bottom: 80px;
      }
    }
    .dTrgt {
      width: 315px;
      // border: 1px solid goldenrod;
      .rgTop {
        width: 100%;
        // height: 604px;
        background: #fff;
        overflow: hidden;
        margin-bottom: 19px;
        border: 1px solid #fff;
        .tols {
          font-size: 24px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #2b2b2b;
          display: inline-block;
          letter-spacing: 1px;
          margin-top: 30px;
          margin-left: 25px;
        }
        .tols:hover {
          cursor: default;
        }
        .cozm {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #999999;
          margin-top: 36px;
          letter-spacing: 1px;
          float: right;
          margin-right: 30px;
        }
        .cozm:hover {
          cursor: pointer;
        }
        .rtmgs {
          height: 236px;
          width: 265px;
          margin-bottom: 20px;
          margin-top: 17px;
          margin-left: 25px;
          .mgebox {
            width: 265px;
            height: 159px;
            // border: 1px solid blue;
            margin-bottom: 20px;
          }
          .scorls {
            font-size: 18px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #333333;

            letter-spacing: 1px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            // border: 1px solid red;
            // padding-bottom: 30px;
          }
          .scorls:hover {
            cursor: pointer;
          }
        }
        .rtmgs:hover {
          cursor: pointer;
					.scorls {
												color: #E6564E;
					}
        }
      }
      .rgBton {
        width: 100%;
        // height: 374px;
        border: 1px solid #fff;
        background: #fff;
        .tuis {
          font-size: 24px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #2b2b2b;
          margin: 26px 0 0 25px;
          letter-spacing: 1px;
        }
        .tuis:hover {
          cursor: default;
        }
        .dans {
          width: 130px;
          height: 173px;
          margin: 0 auto;
          margin-top: 16px;
          margin-bottom: 10px;
        }
        .dans:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
