import Vue from 'vue'
import VueRouter from 'vue-router'
import hzInx from '../components/hzIndex.vue'
import Homepage from '../views/Homepage/Homepage.vue' //推荐频道页
import Ebook from '../views/Ebook/E-book.vue'      //电子书
import Ebookdtl from '../views/Ebook/Ebook_dtail.vue'  //电子书详情页
import EbookReader from '../views/Ebook/Ebook_reader.vue'  //电子书阅读界面
import Aubook from '../views/Audiobook/Audiobook.vue'      //有声书
import Audtl from "../views/Audiobook/Audio_dtail"  //有声书详情
import Course from '../views/Course/Course.vue'      //课程
import Couraudio from '../views/Course/Couraudio.vue' //课程详情页
import VueAudio from '../views/Course/VueAudio.vue' //课程详情页

import Freshread from '../views/Freshread/Freshread.vue'      //鲜读
import Livebook from '../views/Livebook/Livebook.vue'      //直播LIVE
import LiveDt from "../views/Livebook/Livedtail.vue"     //直播详情页
import Teachers from '../views/Teacher/Teachers.vue'      //师资
import Subject from '../views/Subject/Subject.vue'      //专题
import SubjectDetail from '../views/Subject/SubjectDetail.vue' //专题详情备份
import Login from '../views/Login/Login.vue'   //登录
import Yunbook from '../views/Yunbook/Yunbook.vue' //云书房
import Refinance from '../views/Personnalcenter/refinance.vue' //续费VIP
import Filtrate from '../views/Homepage/Filtrate.vue'   //综合筛选页
import Seebook from '../components/Seebook.vue'   //阅读人数页
import Searchpage from '../components/Searchpage.vue'  //搜索页

import GuestCollection from '../views/Teacher/Guest/GuestCollection.vue'   //师资 - 
import GuestDetail from '../views/Teacher/Guest/GuestDetail.vue'   //师资 - 
import GuestList from '../views/Teacher/Guest/GuestList.vue'   //师资 - 
import Invite from '../views/Teacher/Invite/Invite.vue'   //师资 - 
import InviteResult from '../views/Teacher/Invite/InviteResult.vue'   //师资 - 
import TeacherSearchpage from '../views/Teacher/SearchPage/TeacherSearchPage.vue'   //师资 - 
// import { component } from 'vue/types/umd'
import Abouts from '../components/Help/Abouts.vue'  //关于我们
import Agreem from '../components/Help/Agreem.vue'   //用户协议
import Helpcenter from '../components/Help/Helpcenter.vue' //帮助中心
// import Ceshi from '../components/ceshi.vue'  //测试页面

import Teachers2 from '../views/TeacherNew/Teachers.vue' //新师资首页
import Teachers2Detail from '../views/TeacherNew/TeachersDetail.vue' //新师资详情
import Course2Detail from '../views/TeacherNew/CourseDetail.vue' //新师资详情


Vue.use(VueRouter)

const routes = [
  
  {
    path:'/login',
    component:Login,
    
  },
  {
    path:'/ebook',
    component:Ebook
  },
  {
    path:'/aubook',
    component:Aubook
  },
  {
    path:'/course',
    component:Course
  },
  {
    path:'/searchpage',
    component:Searchpage
  },
  {
    path:'/filtrate',
    name: 'filtrate',
    component:Filtrate,
    // component: resolve => import('../views/Homepage/Filtrate'),
    // // meta:{
    // //   title: '游戏说明'
    // // }
  } ,
  {
    path:'/livebook',
    component:Livebook,
    
   },
   {
    path:'/teachers',
    component:Teachers
  },
  {
    path:'/teachers2',
    component:Teachers2
  },

  {
    path:'/subject',
    component:Subject
  },
  // {
  //   path:'/ceshi',
  //   component: Ceshi
  // },
  {
    path:'/ebookrdr',
    component: EbookReader
  },
  {
    path:'/vueaudio',
    component: VueAudio
  },
  {
    path: '/',
    redirect:'/homepage',
    component:hzInx,
   children:[
     {
        path:'homepage',
        component:Homepage ,
        meta:{
          // 页面标题title
          title: '首页'
        ,} 
      },
  {
    path:'/agreem',
    component:Agreem
  },
  {
    path:'/helpcenter',
    component:Helpcenter
  },
  {
    path:'/abouts',
    component:Abouts
  },
      {
        path:'ebookdtl',
        component: Ebookdtl
      },
	  {
	    path:'/teachers2detail',
	    component:Teachers2Detail
	  },
	  {
	    path:'/course2detail',
	    component:Course2Detail
	  },
      {
        path:'audtl',
        component:Audtl
      },
     
      {
        path:'subjectDetail',
        component:SubjectDetail
      },
      {
        path:'couraudio',
        component:Couraudio
      },
      {
        path:'freshread',
        component:Freshread
      },
     
      {
        path:'livedtl',
        component:LiveDt
      },
    
      {
        path:'yunbook',
        component:Yunbook
      },
    
      {
        path:'seebook',
        component:Seebook
      },
      
      {
        path:'TeacherSearchpage',
        component:TeacherSearchpage
      },
      {
        path:'GuestCollection',
        component:GuestCollection
      },
      {
        path:'GuestDetail',
        component:GuestDetail
      },
      {
      path:'/GuestList',
      component:GuestList
    },
      {
        path:'Invite',
        component:Invite
      },
      {
        path:'InviteResult',
        component:InviteResult
      },
      
    ]
  },
  //   {
  //   path:'/GuestList',
  //   component:GuestList
  // },
  //     {
  //       path:'/GuestCollection',
  //       component:GuestCollection
  //     },
  //     {
  //       path:'/GuestDetail',
  //       component:GuestDetail
  //     },
  //     {
  //       path:'/Invite',
  //       component:Invite
  //     },
  //     {
  //       path:'/InviteResult',
  //       component:InviteResult
  //     },
  //     {
  //       path:'/SearchPage',
  //       component:SearchPage
  //     },




  // {
  //   path:'/GuestDetail',
  //   component:GuestDetail
  // },

 
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router