import { render, staticRenderFns } from "./GuestList.vue?vue&type=template&id=745d079d&scoped=true&"
import script from "./GuestList.vue?vue&type=script&lang=js&"
export * from "./GuestList.vue?vue&type=script&lang=js&"
import style0 from "./GuestList.vue?vue&type=style&index=0&id=745d079d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "745d079d",
  null
  
)

export default component.exports