<template>
  <div class="maxBox">
    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
          <div class="logo" @click="GoToChangeTab(0)" style="cursor: pointer;">
            <img
              style="width:100%;height:100%"
              src="../../assets/image/logo.png"
              alt=""
            />
          </div>
          <input class="inputs" type="search" placeholder="搜 索" />
        </div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
            @click="handellet"
          />
        </div>
      </div>
      <!-- tab栏 -->

      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">
          一直会飞哈哈哈哈哈哈哈哈哈哈
        </div>
        <div class="times">
          2021.08.10 到期
        </div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>
    </div>
    <!-- content -->
    <div class="mianb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
        <el-breadcrumb-item><a href="/">续费vip</a></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="vipsmoy">
      <div class="montol">
        <div class="monimg " style="display: inline-block;">
          <img
            style="width:100%;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div>
        <div class="monbs">
          <div class="mon1">一直会飞哈哈哈哈</div>
          <img class="mg1" src="../../assets/image/vip.png" alt="" />
          <img class="mg2" src="../../assets/image/svip.png" alt="" />
          <div class="mon2">VIP 2021-08-10 到期，购买后有效期顺延</div>
        </div>
      </div>
      <div class="costing">
        <div class="box1">
          <div class="h5">
            VIP体验
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
        <div class="box1">
          <div class="h5">
            VIP体验
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
        <div class="box1">
          <div class="h5">
            VIP体验
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
        <div class="box1">
          <div class="h5">
            VIP两年卡
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
        <div class="box1">
          <div class="h5">
            VIP两年卡
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
        <div class="box1">
          <div class="h5">
            VIP两年卡
          </div>
          <p class="p1">原价¥60元</p>
          <div class="money"><span>¥</span>45</div>
          <p class="p2">低至¥45元/月</p>
          <div class="poset">
            <span>赠月卡+友情月卡+书</span>
          </div>
          <img src="../../assets/image/hot.png" class="hotimg" alt="" />
        </div>
      </div>
      <div class="phsol">
        <div class="p3">* 买年卡送纸书：最新畅销纸书 · 赠书挑选</div>
        <div class="p4">
          * 点击问号，已帮您解答关于VIP的大部分问题 →
          <img
            src="../../assets/image/wenhao.png"
            style="height:25.5px;width:25.5px"
            alt=""
          />
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../components/footer";
export default {
  data() {
    return {
      num: 0,
    };
  },
  components: {
    fooTer,
  },
  methods: {
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.maxBox {
  width: 100%;
  min-width: 1157px;
  background: #f7f7f7;
  cursor: default;
  //   border: 1px solid red;
  .topNav {
    width: 100%;
    height: 173px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    .hovers {
      width: 221px;
      height: 506px;
      border: 1px solid;
      z-index: 20;
      position: absolute;
      right: 300px;
      top: 60px;
      background: #fff;

      display: none;
      .vipmoy {
        width: 180px;
        height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 14px;
        .viplo {
          width: 34px;
          height: 15px;
        }
        .spanv {
          width: 65px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #e6544c;
        }
      }
      .names {
        width: 171px;
        height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 8px;
      }
      .times {
        width: 123px;
        height: 20px;
        margin-left: 22px;
        margin-top: 9px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #999999;
      }
      .fenge {
        width: 179px;
        height: 1px;
        background: #eeeeee;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .fosnam {
        width: 179px;
        height: 100px;
        // border: 1px solid red;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;

        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .fonbot {
        width: 179px;
        height: 190px;
        // border: 1px solid;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;
        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .nect {
        width: 179px;
        height: 57px;
        margin: 0 auto;

        line-height: 57px;

        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .logipt {
      width: 1157px;
      height: 60px;

      margin: 0 auto;
      margin-top: 27px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoBox {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 360px;
          height: 100%;
        }
        .inputs {
          width: 140px;
          height: 50px;
          background: #f7f7f7;
          border-radius: 25px;
          margin-left: 30px;
          text-align: center;
          font-size: 20px;
        }
      }
      .logins {
        width: 95px;
        height: 29px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e6564e;
      }
      .users {
        width: 210px;
        height: 47px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #e6564e;
          line-height: 25px;
          width: 130px;
          height: 25px;
          // border: 1px solid red;
          border-right: 3px solid #d8d8d8;
        }
      }
    }
    .tablistBg {
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topNavShow {
      position: fixed !important;
      z-index: 99 !important;
      top: 0 !important;
      // left: 0 !important;
      // left: calc((100% - 1157px) / 2) !important;
      background: white;
      margin-top: 0 !important;
      // width: calc(100% - 15px);
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tablist {
      height: 70px;
      width: 1157px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bdbdbd;
      position: relative;
      margin-top: 0px !important;
      .tabActive {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e6564e !important;
        padding-bottom: 5px;
        // border: 1px solid red;
        text-align: center;

        display: flex;
        justify-content: center;
      }
      .tabActive2 {
        margin-left: 0;
      }
      .unTabActive2 {
        margin-left: -6px;
      }
      .tabActive::before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        // border-bottom: 4px solid #e6564e;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
    }
  }
  .mianb {
    width: 1157px;
    height: 75px;
    margin: 0 auto;
    // border: 1px solid red;

    display: flex;
    align-items: center;
    // .el-breadcrumb {
    //   font-size: 18px;
    // }
    .el-breadcrumb {
      font-size: 16px;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: #e6554d !important;
    }
  }
  .vipsmoy {
    width: 1157px;

    margin: 0 auto;
    border: 1px solid red;
    background: #fff;
    margin-bottom: 143px;
    .montol {
      width: 969px;
      height: 50px;
      //   border: 1px solid red;
      margin-left: 62px;
      margin-top: 38px;
      display: flex;
      align-items: center;
      .monimg {
        height: 48px;
        width: 48px;
      }
      .monbs {
        margin-left: 5px;
        .mon1 {
          width: 153px;
          font-size: 18px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
        .mg1 {
          //   border: 1px solid red;
          width: 36px;
          height: 17px;
        }
        .mg2 {
          //   border: 1px solid red;
          width: 36px;
          height: 17px;
          margin-left: 7px;
        }
        .mon2 {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    .costing {
      width: 970px;

      margin: 0 auto;
      margin-top: 44px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .box1 {
        width: 162px;
        height: 191px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin-bottom: 30px;
        position: relative;
        // border: 1px solid blue;
        .hotimg {
          width: 24px;
          height: 13px;
          position: absolute;
          right: 10px;
          top: 40px;
        }
        .poset {
          // width: 80px;
          display: inline-block;
          height: 30px;
          font-size: 15px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
          background: linear-gradient(180deg, #ff6b5d 0%, #e94b42 100%);
          border-radius: 12px 12px 12px 0px;
          text-align: center;
          line-height: 30px;
          position: absolute;
          top: -15px;
          left: -1px;
          span {
            margin: 0 5px;
          }
        }
        .h5 {
          height: 29px;

          // display: inline-block;
          margin: 0 auto !important;
          margin-top: 38px !important;
          text-align: center;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .p1 {
          font-size: 15px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #bebebe;
          margin-bottom: 15px;
          margin-left: 45px;
          text-decoration: line-through;
        }
        .money {
          font-size: 21px;
          font-family: SFUDINMitAlt;
          color: #e6564e;
          margin-bottom: 15px;
          margin-left: 57px;
          span {
            font-size: 12px;
          }
        }
        .p2 {
          font-size: 15px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #999999;
          margin-left: 41px;
        }
      }
    }
    .phsol {
      height: 64px;
      width: 500px;

      margin-left: 63px;
      .p3 {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #eb4d43;
        margin-bottom: 14px;
        text-decoration: underline;
      }
      .p4 {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #999999;
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
