<template>
  <div id="iframeBg">
    <div class="allBox allBox_pc">
      <div class="contentPC">
        <div class="navPC">
          <div class="nav">
            <ul>
              <li>
                <span class="pointer" @click="event_navToPage_pc(1, '')"
                  >机工新阅读</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer" @click="event_navToPage_pc(2, '')">师资</span>
              </li>
              <li v-if="navClassTypeName != ''">
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li v-if="navClassTypeName != ''">
                <span class="pointer" @click="event_navToPage_pc(2, '')">{{
                  navClassTypeName
                }}</span>
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer color">{{ userInfo.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="contentBox">
          <div class="contentLeft">
            <div class="personMessage">
              <div class="headphoto">
                <!-- userInfo.image -->
                <el-image class :src="userInfo.list_image">
                  <div slot="placeholder" class>
                    <img class src="../../../assets/img/avatar_place.png" alt />
                  </div>
                  <div slot="error" class>
                    <img class src="../../../assets/img/avatar_place.png" alt />
                  </div>
                </el-image>
                <!-- <img :src="userInfo.image || require('../../../assets/img/bgwall_place.png')" alt /> -->
              </div>
              <h2 class="name">{{ userInfo.name }}</h2>
              <div class="personMes">
                <p v-for="(item, i) in userInfo.titleList" :key="i">
                  {{ item }}
                </p>
              </div>
              <div class="personlabel">
                <span
                  class="labels"
                  v-for="(item, i) in userInfo.tagList"
                  :key="i"
                  >{{ item }}</span
                >
              </div>
              <div class="site">
                <img src="../../../assets/img/dizhi.png" alt />
                <span>{{ userInfo.habitation }}</span>
              </div>
              <div class="invitation">
                <div class="inviteBox" @click="to_inviteType(20)">
                  <span>聊一聊</span>
                </div>
                <!-- <div class="inviteBox inviteBoxAC" @click="to_inviteType(10)">
                  <span>邀请参加活动</span>
                </div>-->
              </div>
              <div
                style="display: none"
                class="enshrine fix_collection"
                v-if="userInfo.isFavorite == 0"
                @click="event_collectClick()"
              >
                <img src="../../../assets/img/collect_1.png" alt />
                <span>收藏</span>
              </div>
              <div
                style="display: none"
                class="enshrine fix_collection"
                v-if="userInfo.isFavorite != 0"
                @click="event_collectClick()"
              >
                <img src="../../../assets/img/icom8.png" alt />
                <span>已收藏</span>
              </div>
            </div>
          </div>
          <!-- 视频播放弹框 -->
          <div class="vidoePop" v-if="videoShow">
            <div class="vidoeBG" @click="noPop"></div>
            <div class="iframeBox">
              <img @click="noPop" src="../../../assets/img/closexd.png" alt />
              <!-- <iframe
                style="height:691px;width:1157px"
                scrolling="no"
                allowfullscreen="true"
                :src="videoUrls"
                frameborder="0"
              ></iframe>-->
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
              ></video-player>
            </div>
          </div>
          <div class="contentrigth">
            <div class="personMessageRight">
              <div
                class="fix_boderbottom"
                v-if="
                  (userInfo.videoList && userInfo.videoList.length > 0) ||
                    (userInfo.picList && userInfo.picList.length > 0)
                "
              >
                <p class="title">嘉宾风采</p>
                <div class="video">
                  <div
                    v-if="userInfo.videoList && userInfo.videoList.length > 0"
                    class="videoBox"
                    v-for="(item, i) in userInfo.videoList"
                    @click="event_videoClick(item)"
                    :key="i"
                    ref="enterBox"
                  >
                    <img :src="item.imageUrl" alt />
                    <img
                      class="playerIcon"
                      src="../../../assets/img/player.png"
                      alt
                    />
                    <!-- <iframe
                      :style="{width:boxWidth+'px',height:boxHeigt +'px'}"
                      scrolling="no"
                      allowfullscreen="true"
                      :src="item.url +'&width=' +boxWidth +'&height=' + boxHeigt"
                      frameborder="0"
                    ></iframe>-->
                    <div class="bgBox" @click="offPop(item)"></div>
                  </div>

                  <div
                    v-if="userInfo.picList && userInfo.picList.length > 0"
                    class="videoBox"
                    v-for="item in userInfo.picList"
                  >
                    <img :src="item.url" alt />
                  </div>

                  <!-- <img src="../../../assets/img/videoimg.png" alt />-->
                </div>
              </div>
              <div
                class="fix_boderbottom"
                v-if="userInfo.classList && userInfo.classList.length > 0"
              >
                <p class="title titlerigth">专研课程</p>
                <div class="curriculum">
                  <div
                    class="text"
                    v-for="(item, i) in userInfo.classList"
                    :key="i"
                  >
                    <p class="curriculumTitle">{{ item.title }}</p>
                    <span
                      class="curriculumtext"
                      ref="element_pc"
                      :class="
                        ac.indexOf(i) >= 0
                          ? 'describes_pc'
                          : item.isShow
                          ? 'describes_2_pc'
                          : 'describes_pc'
                      "
                      >{{ item.content }}</span
                    >
                    <!-- 注释展开收起 -->
                    <!-- <div
                      class="unfold"
                      v-show="item.isShow"
                      @click="unfolds(item,i)"
                    >
                      <span>{{ac.indexOf(i)>=0?"收起":"展开"}}</span>
                      <img
                        :class="{downImg:ac.indexOf(i)>=0}"
                        src="../../../assets/img/down_Icon.png"
                        alt
                      />
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                class="fix_boderbottom"
                v-if="userInfo.invitedList && userInfo.invitedList.length > 0"
              >
                <p class="title titlerigth">受邀活动</p>
                <div class="be_asked">
                  <p v-for="(item, i) in userInfo.invitedList" :key="i">
                    {{ item }}
                  </p>
                </div>
              </div>
              <div class="fix_boderbottom">
                <p class="title titlerigth">更多介绍</p>
                <div class="be_askeds">
                  <p
                    class="enterprise"
                    ref="elementBottom_pc"
                    :class="
                      isShowMoreItem_bottom
                        ? ismore
                          ? 'enterprises_pc'
                          : 'enterprises_2_pc'
                        : 'enterprises_pc'
                    "
                  >
                    {{ userInfo.description || "" }}
                  </p>
                  <!-- 注释展开收起 -->
                  <!-- <div class="expansion" v-if="isShowMoreItem_bottom" >
                    <span @click="unfold">{{ismore ?'收起':"展开"}}全部</span>
                    <img
                      @click="unfold"
                      :class="ismore?'downImg':''"
                      src="../../../assets/img/down_Icon.png"
                      alt
                    />
                  </div> -->
                </div>
              </div>
              <div
                class="fix_boderbottom"
                v-if="userInfo.serviceFirm && userInfo.serviceFirm != ''"
              >
                <p class="title titlerigth">服务企业</p>
                <div class="service">
                  <p>{{ userInfo.serviceFirm }}</p>
                </div>
              </div>
              <div v-if="userInfo.bookList && userInfo.bookList.length > 0">
                <p class="title titlerigth">代表著作</p>
                <div class="workList">
                  <div
                    class="wordBox"
                    v-for="(item, i) in userInfo.bookList"
                    :key="i"
                  >
                    <img :src="item.bookImage" alt />
                    <span>{{ item.bookName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import elementResizeDetectorMaker from "element-resize-detector";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import Vue from "vue";
export default {
  components: {
    fooTer,
  },
  data() {
    return {
      isShowMoreItem_bottom: true,
      userInfo: {},
      navTitle: "123",
      navClassTypeName: "",
      lecturerId: "",
      userId: "",
      ac: [],
      ac1: 0,
      rightNavShow: "",
      ismore: true,
      isVideo: false,
      navLevel: 3,
      boxWidth: "",
      boxHeigt: "",
      videoUrl: "",
      videoUrls: "",
      videoShow: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", //url地址
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },

  methods: {
    // PC
    offPop(item) {
      this.event_videoClick(item);
      this.videoShow = true;
    },
    noPop() {
      this.videoShow = false;
      this.stopVideo();
    },
    // event_navToPage_pc(type, info) {
    //   // var navIndex = type - this.navLevel;
    //   if (type == 1) {
    //     //机工新阅读
    //     //测试代码:
    //     // navIndex += 1;
    //     // this.$router.go(navIndex);
    //     this.$router.push({
    //       path: "teachers",
    //       query: {},
    //     });
    //   } else if (type == 2) {
    //     //师资   / 热门
    //     // this.$router.go(navIndex);
    //     this.$router.push({
    //       path: "GuestList",
    //       query: {},
    //     });
    //   } else if (type == 3) {
    //     //讲师信息
    //     // this.$router.go(navIndex);
    //     this.$router.push({
    //       path: "GuestDetail",
    //       query: {
    //         id: info || "",
    //       },
    //     });
    //   }
    // },
    event_navToPage_pc(type, info) {
      if (type == 1) {
        //机工新阅读
        //测试代码:
        this.$router.push({
          path: "teachers",
          query: {},
        });
      } else if (type == 2) {
        //师资   / 热门
        this.$router.push({
          path: "GuestList",
          query: {},
        });
      } else if (type == 3) {
        //讲师信息
        this.$router.push({
          path: "GuestDetail",
          query: {
            id: info || "",
          },
        });
      }
    },

    setupAjaxData() {
      this.getDetailById();
    },
    // 展开
    unfold() {
      this.ismore = !this.ismore;
    },
    unfolds(val, i) {
      if (this.ac && this.ac.length > 0) {
        var index = this.ac.indexOf(i);
        if (index >= 0) {
          this.ac.splice(index, 1);
        } else {
          this.ac.push(i);
        }
      } else {
        this.ac.push(i);
      }
    },
    getDetailById() {
      var that = this;
      var data = {
        id: parseInt(this.lecturerId),
      };

      if (this.userId != "") data["userId"] = this.userId;
      this.AjaxTeacher(ApiUrl.getDetailById, data, function(res) {
        // 修改视频图片数据
        var remainShowCount = 3;
        if (res.videoList && res.videoList.length > 0) {
          if (res.videoList.length > 3) res.videoList.splice(3);
          remainShowCount = 3 - res.videoList.length;
        }
        //测试
        if (res.picList && res.picList.length > 0 && remainShowCount > 0) {
          if (res.picList.length > remainShowCount)
            res.picList.splice(remainShowCount);
        }

        that.userInfo = res;
        document.title = res.name + " - 师资 - 机工新阅读";

        if (that.rightNavShow) {
          that.setClassItemHight_PC();
        } else {
          that.setClassItemHight_Phone();
        }
      });
    },
    setClassItemHight_PC() {
      var that = this;
      setTimeout(() => {
        if (that.userInfo.classList && that.userInfo.classList.length > 0) {
          let ems = that.$refs.element_pc;
          if (ems && ems.length > 0) {
            for (var i = 0; i < ems.length; i++) {
              var item = ems[i];
              that.userInfo.classList[i].isShow = true;
            }
          }
        }

        var description = that.userInfo.description || "";
        let em2 = that.$refs.elementBottom_pc;
        that.isShowMoreItem_bottom = true;

        if (that.userInfo.videoList.length > 0) {
          that.boxWidth = 335;
          that.boxHeigt = 200;
        }
      }, 200);
    },
    setClassItemHight_Phone() {
      var that = this;

      setTimeout(() => {
        if (that.userInfo.classList && that.userInfo.classList.length > 0) {
          let ems = that.$refs.element;
          if (ems && ems.length > 0) {
            for (var i = 0; i < ems.length; i++) {
              var item = ems[i];
              that.userInfo.classList[i].isShow =
                item.clientHeight > 42 ? true : false;
            }
          }
        }

        var description = that.userInfo.description || "";
        let em2 = that.$refs.elementBottom;
        // that.isShowMoreItem_bottom = em2.clientHeight > 190;
        // if (that.isShowMoreItem_bottom) that.ismore = false;
        that.isShowMoreItem_bottom = true;

        if (that.userInfo.videoList.length > 0) {
          var em3 = that.$refs.enterBox[0];
          that.boxWidth = em3.offsetWidth;
          that.boxHeigt = em3.offsetHeight;
        }
      }, 200);
    },
    to_inviteType(type) {
      if (!this.userId) {
        this.$alert("请登录账户");
        return;
      }
      var json = JSON.stringify(this.userInfo);
      localStorage.setItem("lecturer_userInfo", json);
      var id = this.lecturerId;

      var url = "Invite";
      var query = {
        id: id,
        type: type,
        navClassTypeName: this.navClassTypeName || "",
      };
      let routeUrl = this.$router.resolve({
        path: url,
        query: query,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: url,
      //   query: query,
      // });
    },
    // 点击打开视频
    event_videoClick(item) {
      this.isVideo = true;
      this.playerOptions.sources[0].src = item.videoUrl;
      this.playerOptions.autoplay = true;
    },
    // 关闭视频
    stopVideo() {
      this.isVideo = false;
      this.playerOptions.autoplay = false;
    },
    event_collectClick() {
      var that = this;
      if (!this.userId) {
        this.$alert("请登录账户");
        return;
      }
      var data = {
        userId: this.userId,
        lecturerId: this.lecturerId,
      };

      var url =
        this.userInfo.isFavorite == 1 ? ApiUrl.delFavorite : ApiUrl.addFavorite;

      this.AjaxTeacher(url, data, function(res) {
        console.log(res);
        let state = that.userInfo.isFavorite;
        if (state == 0) {
          res.message = "收藏成功";
        } else {
          res.message = "取消收藏";
        }
        if (res.code == 0) {
          that.userInfo.isFavorite = that.userInfo.isFavorite == 1 ? 0 : 1;
        }

        that.$alert(res.message);
        localStorage.setItem("needUpdata_Collection", "1");
        localStorage.setItem("needUpdata_List", "1");
      });
    },
  },

  watch: {
    userInfo(val) {
      const that = this;
      if (this.rightNavShow) {
        const erd = elementResizeDetectorMaker();
        var app = document.getElementById("iframeBg");
        erd.listenTo(app, (element) => {
          that.$nextTick(() => {
            var iHeight = app.offsetHeight + 11;
            iHeight = iHeight > 500 ? iHeight : 500;
            parent.window.postMessage(
              { msgType: 1, msgData: { iHeight: iHeight } },
              "*"
            );
          });
        });
      }
    },
  },

  mounted() {
    this.rightNavShow = true;
  },
  created() {
    document.title = "师资 - 机工新阅读";
    this.rightNavShow = false; //测试代码
	localStorage.setItem("userId","9999");
    this.userId = localStorage.getItem("userId");
    this.navTitle = this.$route.query.name || "";
    this.lecturerId = this.$route.query.id || "";
    this.navClassTypeName = this.$route.query.navClassTypeName || "";
    if (this.lecturerId == "") {
      return;
    }
    this.setupAjaxData();
  },
};
</script>

<style lang="scss" scoped>
.iframe {
  width: 220px;
  height: 120px;
  overflow: hidden;
  cursor: default;
}
/*PC */
.vidoePop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  .vidoeBG {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .iframeBox {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    img {
      width: 50px;
      height: 50px;
      margin-top: 150px;
      margin-right: -40px;
      cursor: pointer;
    }
    .video-player {
      width: 691px;
      height: 1157px;
      margin-top: 30px;
    }
  }
}
.contentPC {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  // padding-top: 11px;
  .navPC {
    width: 100%;
    height: 75px;
    background-color: #F4F4F4;
    .nav {
      width: 100%;
      height: 100%;
      max-width: 1157px;
      margin: 0 auto;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        li {
          height: 100%;
          // margin-right: 16px;
          display: flex;
          align-items: center;
          color: #363636;
          font-size: 16px;
          font-weight: 600;
          // font-weight: bold;
          .top {
            margin-top: 4px;
          }
        .pointer {
          cursor: pointer;
            font-weight: 700;
            color: #303133;
        }
          .pointer:hover{ 
            color:#429efd;
          }
          .color {
            color: #e6554d;
            font-weight: 400;
          }
          .color:hover{ 
            color:#e6564e;
            cursor: auto;
          }
        }
        li:hover {
          color: #2d8cf0;
        }
      }
    }
  }
  .contentBox {
    width: 100%;
    height: 100%;
    max-width: 1157px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    padding-bottom: 2rem;
    cursor: default;
    .contentLeft {
      width: 30%;
      height: 100%;
      .personMessage {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.41rem;
        .headphoto {
          width: 1.3rem;
          height: 1.3rem;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .name {
          color: #2b2b2b;
          font-size: 26px;
          margin-top: 13px;
        }
        .personMes {
          margin-top: 15px;
          p {
            font-size: 14px;
            color: #666666;
            text-align: center;
            padding: 0 10px;
            line-height: 1.7;
          }
        }
        .personlabel {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          span {
            padding: 3px 12px;
            //background-color: #e0f3f7;
            //color: #52c4c6;
			background-color: #fff4e7;
			color: #ef8608;
            font-size: 14px;
            margin-right: 5px;
            border-radius: 5px;
          }
        }
        .site {
          margin: 13px 0;
          color: #bababa;
          font-size: 14px;
          display: flex;
          align-items: center;
          img {
            width: 11px;
            height: 14px;
            object-fit: contain;
            margin-right: 7px;
          }
        }
        .invitation {
          .inviteBox {
            width: 217px;
            height: 54px;
            cursor: pointer;
            background: #e45754;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
            }
          }
          .inviteBoxAC {
            margin-top: 12px;
            background: linear-gradient(47deg, #ffb16d 0%, #eb6c36 100%);
          }
        }
        .enshrine {
          display: flex;
          width: 2.17rem;
          align-items: center;
          margin-top: 12px;
          padding-bottom: 20px;
          img {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 10px;
            cursor: pointer;
          }
          span {
            color: #287952;
            font-size: 12px;
          }
        }
        .fix_collection {
          // padding-left: 90px;
        }
      }
    }
    .contentrigth {
      width: 60%;
      height: 100%;
      .personMessageRight {
        .workList {
          // overflow: auto;
          widows: 100%;
          display: flex;
          flex-wrap: wrap;
          .wordBox {
            width: 144px;
            display: flex;
            flex-direction: column;
            margin-right: 28px;
            margin-top: 18px;
            cursor: pointer;
            img {
              width: 100%;
              height: 188px;
              box-shadow: 1px 1px 8px #bbbbbb;
            }
            span {
              margin: 17px 0;
              color: #666;
              font-size: 16px;
              line-height: 1.8;
              letter-spacing: 1px;
              text-align: left;
              display: -webkit-box;
              overflow: hidden;
              // white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
        .workList::-webkit-scrollbar {
          // display: none;
        }
        .service {
          color: #666;
          margin-top: 8px;
          font-size: 16px;
        }
        .be_asked {
          p {
            color: #666;
            // color: #4c88dc;
            font-size: 16px;
            // text-decoration: underline;
            margin: 5px 0;
            cursor: pointer;
            line-height: 1.8;
            letter-spacing: 1px;
          }
          .askedAC {
            color: #999999;
            text-decoration: none;
          }
        }
        .be_askeds {
          margin-top: 8px;
          p {
            color: #666;
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            // -webkit-line-clamp: 9;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.8;
            letter-spacing: 1px;
            text-align: justify;
          }

          .enterprise {
            word-break: break-all;
            white-space: pre-wrap;
          }
          .enterprises {
            -webkit-line-clamp: inherit;
          }
          .enterprises_2 {
            -webkit-line-clamp: 9; /** 显示的行数 **/
          }
          .enterprises_pc {
            -webkit-line-clamp: inherit;
            margin-bottom: 10px;
          }
          .enterprises_2_pc {
            -webkit-line-clamp: 5; /** 显示的行数 **/
          }

          .expansion {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #4c88dc;
            cursor: pointer;
            margin-top: 10px;
            img {
              width: 16px;
              height: 8px;
              margin-left: 8px;
              object-fit: contain;
              cursor: pointer;
            }
            .downImg {
              transform: rotate(180deg);
            }
          }
        }
        .title {
          color: #2b2b2b;
          font-size: 20px;
          font-weight: 700;
          display: flex;
          align-items: center;
          padding: 28px 0;
          margin: 0;
          letter-spacing: 1px;
          line-height: 1px;
        }
        .titlerigth {
          padding-bottom: 10px;
        }
        .title::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 20px;
          background-color: #e45754;
          margin-right: 12px;
        }
        .video {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          .videoBox {
            width: 214px;
            height: 126px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .bgBox {
              position: absolute;
              width: 100%;
              height: 100%;

              z-index: 11;
              cursor: pointer;
            }
            .playerIcon {
              position: absolute;
              width: 50px;
              height: 50px;
              left: 0px;
              bottom: 0px;
            }
          }
        }
        .video::after {
          content: "";
          display: block;
          width: 2.2rem;
        }
        .curriculum {
          .text {
            position: relative;
            margin-top: 10px;
            .curriculumTitle {
              color: #2b2b2b;
              font-size: 16px;
              display: flex;
              align-items: center;
              font-weight: bold;
              margin-bottom: 10px;
            }
            // .curriculumTitle::before {
            //   content: "";
            //   width: 10px;
            //   height: 10px;
            //   border-radius: 50%;
            //   background-color: #4c88dc;
            //   margin-right: 10px;
            // }
            .curriculumtext {
              color: #666;
              font-size: 16px;
              // padding-left: 22px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: inherit;
              -webkit-box-orient: vertical;
              white-space: pre-wrap;
              padding-bottom: 15px;
              line-height: 1.8;
              letter-spacing: 1px;
            }
            .describes_pc {
              -webkit-line-clamp: inherit;
            }
            .describes_2_pc {
              -webkit-line-clamp: 2;
            }
            .unfold {
              position: absolute;
              bottom: 0;
              right: 0;
              color: #4c88dc;
              font-size: 12px;
              cursor: pointer;
              width: 63px;
              background: white;
              height: 23px;
              img {
                width: 16px;
                height: 8px;
                object-fit: contain;
              }
              .downImg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}
/*手机*/
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}
.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}
.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}
.Guests_Mes {
  margin-top: 50px;
  width: 100%;
  border-bottom: 8px solid #f9f9f9;
  padding-bottom: 0.8rem;
  .banner {
    position: relative;
    .banerBox {
      height: 23px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url(../../../assets/img/border.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .img {
    display: block;
    height: 11.2rem;
    width: 100%;
    object-fit: contain;
  }
  .detail {
    margin-top: 0.5rem;
    position: relative;
    .detail_name {
      display: flex;
      justify-content: center;
      position: relative;
      font-size: 24px;
      color: #2b2b2b;
      font-weight: bold;
      .detail_state {
        position: absolute;
        right: 16px;
        color: #fe8608;
      }
    }
    .person_person {
      width: 71%;
      text-align: center;
      margin: 0 auto;
      color: #666666;
      font-size: 12px;
      margin-top: 0.5rem;
      line-height: 1.8;
    }
    .label {
      width: 82%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0.5rem;
      // height: 22px;
      overflow: hidden;
      .labels {
        background-color: #e0f3f7;
		color: #52c4c6;
        font-size: 12px;
        line-height: 1;
        padding: 5px 7px;
        margin-right: 5px;
        border-radius: 2px;
      }
      .location {
        position: absolute;
        right: 16px;
        display: flex;
        img {
          width: 12px;
          height: 20px;

          object-fit: contain;
        }
        span {
          font-size: 14px;
          color: #bababa;
          margin-left: 8px;
        }
      }
    }
  }
}
.title {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 15px;
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.title::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 16px;
  background-color: #e45754;
  margin-right: 6px;
}
.contents {
  // padding-bottom: 82px;
  padding-bottom: 100px;
  .contentPerson {
    .footerBox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      box-shadow: -2px -6px 48px 10px rgb(255, 255, 255);
      position: relative;
      z-index: 44;
      color: #4c88dc;
      font-size: 12px;
      img {
        width: 12px;
        height: 7px;
        margin-left: 3px;
      }
    }
    .curriculum {
      .activityList {
        width: 92%;

        overflow: hidden;
        margin: 0 auto;
        // padding-left: 15px;
        ul {
          width: 100%;
          display: -webkit-box;
          white-space: nowrap;
          overflow: auto;

          li {
            width: 4.61rem;
            margin-right: 24px;
            img {
              height: 6.15rem;
              width: 100%;
              box-shadow: 1px 1px 8px #bbbbbb;
              // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
            }
            p {
              color: #666;
              font-size: 12px;
              height: 32px;
              max-height: 32px;
              margin-top: 5px;
              overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // // display: flex;
              // -webkit-line-clamp: 2;
              // /*autoprefixer: off */
              // -webkit-box-orient: vertical;
              // /*autoprefixer: on */

              // white-space: normal;
            }
          }
        }
        // ul::after{
        //   content: '';
        //   display: block;
        //   width: 15px;

        // }
        // ul::before{
        //   content: '';
        //   display: block;
        //   width: 15px;

        // }
        ul :last-child {
          margin-right: 0;
        }
        ul::-webkit-scrollbar {
          display: none;
        }
        .enterprise {
          color: #666;
          font-size: 14px;
          display: -webkit-box;
          word-break: break-all;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.7;
          text-align: justify;
          white-space: pre-wrap;
        }
        .enterprises {
          -webkit-line-clamp: inherit;
        }
        .enterprises_2 {
          -webkit-line-clamp: 9; /** 显示的行数 **/
        }
        .enterprises_pc {
          -webkit-line-clamp: inherit;
        }
        .enterprises_2_pc {
          -webkit-line-clamp: 5; /** 显示的行数 **/
        }
        p {
          padding: 3px 0;
          font-size: 14px;
          color: #999999;
        }
        .state {
          // color: #4c88dc;
          color: #666666;
          // text-decoration: underline;
        }
        .bannerImgs {
          width: 100%;
          height: 5rem;
        }
      }
      .activityListBox {
        width: auto;
        margin-left: 15px;
      }
      .presentation {
        position: relative;
        overflow: initial;
        .down {
          margin-top: 10px;
          // position: absolute;
          color: #4c88dc;
          font-size: 12px;
          right: 0;
          bottom: -13px;
          width: 100%;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 11;
          img {
            width: 14px;
            height: 8px;
            object-fit: contain;
            transition: all 0.35s;
            margin-left: 5px;
          }
          .downImg {
            transform: rotate(180deg);
          }
        }
      }
      .curriculumName {
        width: 92%;
        color: #2b2b2b;

        font-size: 14px;
        margin: 0 auto;
        margin-bottom: 15px;
        .name {
          font-weight: bold;
          // width: 100px;
          // display: flex;
          // align-items: center;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          // padding-left: 16px;
          line-height: 1;
        }
        // .name::before {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   left: 0;
        //   top: 3px;
        //   width: 6px;
        //   height: 6px;
        //   background-color: #4c88dc;
        //   border-radius: 50%;
        //   margin-right: 5px;
        // }
        .describeBox {
          // width: 90%;
          height: auto;
          margin: 0 auto;
          color: #666;
          font-size: 14px;
          margin-top: 5px;
          position: relative;
          // padding-left: 16px;
          .describe {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: inherit;
            -webkit-box-orient: vertical;
            text-align: justify;
            line-height: 1.7;
            white-space: pre-wrap;
          }
          .describes {
            -webkit-line-clamp: inherit;
          }
          .describes_2 {
            -webkit-line-clamp: 2;
          }
          // .overHidden{
          //   overflow: hidden;
          // }
          .down {
            position: absolute;
            color: #4c88dc;
            font-size: 12px;
            right: 0;
            margin-top: 1px;
            background: #fff;
            z-index: 11;
            width: 55px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              width: 14px;
              height: 8px;
              object-fit: contain;
              transition: all 0.35s;
              margin-left: 5px;
            }
            .downImg {
              transform: rotate(180deg);
            }
          }
          .downAC {
            bottom: -18px;
          }
        }
      }
    }
    .curriculum :last-child {
      margin-bottom: 0;
    }
  }
}

.video {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  .videoBox {
    width: 17.16rem;
    height: 10.24rem;
    display: flex;
    flex-direction: column;
    color: #999999;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .playerIcon {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 10px;
      bottom: 10px;
    }
    span {
      display: block;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      margin-top: 5px;
    }
  }
}
.video::-webkit-scrollbar {
  display: none;
}
.footer {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footerBtn {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    .footerBtnone {
      width: 270px;
      height: 45px;
      background-image: url(../../../assets/img/inviteuser2.png);
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 8px;
    }

    .footerBtntow {
      width: 145px;
      height: 49px;
      background-image: url(../../../assets/img/btn_Img1.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .collect {
    display: flex;
    flex-direction: column;
    margin-left: 26px;
    align-items: center;
    text-align: center;
    width: 60px;
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    span {
      color: #287952;
      font-size: 12px;
      font-weight: bold;
      margin-top: 2px;
    }
  }
}
.videoBoxoll {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .videoBg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #141019;
  }
  .video-player {
    width: 100%;
    position: relative;
    z-index: 101;
  }
}
.fix_boderbottom {
  border-bottom: 1px solid #eeeeee;
  // padding-bottom: 10px;
  padding: 10px;
}
.fix_boderbottom_mob {
  border-bottom: 8px solid #f9f9f9;
  padding-bottom: 0.8rem;
}
.fix_top_mob {
  border-top: 8px solid #f9f9f9;
  padding-top: 0.8rem;
}
</style>
