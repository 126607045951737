<template>
  <div class="floter">
    <div class="footcent">
      <div class="cenTop">
        <ul class="uls">
			
         <!-- 
		  <li @click="popblock">移动版</li>
          <router-link to="/abouts">
            <li>关于我们</li>
          </router-link>
		-->
		  
          <!-- <li>用户协议</li> -->
          <!-- <li>隐私政策</li> -->
          <!-- <li>侵权申述</li>
          <li>企业服务</li> -->
		  
          <!-- 
		  <router-link to="/helpcenter">
            <li>帮助中心</li>
          </router-link>
          <router-link to="/agreem">
            <li>联系我们</li>
          </router-link>
		   -->
          <!-- <li>中文</li>
          <li>English</li> -->
		  
          <!-- <div
            class="mages1"
            style="width:23px;height:18px;display: inline-block;"
          >
            <img
              style="width:100%;height:100%"
              src="../assets/image/weixinicont.png"
              alt=""
              @click="popblock"
            />
          </div> -->
		  
          <!-- <div class="mages2">
            <div class="mglf" style="width:25px;height:25px;">
              <img
                style="width:100%;height:100%"
                src="../assets/image/weixin.png"
                alt=""
              />
            </div>
            <div class="mgrt" style="width:25px;height:25px;">
              <img
                style="width:100%;height:100%"
                src="../assets/image/weibo.png"
                alt=""
              />
            </div>
          </div> -->
        </ul>
		
	
      </div>
	  <div class="cenBot">
		<div class="ulbox">
		  <ul class="ul1">
			<li>Copyright©&nbsp;2022&nbsp;&nbsp;机工新阅读</li>
		  	<li><a href="https://beian.miit.gov.cn" target="_blank">京ICP备14043556号-9</a></li>
		   </ul>
		</div>
	  </div>
      <!-- <div class="cenBot">
        <div class="ulbox">
          <ul class="ul1">
            <img
              src="https://gw.alicdn.com/tfs/TB1jwakrbH1gK0jSZFwXXc7aXXa-20-20.png"
              style="margin-right:5px"
              alt=""
            />
            <li class="acti">京公网安备11010502000501号</li>
            <li class="acti">广播电视节目直播经营许可证 (京) 字第06990号</li>
            <li class="acti">京ICP备11017824号</li>
            <li>京ICP证130164号</li>
          </ul>
          <ul class="ul2">
            <li class="acti">营业执照</li>
            <li class="acti">上海有害信息举报专区</li>
            <li class="acti">不良信息举报电话：010-56538658</li>
            <li class="acti">举报邮箱 jubao@zcool.com.cn</li>
            <li>联系电话：010-56538600</li>
            <li>Copyright © 2006-2020 ZCOOL站酷</li>
          </ul>
        </div>
      </div> -->
    </div>

    <div class="popContainer" v-show="isshow" ref="popls" @click="handelpop">
      <div class="erwem">
        <div class="mers">
          <img
            src="../assets/image/huazer.png"
            style="width:100%;height:100%"
            alt=""
          />
        </div>
        <p>扫码使用机工新阅读小程序</p>
        <p>在手机上管理我的云书房</p>
        <img class="imgas" src="../assets/image/guanbi.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow: false,
    };
  },
  methods: {
    handelpop() {
      this.isshow = false;
    },
    popblock() {
      this.isshow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(../assets/css/footer.css);
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  cursor: default;
  .erwem {
    width: 281px;
    height: 344px;
    background: #fff;
    margin: 200px auto;
    border: 1px solid #fff;
    position: relative;
    .mers {
      width: 189px;
      height: 198px;

      margin: 0 auto;
      margin-top: 52px;
      // border: 1px solid red;
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
    }
    .imgas {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
</style>
