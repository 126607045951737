<template>
  <div
  ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="10"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <top-nav ref="topNav" @scrollToTop="scrollToTop" :isShowScrollBtn="isShowScrollBtn" :isShowNav="isShowNav" :isInputing="isInputing" />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
      duration="300"
      appear
    >
      <!--  -->
      <router-view />
    </transition>
  </div>
</template>

<script>
import topNav from "../views/Home/Home.vue";
import "animate.css";
export default {
  components: {
    topNav,
  },

  data() {
    return {
      isShowNav: false,
      isInputing: false,
      isShowScrollBtn: false,
    };
  },

  methods: {
    
    scrollToTop() {
      this.$refs.main.scrollTo({
        top:0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      if (this.$refs.topNav) {
        this.$refs.topNav.input_blurIpt();
      }
      this.$forceUpdate();
    },
    loadMore() {},
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;

        this.isInputing = false;
        if (this.$refs.topNav) {
          this.$refs.topNav.input_blurIpt();
        }
      } else {
        this.isShowNav = false;
      }

      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
