<template>
	<div class="coursedtl">
		<!-- <div class="top" @click="backTop">
		  <div class="divg">
			<img
			  style="width: 100%; height: 100%"
			  src="../../assets/image/shang.png"
			  alt=""
			/>
		  </div>
		</div> -->
		<!-- <BackTop></BackTop> -->

		<div class="eblmax">
			<div class="mianb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/teachers2' }">师资</el-breadcrumb-item>
					<el-breadcrumb-item>{{data.title}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<div class="courseMain">
				<!-- 顶部 -->
				<div class="top">
					<img class="tag" :src="imageDomain+'images_teacher_new/detail_top_tag.png'" />
					<div class="mes">
						<div class="left">
							<div class="span">
								<div class="avatar">
									<img :src="data.lecturer.imgUrl" style="width: 100%;" />
								</div>
								<img class="block" :src="imageDomain+'images_teacher_new/detail_top_at_bg.png'" style="width: 100%;" />
							</div>
						</div>
						<div class="right">
							<div class="title">{{data.title}}</div>
							<div class="line"></div>
							<div class="name">讲师：{{data.lecturer.name}}</div>
							<div class="name2" v-html="data.lecturer.title.replace(/\r\n/g,'<br>')"></div>
							<div class="name2"></div>
						</div>
					</div>
				</div>

				<!-- 切换卡 -->
				<div class="navbox">
					<div class="nav" :class="{active: tabindex == 1}" @click="changeTabIndex(1)">
						<div class="text">简介</div>
					</div>
					<div class="nav" :class="{active: tabindex == 2}" @click="changeTabIndex(2)">
						<div class="text">大纲</div>
					</div>
				</div>


				<!-- 简介内容 -->
				<div class="navCont" v-if="tabindex == 1">
					<div class="box" v-if="data.audience">
						<div class="title">
							<div class="icon"></div>
							<div class="text">学习受众</div>
						</div>
						<div class="desc" v-html="data.audience.replace(/\r\n/g,'<br>')"></div>
					</div>

					<div class="box" v-if="data.target">
						<div class="title">
							<div class="icon"></div>
							<div class="text">学习目标</div>
						</div>
						<div class="desc" v-html="data.target.replace(/\r\n/g,'<br>')"></div>
					</div>

					<div class="box" v-if="data.desc">
						<div class="title">
							<div class="icon"></div>
							<div class="text">详情介绍</div>
						</div>
						<div class="desc" v-html="data.desc.replace(/\r\n/g,'<br>')"></div>
					</div>


				</div>



				<!-- 大纲内容 -->
				<div class="navCont" v-if="tabindex == 2">
					<div class="box">
						<!-- <view class="title">
							<image :src="imageDomain+'images_teacher_new/detail.png'" mode="widthFix"></image>
							<text>共8篇</text>
							<view style="display: flex;">
								
							</view>
						</view>
					
						<view class="content">
							▶ 第一篇：企业生命周期
						</view> -->

						<div class="desc" v-html="data.outline.replace(/\r\n/g,'<br>')"></div>
					</div>
				</div>

			</div>
		</div>
		<foo-ter />
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			return {
				id:"",
				data:{},
				imageDomain: 'https://images.hzmedia.com.cn/h5/uniapp/static/',
				coursefCList: [],
				tabindex: 1
			};
		},
		components: {
			fooTer
		},
		// mounted() {
		//   window.addEventListener("scroll", this.scrollToTop);
		// },
		// destroyed() {
		//   window.removeEventListener("scroll", this.scrollToTop);
		// },
		created() {
			document.title = "师资 - 机工新阅读";
			var option = {
				id: this.$route.query.id || "",
			};
			if (option && option.id) {
				this.id = option.id;
				this.setupDetailData();
			}
		},
		methods: {
			//切换选项卡
			changeTabIndex(index) {
				this.tabindex = index;
			},
			//Detail
			setupDetailData() {
				var that = this;
				var url = apiUrl.lecturer_get_course_detail;
				var param = { id: this.id };
				this.Ajax(url, param, (data) => {
					if (data) {
						that.data = data;
						document.title = data.title + " - 课程 - 机工新阅读";
						console.log(JSON.stringify(that.data))
					}
				});
			},
			//QRCode
			setupQRCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			//Page: Author
			goToAuthorDetailPage() {
				if (this.data.author_id && this.author_id != "") {
					var url = "GuestDetail";
					var query = {
						name: this.data.author,
						id: this.data.author_id || 0,
					};
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
					// this.$router.push({
					//   path: url,
					//   query: query,
					// });
				}
			},
			//Page: Read personList
			goToPersonPage(id) {
				localStorage.setItem("toPagePath", "ebook");
				var url = "seebook";
				var query = {
					product_type: this.data.product_type,
					product_name: this.data.title,
					id: id,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				// this.$router.push({
				//   path: url,
				//   query: query,
				// });
			},
			//Page: AllBook
			goToAllBookPage(item) {
				localStorage.setItem("toPagePath", "ebook");
				var query = {
					type: 1,
					level: item.level,
					id: item.id,
				};
				this.GoToAllBook(query);
			},
			//Click: Preview
			previewImgClick(type) {
				if (type == 1) {
					this.isshow = true;
				} else {
					this.isshow = false;
				}
			},
			//Click: Sort
			changeSortType() {
				if (this.contentData) {
					this.sort = this.sort == 0 ? 1 : 0;
					this.contentData = this.contentData.reverse();
				}
			},
			//Click: Fold Detail
			goFoldDetail(item) {
				//目录详情点击
				if (item && item.uuid && item.chapterid) {
					if (item.open == 1) {
						this.goToReader(item.uuid, item.chapterid);
					} else {
						this.$message({
							message: '暂无权限阅读当前章节',
							type: 'warning',
							offset: 330,
							duration: 2000
						});
					}
				}
			},
			goToReader(uuid, chapterid) {
				var url = "ebookrdr";
				var query = {
					uuid: uuid,
					chapterid: chapterid,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			//Check Show
			checkIsShowFolder(item) {
				var that = this;
				if (that.contentFoldList.indexOf(item.parent) > -1) {
					return true;
				} else {
					return false;
				}
			},
			// backTop() {
			//   const that = this;
			//   let timer = setInterval(() => {
			//     let ispeed = Math.floor(-that.scrollTop / 5);
			//     document.documentElement.scrollTop = document.body.scrollTop =
			//       that.scrollTop + ispeed;
			//     if (that.scrollTop === 0) {
			//       clearInterval(timer);
			//     }
			//   }, 16);
			// },

			// // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			// scrollToTop() {
			//   const that = this;
			//   let scrollTop =
			//     window.pageYOffset ||
			//     document.documentElement.scrollTop ||
			//     document.body.scrollTop;
			//   that.scrollTop = scrollTop;
			//   if (that.scrollTop > 60) {
			//     that.btnFlag = true;
			//   } else {
			//     that.btnFlag = false;
			//   }
			// },
		},
	};
</script>

<style lang="scss">
	.coursedtl {
		width: 100%;
		background: #f7f7f7;
		min-width: 1157px;
		position: relative;
		cursor: default;


		.popContainer {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 999;

			.maxmage {
				width: 400px;
				height: 500px;
				background: #fff;
				margin: 0 auto;
				margin-top: 200px;
			}
		}

		.eblmax {
			width: 1157px;
			// border: 1px solid red;
			margin: 0 auto;

			.mianb {
				width: 1157px;
				height: 75px;
				// border: 1px solid red;
				margin: 0 auto;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #363636;

				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #e6554d !important;
				}
			}

			.courseMain {
				width: 1157px;
				min-height: 100vh;
				height: auto;
				padding: 35px;
				box-sizing: border-box;

				display: flex;
				flex-direction: column;
				background: #fff;
				position: relative;


				.top {
					width: 100%;
					
					//height: 150px;
					//background: linear-gradient(to bottom, #F5F5F5, #C7C8C8);

					.tag {
						width: 65px;
						position: absolute;
						top: -10px;
						right: 35px;
						z-index: 999;
					}

					.mes {
						width: 100%;
						display: flex;
						flex-direction: row;
						
						.left {
							width: 240px;
							position: relative;

							.span {
								width: 240px;
								height: 240px;
								position: relative;
								background: linear-gradient(to bottom, #F5F5F5, #C7C8C8);


								.block {
									width: 240px;
									position: absolute;
									bottom: -20px;
									right: -20px;
									z-index: 8;
								}


								.avatar {
									z-index: 9;
									width: 240px;
									height: 240px;
									overflow: hidden;
									background: linear-gradient(to bottom, #F5F5F5, #C7C8C8);
									position: absolute;
									
									img {
										width: 100%;
									}
								}
							}
						}

						.right {
							margin-top: 25px;
							flex: 1;
							padding: 25px 75px;
							box-sizing: border-box;
							background: linear-gradient(to bottom, #F5F5F5, #C7C8C8);

							.title {
								font-weight: 600;
								color: #E4574E;
								line-height: 1.2;
								font-size: 30px;
								margin: 15px 0;
							}

							.line {
								border-bottom: 1px solid #E4574E;
								width: 30px;
							}

							.name {
								color: #E4574E;
								line-height: 2;
								font-size: 20px;
								font-weight: bold;
							}

							.name2 {
								color: #555555;
								font-size: 14px;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								-webkit-box-orient: vertical;
							}

						}
					}
				}


				.navbox {
					clear: both;
					margin-top: 35px;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					line-height: 2.2;
					height:60px;
					z-index: 9999;

					.nav {
						width: 50%;
						display: flex;
						justify-content: center;
						font-size: 24px;
						cursor: pointer;

						.text {
							width: 10em;
							display: flex;
							justify-content: center;
							font-weight: bold;
							color: #000000;
						}

						&.active {
							.text {
								border-bottom: 1px solid #E4574E;
								color: #E4574E;
							}
						}
					}
				}

				.navCont {
					display: flex;
					flex-direction: column;
				}

				.box {
					display: flex;
					flex-direction: row;
					margin-top: 5px;
					padding: 15px;
					box-sizing: border-box;

					.title {
						display: flex;
						flex-direction: row;
						position: relative;
						width: 7em;
						align-items: center;
						height: 30px;

						.icon {
							width: 10px;
							height: 10px;
							background-color: #E4574E;
							border-radius: 50%;
							margin-right: 5px;
						}

						.text {
							z-index: 999;
							font-size: 16px;
							font-weight: bold;
						}
					}

					.desc {
						flex: 1;
						font-size: 15px;
						line-height: 1.75;
						text-align: justify;
						padding: 0 10px 10px 10px;
						box-sizing: border-box;
						border-left: 3px solid #F6DEDD;
						clear: both;
						
						.dotted{
							width: 8px;
							height: 8px;
							background-color: #E4574E;
							border-radius: 50%;
							margin-right: 5px;
						}
					}

					.content {
						background-color: #F6DEDD;
						font-weight: 500;
						font-size: 15px;
						color: #E4574E;
						padding: 5px 10px;
						box-sizing: border-box;
						border-radius: 15px;
						margin-top: 10px;
					}
				}
			}
		}
	}
</style>
