<template>
  <div id="iframeBg">
    <div class="allBox">
      <div class="contentPC">
        <div class="navPC">
          <div class="nav">
            <ul>
              <li>
                <span class="pointer" @click="event_navToPage_pc(1, '')"
                  >机工新阅读</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer" @click="event_navToPage_pc(2, '')"
                  >师资</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer" @click="event_navToPage_pc(2, '')">{{
                  navClassTypeName
                }}</span>
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span
                  class="pointer"
                  @click="event_navToPage_pc(3, lecturerId)"
                  >{{ navTitle }}</span
                >
              </li>
              <li>
                <i class="el-icon-arrow-right el-breadcrumb__separator"></i>
              </li>
              <li>
                <span class="pointer color">发送邀请</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="contentBox">
          <div class="headImg">
            <img src="../../../assets/img/company.png" alt />
            <span class="activity">联合为您提供嘉宾邀请服务</span>
          </div>
          <div class="contentBoxPC">
            <img src="../../../assets/img/prosperity.png" alt />
            <span class="stateText">发送成功</span>
            <span class="name">机械工业出版社将会在1~3个工作日与您取得联系</span>
            <span class="activity">(活动行用户专享特惠)</span>
            <div class="BtnBox" @click="event_backToList()">
              <span>返回首页</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foo-ter />
  </div>
</template>

<script>
import fooTer from "../../../components/footer";
import ApiUrl from "../../../js/apiUrl.js";
import comm from "../../../js/comm.js";
import elementResizeDetectorMaker from "element-resize-detector";
export default {
  components: {
    fooTer,
  },
  data() {
    return {
      rlen: history.length,
      isMobile: "",
      rightNavShow: "",
      navLevel: 5,
      lecturerId: "",
    };
  },

  methods: {
    // PC
    event_navToPage_pc(type, info) {
      if (type == 1) {
        //机工新阅读
        //测试代码:
        this.$router.push({
          path: "teachers",
          query: {},
        });
      } else if (type == 2) {
        //师资   / 热门
        this.$router.push({
          path: "GuestList",
          query: {},
        });
      } else if (type == 3) {
        //讲师信息
        this.$router.push({
          path: "GuestDetail",
          query: {
            id: info || "",
          },
        });
      }
    },
    // event_navToPage_pc(type, info) {
    //   var navIndex = type - this.navLevel;
    //   navIndex = this.rlen - history.length + navIndex;
    //   if (type == 1) {
    //     //机工新阅读
    //     //测试代码:
    //     navIndex += 1;
    //     this.$router.go(navIndex);
    //   } else if (type == 2) {
    //     //师资   / 热门
    //     this.$router.go(navIndex);
    //   } else if (type == 3) {
    //     //讲师信息
    //     this.$router.go(navIndex);
    //   }
    // },
    event_backToList() {
      this.event_navToPage_pc(2);
    },
  },
  created() {
    document.title = "师资 - 机工新阅读";
    this.navClassTypeName = this.$route.query.navClassTypeName || "";
    this.navTitle = this.$route.query.navTitle || "";
    this.lecturerId = this.$route.query.lecturerId || "";
  },
  mounted() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    console.log(this.flag);
    if (this.flag === null) {
      console.log("pc端");
      this.rightNavShow = true;
    } else {
      console.log("移动端");
      this.rightNavShow = false;
    }
    const clientWidth = document.body.clientWidth;

    console.log("屏幕尺寸", clientWidth);
    console.log("状态", this.rightNavShow);

    if (this.rightNavShow) {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      var app = document.getElementById("iframeBg");
      erd.listenTo(app, (element) => {
        _this.$nextTick(() => {
          var iHeight = app.offsetHeight + 11;
          iHeight = iHeight > 500 ? iHeight : 500;
          parent.window.postMessage(
            { msgType: 1, msgData: { iHeight: iHeight } },
            "*"
          );
        });
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
    return;
    if (!(window.self === window.top)) {
      //iframe打开
      next();
      return;
    }

    // var userId = from;
    //
    // from.meta.keepAlive = true;

    var isMobile = true; //判断是否是手机
    var flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    // console.log(flag);
    if (flag === null) {
      // console.log("pc端");
      isMobile = false;
      next({
        path: "/GuestDetailBg", //跳pc首页
      });
      // next();
    } else {
      // console.log("移动端");
      isMobile = true;
      next();
    }
  },
};
</script>

<style scoped lang="scss">
// PC
.contentPC {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  cursor: default;
  .navPC {
    width: 100%;
    height: 75px;
    background-color: #F4F4F4;
    .nav {
      width: 100%;
      height: 100%;
      max-width: 1157px;
      margin: 0 auto;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        li {
          height: 100%;
          // margin-right: 16px;
          display: flex;
          align-items: center;
          color: #363636;
          font-size: 16px;
          // font-weight: bold;
          .top {
            margin-top: 4px;
          }
        .pointer {
          cursor: pointer;
            font-weight: 700;
            color: #303133;
        }
          .pointer:hover{ 
            color:#429efd;
          }
          .color {
            color: #e6554d !important;
            font-weight: 400;
          }
          .color:hover{ 
            color:#e6564e;
            cursor: auto;
          }
        }
      }
    }
  }
  .contentBox {
    width: 100%;
    height: 100%;
    max-width: 1157px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    .contentBoxPC {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.3rem;
      .activity {
        color: #52bf80;
        font-size: 18px;
        margin-top: 0.1rem;
      }
      .BtnBox {
        width: 3.5rem;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(194deg, #55c87f 0%, #4fae82 100%);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        margin-top: 0.5rem;
        cursor: pointer;
        span {
          color: #fff;
          font-size: 16px;
        }
      }
      img {
        width: 1.7rem;
        height: 1.7rem;
        object-fit: contain;
      }
      .stateText {
        color: #52bf80;
        font-size: 24px;
        font-weight: bold;
      }
      .name {
        color: #52bf80;
        font-size: 18px;
        width: auto;
        text-align: center;
        margin-top: 0.1rem;
      }
    }
    .headImg {
      display: flex;
      align-items: center;
      padding-left: 0.3rem;
      padding-top: 0.1rem;
      img {
        width: 3rem;
        height: 0.5rem;
        object-fit: contain;
      }
      .activity {
        font-size: 18px;
        color: #666666;
        margin-left: 5px;
      }
    }
  }
}

// 手机样式
.content {
  width: 100%;
  margin-top: 50px;
  .Btn {
    width: 200px;
    height: 49px;
    background: linear-gradient(194deg, #55c87f 0%, #4fae82 100%);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 60px;
    span {
      font-size: 16px;
      color: #fff;
    }
  }
  .state {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    .activity {
      color: #52bf80;
      font-size: 18px;
      margin-top: 0.5rem;
    }
    img {
      width: 10rem;
      height: 10rem;
    }
    .stateText {
      color: #52bf80;
      font-size: 24px;
      font-weight: bold;
      margin-top: 0.2rem;
    }
    .name {
      width: 90%;
      text-align: center;
      color: #52bf80;
      font-size: 18px;
      margin-top: 0.5rem;
    }
  }
  .footer {
    width: 100%;
    position: fixed;
    bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    font-size: 18px;
    .activity {
      color: #666666;
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
    img {
      width: 13rem;
      height: 2rem;
      object-fit: contain;
    }
  }
}
.navMT {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

.navMT .backtrack {
  position: fixed;
  top: 15px;
  left: 13px;
  width: 16px;
  height: 22px;
}
.navMT .seek {
  position: fixed;
  top: 15px;
  right: 13px;
  width: 23px;
  height: 22px;
  object-fit: cover;
}
</style>
