<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="0"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      class="boska"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>
    <div class="topNav">
      <div class="logipt">
        <!-- logo -->
        <div class="logoBox">
          <div class="logo" @click="GoToChangeTab(0)" style="cursor: pointer" v-if="!isLogin">
            <img
              style="width: 100%; height: 100%"
              src="../assets/image/logo.png"
              alt=""
            />
          </div>
		  <div class="logo_title" @click="GoToChangeTab(1)" style="cursor: pointer;" v-if="isLogin">
		  	<h1>{{corpTitle}}</h1>
		  </div>
			
				
          <!-- <router-link to="/searchpage"> -->
          <div
            class="inputs"
            style="width: 485px !important; background: #f7f7f7ff"
          >
            <img src="../assets/image/search.png" alt="" />
            <input
              ref="inputRef"
              class="ipus"
              style="
                color: #999999;
                outline: none;
                background: rgb(247, 247, 247);
                border: none;
                width: 360px;
                height: 34px;
                font-size: 16px;
              "
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              v-on:keyup.enter="input_search(input_content)"
              v-model="input_content"
            />
            <!-- @blur="input_blurIpt" -->
            <div
              class="btnBox"
              style="
                width: 88px;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                background: #e6564eff;
                color: white;
                border-radius: 19px;
                cursor: pointer;
              "
              @click="input_search()"
            >
              搜索
            </div>
          </div>
          <div class="showContent" v-if="isInputing2">
            <div class="titleBg">
              <div class="title">热门搜索</div>
              <div class="clear"></div>
            </div>
            <div
              class="item"
              v-for="(item, index) in hotKeywords"
              :key="index"
              @click="input_search(item.title)"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- </router-link> -->
        </div>
		<div v-if="isLogin">
			<router-link to="/yunbook" 
			style="display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;">
				<h3 style="font-size: 18px;color: #2b2b2b;">
					云书房
				</h3>
				<img
					class="hoveimg"
					style="width:24px;height:100%; margin:0 0 5px 5px;"
					src="../assets/image/lishi.png"
					alt="" />
			</router-link>
		</div>
        <!-- logins -->
        <!-- <div class="logins"><span>登录</span>/<span>注册</span></div> -->
        <!-- <div class="users">
          <router-link to="/yunbook">
            <div>我的云书房</div>
          </router-link>
          <img
            class="hoveimg"
            style="width:47px;height:100%"
            src="../../assets/image/buddha.png"
            alt=""
          />
        </div> -->
      </div>
      <!-- tab栏 -->
      <div
        class="tablistBg"
        v-if="isShowNav"
        style="height: 70px; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      ></div>
      <div
        class="tablistBg"
        :class="isShowNav ? 'topNavShow' : ''"
        style="margin-top: 0px"
      >
        <div class="tablist">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tabActive: index == num }"
            @click="GoToChangeTab(item.product_type)"
            style="color: #333333"
          >
            {{ item.title }}
            <span
              v-if="item.product_type == 11"
              :class="index == num ? 'tabActive2' : 'unTabActive2'"
              style="color: #47cd96"
              >LIVE</span
            >
          </div>
        </div>
      </div>
      <div class="hovers" ref="hoves">
        <div class="vipmoy">
          <img class="viplo" src="../assets/image/viplogo.png" alt="" />
          <span class="spanv">[续费VIP]</span>
        </div>
        <div class="names">一直会飞哈哈哈哈哈哈哈哈哈哈</div>
        <div class="times">2021.08.10 到期</div>
        <div class="fenge"></div>
        <div class="fosnam">
          <p>我的订单</p>
          <p>我的卡券</p>
        </div>
        <div class="fonbot">
          <p>物流查询</p>
          <p>我的企业</p>
          <p>关于我们</p>
          <p>作者助手</p>
        </div>
        <div class="nect">退出</div>
      </div>

      <!-- content -->
    </div>
    <div class="searchpg">
      <div class="serchmax">
        <div class="navOll" style="display: none">
          <div class="navInp">
            <div class="seekImg">
              <img src="../assets/image/search.png" alt="" />
            </div>

            <input
              class="ipus"
              style="color: #666666; outline: none"
              confirm-type="search"
              placeholder-class="text"
              placeholder="书名、作者、关键字"
              @focus="input_focusIpt"
              @blur="input_blurIpt"
              v-on:keyup.enter="searchClick"
              v-model="input_content"
            />
            <img
              class="mgsa"
              style="
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin-right: 10px;
              "
              v-show="hideDel"
              src="../assets/image/v.png"
              alt=""
              @click="input_content = ''"
            />
          </div>
          <div class="btnBox" @click="searchClick()">搜索</div>
        </div>
        <div class="tabLst" v-show="tabLists && tabLists.length > 0">
          <div class="tabFot">
            <div
              class="tabfonts"
              :class="TabCur == ina ? 'active' : ''"
              v-for="(ite, ina) in tabLists"
              :key="ina"
              @click="changeSelectTab(ina)"
            >
              {{ ite.name }}
            </div>
          </div>
        </div>
        <div
          class="batil"
          v-for="(typeItem, inedex2) in tabLists"
          v-show="
            tabLists[TabCur] &&
            typeItem.product_type == tabLists[TabCur].product_type
          "
          :key="inedex2"
        >
          <h2>共 {{ typeItem.product_count }} 种</h2>
          <div class="conten">
            <div
              class="condtl"
              v-for="(item, index3) in result"
              :key="index3"
              @click="goToDetailPage(item.product_type, item.id, item)"
            >
              <div :class="item.product_type != 13 ? 'dtlft' : 'lftactiveL'">
                <img
                  v-if="item.product_type != 13 && item.product_type != 11 && item.product_type != 12"
                  :src="item.img_url"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                  "
                  alt=""
                />
                <img
                  v-if="item.product_type == 11"
                  :src="item.image_url"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                  "
                  alt=""
                />
				
				<img
				  v-if="item.product_type == 12"
				  :src="item.img_url_vertical"
				  style="
				    width: 100%;
				    height: 100%;
				    object-fit: cover;
				    border-radius: 5px;
				  "
				  alt=""
				/>
				
                <img
                  v-if="item.product_type == 13"
                  :src="item.image"
                  style="
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    border-radius: 5px;
                  "
                  alt=""
                />
                <img
                  class="erjia"
                  v-if="item.product_type == 3"
                  src="../assets/image/icon-listen.png"
                  alt=""
                />

                <!-- <img v-if="item.product_type == 3" class="lisIcon" src="../../static/images/icon-listen.png" mode=""/> -->
              </div>
              <div class="dltrgt" v-if="item.product_type != 13" >
                <h3>{{ item.title }}</h3>
                <div class="thors">{{ item.author }}</div>
              </div>
              <div class="dltrgt" v-if="item.product_type == 13">
                <h3>{{ item.name }}</h3>
				<div class="personMesoll">
				  <div class="personMesollP">
				    <p v-for="(titleItem, j) in item.titleList">
				      {{ titleItem }}
				    </p>
				  </div>
				</div>
				<div class="sort">
				  <span
				    class="labelBox"
				    v-for="(tagItem, j) in item.tagList"
				    >{{ tagItem }}</span
				  >
				</div>
              </div>
			  

            </div>
          </div>
        </div>
        <div class="noContentBg" v-show="!(result && result.length > 0)">
          <img class="placeImg" src="../assets/image/search_place.png" alt="" />
        </div>
      </div>
      <foo-ter class="footer" />
    </div>
  </div>
</template>

<script>
import fooTer from "../components/footer";
import apiUrl from "../js/apiUrl.js";
import comm from "../js/comm.js";

export default {
  data() {
    return {
      num: 0,
      tabList: [],
      productTypeData: {},
      busy: false,
      input_content: "",
      acitvnum: 0,
      hideDel: false,
      TabCur: -1,
      isContent: true,
      isContents: false,
      NoContent: false,
      tabLists: [],

      hotKeywords: [], //热门搜索
      historyKeywords: [], //历史搜索
      searchSearch: {
        value: null,
        product_type: 1,
        page_index: 1,
        page_size: 10,
        order_by: "start_at:desc",
		client_type: 10,//机工新阅读PC版
      },
      result: [], //搜索结果
      total: "", //搜索总数
      pageCount: 1,
      hideDel: false,
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
	  isLogin: false,
	  corpTitle:''
    };
  },
  components: {
    fooTer,
  },
  created() {
    var that = this;
	that.isLogin = sessionStorage.hasOwnProperty("userLogInInfo");
	if(that.isLogin){
		var userInfo = JSON.parse(sessionStorage.getItem("userLogInInfo"));
		if (userInfo && userInfo.title != '') {
		    that.corpTitle = userInfo.title;
		}
	}
	
    var input_Str = this.$route.query.inputStr || "";
    this.input_content = input_Str;
    document.title = "搜索 - 机工新阅读";
    // this.hotKeywordsGet();
    // this.historyKeywordsGet();
    this.searchClick();
    this.setupList();
    this.hotKeywordsGet();

    window.addEventListener("resize", function () {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
    },

    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function (data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.searchClick();
      this.setupList();
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    loadMore() {},
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }else if (item.product_type == 13) {
		    item.title = "师资";
		  }
		  if(item.product_type != 11){
			  that.tabList.push(item);
		  }
        });
		that.tabList.push({
			title:'院校服务',
			img_url:'',
			product_type:15,
			product_count:0
		})
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
    //Next PageList
    loadMore() {
      if (this.busy) return;
      this.busy = true;
      this.setupListData(this.searchSearch.page_index + 1);
    },
    //Click: change Tab
    changeSelectTab(index) {
      this.TabCur = index;
      if (!this.tabLists[this.TabCur]) return;
      this.setupListData(1, this.tabLists[this.TabCur].product_type);
    },
    //Click: Search
    searchClick() {
      this.isContents = true;
      this.isContent = false;
      this.setupTabarList();
    },
    //TabbarList
    setupTabarList() {
      var that = this;
      var url = apiUrl.search_count_get;
      var param = { value: that.input_content || "" , client_type: 10 };
      this.Ajax(
        url,
        param,
        (data) => {
          if (data) {
            that.tabLists = [];

            for (let i = 0; i < data.length; i++) {
			  if(data[i].product_type!=11){
				that.tabLists.push({
				  name: data[i].title,
				  product_type: data[i].product_type,
				  product_count: data[i].product_count,
				});  
			  }
             
              //第一个有数据的
              if (that.TabCur < 0 && data[i].product_count > 0) {
                that.TabCur = i;
              }
            }
            if (!that.tabLists[that.TabCur]) return;
            that.setupListData(1, that.tabLists[that.TabCur].product_type);
          } else {
            //
          }
        },
        function (data) {
          that.result = [];
          that.tabLists = [];
          // uni.showToast({
          // 	title: "搜索内容不存在",
          // 	icon: 'none',
          // 	duration: 2000
          // });
          return;
        }
      );
    },
    //ListData
    setupListData(index, product_type) {
      var that = this;
      that.searchSearch.value = that.input_content || "";
      if (!index || index <= 0) {
        index = 1;
      }

      that.searchSearch.page_index = index;

      if (that.searchSearch.page_index == 1) {
        that.pageCount = 1;
        that.result = [];
      }

      if (that.searchSearch.page_index > that.pageCount) {
        // uni.showToast({
        // 	title: "没有更多内容了",
        // 	icon: 'none',
        // 	duration: 2000
        // });
        that.busy = false;
        return;
      }

      if (product_type) {
        that.searchSearch.product_type = product_type;
      }
      var url = apiUrl.search_list_get;
      var param = that.searchSearch;
      this.Ajax(url, param, (data) => {
        if (data && data.list.length > 0) {
          if (that.searchSearch.page_index == 1) {
            that.result = data.list;
          } else {
            that.result.push.apply(that.result, data.list);
          }

          that.total = data.total;
          that.pageCount = data.count;
        } else if (data == null) {
          that.isContent = false;
          that.isContents = false;
          that.NoContent = true;
        }
        that.busy = false;
      });
    },
    //Page: Detail
    goToDetailPage(product_type, id, item) {
      if (product_type == 13) {
        var url = "GuestDetail";
        var query = {
          name: item.name,
          id: item.id,
        };
        let routeUrl = this.$router.resolve({
          path: url,
          query: query,
        });
        window.open(routeUrl.href, "_blank");
        // this.$router.push({
        //   path: url,
        //   query: query,
        // });
      } else {
        this.GoToDetail(product_type, id);
      }
    },
    // //Input forcous
    // input_focusIpt() {
    //   this.hideDel = true;
    // },
    // //Input blur
    // input_blurIpt() {
    //   if (this.input_content) {
    //     this.hideDel = true;
    //   } else {
    //     this.hideDel = false;
    //   }
    // },
    //Other: Remove History Keywords
    search_history_del(value) {
      var that = this;
      this.Ajax(
        apiUrl.search_history_del,
        {
          value: value,
        },
        function (data) {}
      );
    },
    //Other: Recomend keywords
    hotKeywordsGet() {
      var that = this;
      var url = apiUrl.search_keywords_get;
      var param = {};
      this.Ajax(url, param, (data) => {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    //Other: History keywords
    historyKeywordsGet() {
      var that = this;
      var url = apiUrl.search_history_get;
      var param = {};
      this.Ajax(url, param, (data) => {
        if (data && data.length > 0) {
          that.historyKeywords = data;
        }
      });
    },
    //Other Clear History Keywors
    search_history_clear() {
      var that = this;
      var url = apiUrl.search_history_clear;
      var param = {};
      this.Ajax(url, param, (data) => {});
    },
  },
};
</script>

<style lang="scss">
.maxBox {
  width: 100%;
  min-width: 1157px;
  cursor: default;
  // background: #d8e4f8;
  .boska {
    // background: yellow;
    position: fixed;
    // top: 660px;
    right: calc(100px + 50px);
    // right: calc(100%-20px);
    // right: cal;
  }
  .top {
    width: 70px;
    height: 70px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.19;
    display: flex;
    align-items: center;
    .divg {
      width: 32px;
      height: 18px;
      margin: 0 auto;
    }
  }
  .topNav {
    width: 100%;
    // height: 173px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .hovers {
      width: 221px;
      height: 506px;

      z-index: 20;
      position: absolute;
      right: 290px;
      top: 90px;
      background: #fff;

      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      display: none;
      .vipmoy {
        width: 180px;
        height: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-top: 14px;
        .viplo {
          width: 34px;
          height: 15px;
        }
        .spanv {
          width: 65px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #e6544c;
        }
      }
      .names {
        width: 171px;
        height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 8px;
      }
      .times {
        width: 123px;
        height: 20px;
        margin-left: 22px;
        margin-top: 9px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #999999;
      }
      .fenge {
        width: 179px;
        height: 1px;
        background: #eeeeee;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 18px;
      }
      .fosnam {
        width: 179px;
        height: 100px;
        // border: 1px solid red;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;

        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .fonbot {
        width: 179px;
        height: 190px;
        // border: 1px solid;
        margin: 0 auto;
        border-bottom: 1px solid #eeeeee;
        p {
          width: 76px;
          height: 25px;
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
      .nect {
        width: 179px;
        height: 57px;
        margin: 0 auto;

        line-height: 57px;

        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .logipt {
      width: 1157px;
      height: 60px;

      margin: 0 auto;
      margin-top: 27px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoBox {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .logo {
         /*
          width: 270px;
          height: 45px;
          */
         width: 200px;
         margin-top: -17px;
         margin-right: 27px;
        }
		
		.logo_title {
			display: inline-block;
			h1 {
				font-size: 30px;
			}
		}
		
        .inputs {
          width: 140px;
          height: 34px;
          background: #f7f7f7;
          border-radius: 25px;
          margin-left: 30px;
          text-align: center;
          font-size: 20px;
          line-height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #c9c9c9;
          }
        }
        .showContent {
          position: absolute;
          top: 50px;
          left: 320px;
          width: 452px;
          // height: 216px;
          background: white;
          z-index: 99999;
          padding: 18px;
          .titleBg {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            .title {
              width: 64px;
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2b2b2b;
              line-height: 22px;
            }
            .clear {
              width: 32px;
              height: 22px;
              font-size: 16px;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              color: #e6564e;
              line-height: 22px;
            }
          }
          .item {
            height: 22px;
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #999999;
            line-height: 22px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .logins {
        width: 95px;
        height: 29px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e6564e;
      }
      .users {
        width: 210px;
        height: 47px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #e6564e;
          line-height: 25px;
          width: 130px;
          height: 25px;
          // border: 1px solid red;
          border-right: 3px solid #d8d8d8;
        }
      }
    }
    .tablistBg {
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topNavShow {
      position: fixed !important;
      z-index: 99 !important;
      top: 0 !important;
      // left: 0 !important;
      // left: calc((100% - 1157px) / 2) !important;
      background: white;
      margin-top: 0 !important;
      // width: calc(100% - 15px);
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .tablist {
      height: 70px;
      width: 1157px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #bdbdbd;
      position: relative;
      margin-top: 0px !important;
      .tabActive {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #e6564e !important;
        padding-bottom: 5px;
        // border: 1px solid red;
        text-align: center;

        display: flex;
        justify-content: center;
      }
      .tabActive2 {
        margin-left: 0;
      }
      .unTabActive2 {
        margin-left: -6px;
      }
      .tabActive::before {
        content: "";
        width: 40px;
        border-radius: 5px;
        height: 3px;
        position: absolute;
        text-align: center !important;
        // border-bottom: 4px solid #e6564e;
        background-color: #e45754;
        display: block;
        bottom: 0;
      }
    }
    .tablist:hover {
      cursor: pointer;
    }
  }

  .searchpg {
    width: 100%;
    background: #f7f7f7;
    min-width: 1157px;
    border: 1px solid #fff;
    .serchmax {
      width: 1157px;
      margin: 0 auto;
      min-height: 800px;
      background: #fff;
      margin-top: 20px;
      border: 1px solid #fff;
      .navOll {
        width: 726px;
        height: 67px;
        background: #f7f7f7;
        border-radius: 34px;
        margin: 0 auto;
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navInp {
          width: 83%;
          height: 100%;
          border-radius: 34px;

          display: flex;
          // justify-content: space-between;
          align-items: center;

          .seekImg {
            width: 35px;
            height: 35px;
            z-index: 999;
            //   background: chartreuse;
            // border: 1px solid red;
            margin-left: 38px;
            margin-right: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ipus {
            height: 100%;
            width: 79%;
            // border-radius: 34px;
            font-size: 25px;
            background: #f7f7f7;
            border: 1px solid #f7f7f7;
          }
        }
        .btnBox {
          width: 118px;
          height: 48px;
          background: #e6564e;
          border-radius: 34px;
          text-align: center;
          cursor: pointer;
          line-height: 48px;
          font-size: 22px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .tabLst {
        width: 1088px;
        margin: 0 auto;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        .tabFot {
          height: 56px;
          width: 538px;

          margin: 0 auto;
          display: flex;
          justify-content: space-evenly;
          .tabfonts {
            font-size: 19px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
			//color: #CCCCCC;
            color: #999999;
            line-height: 56px;
          }
          .tabfonts:hover {
            cursor: pointer;
          }
          .active {
            display: flex;
            justify-content: center;
            position: relative;
            color: #e45754;
          }

          .active::before {
            content: "";
            width: 26px;
            border-radius: 5px;
            height: 3px;
            position: absolute;
            background-color: #e45754;
            display: block;
            bottom: 0;
          }
        }
      }
      .noContentBg {
        width: 100%;
        height: 700px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        .placeImg {
          width: 342px;
          height: 260px;
        }
      }
      .batil {
        width: 1022px;
        // height: 1000px;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 22px;
        h2 {
          font-size: 29px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #2b2b2b;
          letter-spacing: 1px;
        }
        .conten {
          width: 100%;

          // border: 1px solid red;
          margin-top: 7px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .condtl {
            height: 192px;
            width: 455px;
            cursor: pointer;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            margin-top: 27px;

            .dtlft {
              width: 121px;
              height: 160px;

              box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
              object-fit: cover;

              position: relative;
              .erjia {
                width: 31px;
                height: 31px;
                position: absolute;
                top: 9px;
                right: 9px;
              }
            }
            .lftactiveL {
              width: 121px;
              height: 121px;
              border-radius: 50%;
            }

            .dltrgt {
              height: 160px;
              width: 315px;

              h3 {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2b2b2b;
                letter-spacing: 1px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                // margin-bottom: 9px;
              }
              .thors {
                font-size: 16px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: #666666;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                // margin-bottom: 12px;
              }
              p {
                font-size: 16px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: #cccccc;
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .risk {
                display: flex;
                align-items: center;
                margin-top: 70px;
                img {
                  width: 27px;
                  height: 27px;
                  margin-left: 120px;
                }
                .flils {
                  width: 120px;
                  height: 36px;
                  background: #f4f5f6;
                  border-radius: 18px;
                  font-size: 22px;
                  font-family: PingFang-SC-Medium, PingFang-SC;
                  font-weight: 500;
                  color: #e6564e;
                  line-height: 36px;
                  letter-spacing: 1px;
                  text-align: center;
                  margin-left: 22px;
                }
              }
			  
			  .personMesoll {
			    display: flex;
			    color: #cccccc;
			    font-size: 15px;
			    justify-content: space-between;
			    max-height: 65px;
			    overflow: hidden;
			    margin: 5px 0;
			  
			    .personMesollP {
			      width: 90%;
			      height: 100%;
			  
			      p {
			        width: 100%;
			        white-space: nowrap;
			        text-overflow: ellipsis;
			        overflow: hidden;
			        font-size: 14px;
					margin-bottom: 0;
			      }
			    }
			  }
			  
			  
			  .sort {
			    margin-top: 5px;
			    display: flex;
			    flex-wrap: wrap;
			    max-height: 25px;
			    overflow: hidden;
			  }
			  
			  .sort span {
			    display: inline-block;
			    padding: 2px 10px;
			    background-color: #e0f3f7;
			    color: #52c4c6;
			    font-size: 15px;
			    // margin-bottom: 20px;
			  }
			  
			  .sort .labelBox {
			    display: inline-block;
			    //background-color: #e0f3f7;
			    //color: #52c4c6;
			    background-color: #fff4e7;
			    color: #ef8608;
			    padding: 2px 8px;
			    margin-right: 5px;
			    border-radius: 2px;
			    font-size: 14px;
			  }
			  
            }
          }
        }
      }
    }
  }
  .searchpg::-webkit-scrollbar {
    display: none;
  }
}
</style>
