<template>
	<div class="teadtl">
		<!-- <div class="top" @click="backTop">
		  <div class="divg">
			<img
			  style="width: 100%; height: 100%"
			  src="../../assets/image/shang.png"
			  alt=""
			/>
		  </div>
		</div> -->
		<!-- <BackTop></BackTop> -->

		<div class="eblmax">
			<div class="mianb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/teachers2' }">师资</el-breadcrumb-item>
					<el-breadcrumb-item>{{data.name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="dTals" :class="{'dTalsHH':data.pinyin.length>8}">
				<div class="pinyin">{{data.pinyin}}</div>
				<div class="inner">
					<div class="left">
						<div class="mes">
							<div class="avatar_box">
								<div class="avatar_img">
									<img :src="data.imgUrl" style="width: 170px;" />
								</div>
								<img src="https://images.hzmedia.com.cn/h5/uniapp/static/images_teacher_new/detail_left_at_bg.jpg"
									class="bottom_bg" />
							</div>
							<p class="name">{{data.name}}</p>
							<p class="title" v-html="data.title.replace(/\r\n/g,'<br>')"></p>
							<p class="classfy" v-if="data.classifyList && data.classifyList.length>0">
								<span v-for="(item,index) in data.classifyList">{{item.name}}</span>
							</p>
						</div>
					</div>

					<!-- 所授课程 -->
					<div class="right">
						<div class="box" v-if="data.courseList">
							<div class="title">
								<span>所授课程</span>
							</div>

							<div class="course_list" :class="{'fold':fold && data.courseList.length>5}">
								<div class="text" v-for="(item,index) in data.courseList" @click="goToCourse(item)">
									<span>▶ {{item.title}}</span>
								</div>
							</div>
							<div class="showmore" @click="showMore" v-if="fold && data.courseList.length>5">
								展开查看更多 <i class="el-icon-arrow-down" style="margin-left: 3px;"></i>
							</div>
						</div>

						<!-- 讲师简介 -->
						<div class="box"  v-if="data.desc">
							<div class="title">
								<span>
									讲师简介
								</span>
							</div>

							<div class="desc" v-html="data.desc.replace(/\r\n/g,'<br>')"></div>
						</div>

						<!-- 服务企业 -->
						<div class="box" v-if="data.serviceCompany">
							<div class="title">
								<span>
									服务企业
								</span>
							</div>

							<div class="desc">
								<span class="dot"></span>
								{{data.serviceCompany}}
							</div>
						</div>

						<!-- 讲师风采 -->
						<div class="box" v-if="coursefCList && coursefCList.length>0">
							<div class="title">
								<span>
									讲师风采
								</span>
							</div>

							<div class="show-list">
								<div class="item" v-for="(item,index) in coursefCList">
									<div class="vplayer"></div>
									<div class="vtitle"><span>▶</span> {{item.title}}</div>
								</div>
							</div>
						</div>

						<!-- 代表著作 -->
						<div class="box" v-if="data.bookList && data.bookList.length>0">
							<div class="title">
								<span>
									代表著作
								</span>
							</div>

							<div class="book_list">
								<div class="item" v-for="(item,index) in data.bookList">
									<img class="cover" :src="item.img" />
									<p class="title">{{item.name}}</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<foo-ter />
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	
	export default {
		data() {
			return {
				id: "",
				data: {},
				coursefCList:[],
				fold: true,
			};
		},
		components: {
			fooTer
		},
		// mounted() {
		//   window.addEventListener("scroll", this.scrollToTop);
		// },
		// destroyed() {
		//   window.removeEventListener("scroll", this.scrollToTop);
		// },
		created() {
			document.title = "师资 - 机工新阅读";
			var option = {
				id: this.$route.query.id || "",
			};
			if (option && option.id) {
				this.id = option.id;
				this.setupDetailData();
			}
		},
		methods: {
			showMore(){
				this.fold = false;
			},
			goToCourse(item) {
				localStorage.setItem("toPagePath", "Course2Detail");
				var url = "Course2Detail";
				var query = {
					title: item.title,
					id: item.id,
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			//Detail
			setupDetailData() {
				var that = this;
				var url = apiUrl.lecturer_get_detail;
				var param = { id: this.id };
				this.Ajax(url, param, (data) => {
					if (data) {
						that.data = data;
						document.title = data.title + " - 师资 - 机工新阅读";
						
						that.coursefCList =[];
						if (data && data.courseList) {
							data.courseList.forEach((item,index) => {
								if(item.demo!=""){
									that.coursefCList.push(item);
								}
							})
						}
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	.teadtl {
		width: 100%;
		background: #f7f7f7;
		min-width: 1157px;
		position: relative;
		cursor: default;


		.popContainer {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 999;

			.maxmage {
				width: 400px;
				height: 500px;
				background: #fff;
				margin: 0 auto;
				margin-top: 200px;
			}
		}

		.eblmax {
			width: 1157px;
			// border: 1px solid red;
			margin: 0 auto;

			.mianb {
				width: 1157px;
				height: 75px;
				// border: 1px solid red;
				margin: 0 auto;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #363636;

				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #e6554d !important;
				}
			}

			.dTals {
				width: 1157px;
				min-height: 100vh;
				height: auto;
				display: flex;
				background: #fff;
				position: relative;
				
				&.dTalsHH{
					min-height: 1300px !important;
				}	
				.pinyin {
					font-size: 150px;
					color: #FFEBEC;
					font-weight: 800;
					writing-mode: vertical-rl;
					transform: rotate(-180deg);
					position: absolute;
					right: -54px;
					top: 0;
				}
					
					
				.inner {
					display: flex;
					flex-direction: row;

					.left {
						width: 300px;
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 65px;

						.mes {
							width: 200px;
							display: flex;
							flex-direction: column;

							.avatar_box {
								width: 170px;
								height: 170px;
								position: relative;
								margin-bottom: 20px;

								.avatar_img {
									width: 170px;
									height: 170px;
									overflow: hidden;
									z-index: 10;
									background-color: #ccc;
									border: 1px solid #eee;
									position: absolute;
									left: 0;
									top: 0;

									img {
										width: 170px;
									}
								}

								.bottom_bg {
									width: 170px;
									position: absolute;
									right: -10px;
									bottom: -10px;
									z-index: 9;
								}
							}

							.name {
								font-size: 30px;
								color: #E4574E;
								font-weight: bold;
							}

							.title {
								font-size: 13px;
								color: #000;
								margin: 10px 0;
								line-height: 1.75;
							}

							.classfy {
								span {
									font-size: 12px;
									border: 1px solid #E4574E;
									padding: 2px 5px;
									box-sizing: border-box;
									color: #E4574E;
									border-radius: 4px;
									margin-right: 4px;
									margin-bottom: 4px;
								}
							}
						}
					}


					.right {
						flex: 1;
						display: flex;
						flex-direction: column;
						padding: 65px;
						position: relative;
						
					

						.box {
							display: flex;
							flex-direction: column;
							margin-bottom: 20px;

							.title {
								width:100%;
								display: flex;
								flex-direction: row;

								span {
									border-left: 3px solid #E4574E;
									border-right: 3px solid #E4574E;
									padding: 0 10px;
									line-height: 1;
									font-weight: bold;
									color: #333;
									font-size: 20px;
									display: inline-block;
								}
							}



							.desc {
								font-size: 16px;
								margin: 15px 0;
								text-align: justify;
								line-height: 1.75;
								padding: 5px;
								box-sizing: border-box;

								.dot {
									display: inline-block;
									width: 8px;
									height: 8px;
									background-color: #E4574E;
									border-radius: 5px;
									margin-right: 5px;
								}
							}

							.course_list {
								display: flex;
								flex-direction: column;
								font-size: 16px;
								margin: 15px 0;
								
								&.fold {
									height: 160px;
									overflow: hidden;
								}

								.text {
									color: #E4574E;
									margin-bottom: 10px;
									cursor: pointer;

									span {
										display: inline-block;
										border-bottom: 1px solid #E4574E;
										line-height: 1.3;
									}
								}
							}
							
							.showmore {
								font-size: 13px;
								padding:10px 3px 3px 0;
								color: #333;
								opacity: 0.8;
							}

							.show-list {
								font-size: 16px;
								margin: 15px 0;
								text-align: justify;
								line-height: 1.75;
								padding: 5px;
								box-sizing: border-box;
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;

								.item {
									width: 50%;
									display: flex;
									flex-direction: column;
									margin-bottom: 10px;
									width: 50%;
									display: flex;
									flex-direction: column;
									margin-bottom: 10px;

									&:nth-of-type(1) {
										width: 100%;

										.player {
											width: 640px;
											height: 364px;
										}

										.title {
											width: 640px;
										}

									}

									.player {
										width: 300px;
										height: 171px;
										border: 2px solid #E4574E;

									}

									.v_title {
										font-size: 14px;
										margin-top: 10px;
										width: 300px;


										span {
											color: #E4574E;
										}
									}
								}

							}


							.book_list {
								display: flex;
								flex-wrap: wrap;
								padding: 25px 5px;

								.item {
									display: flex;
									flex-direction: column;
									width: 144px;
									margin: 0 35px 35px 0;

									.cover {
										width: 144px;
										box-shadow: 0 2px 6px #ccc;
									}

									.title {
										font-size: 12px;
										border-left: 2px solid #595757;
										color: #595757;
										padding-left: 5px;
										line-height: 1.3;
										margin-top: 8px;
										max-height: 28px;
										overflow: hidden;
									}
								}

							}
						}
					}
				}
			}
		}
	}
</style>
