<template>
	<div class="coraudio">
		<div class="audmax">
			<div class="mianb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">机工新阅读</el-breadcrumb-item>
					<el-breadcrumb-item><a href="/course">课程</a></el-breadcrumb-item>
					<el-breadcrumb-item>{{ detailData.title }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="moltop">
				<div class="cents">
					<div class="cLft">
						<div class="lftmg">
							<img
								:src="detailData.img_url"
								style="width: 100%; height: 100%; object-fit: cover"
								alt="" />
						</div>
					</div>
					<div class="ccent">
						<!-- {{ detailData.title }} -->
						<h3>{{ detailData.title }}</h3>
						<div class="span1">
							{{ detailData.summary }}
						</div>
						<!-- <div class="whitx">
              <span class="span4">购买 ¥ {{ detailData.price }}</span>
              <span class="span5">¥ {{ detailData.line_price }}</span>
            </div> -->
						<!-- <div class="blak">
              开通VIP
            </div> -->
						<!-- <span class="span2">（VIP会员电子书、有声书免费学习）</span> -->
					</div>
					<div class="cRgt">
						<div class="toplogo">
							<!-- <div class="xue">
                <img
                  src="../../assets/image/icon8.png"
                  style="width:100%;height:100%"
                  alt=""
                />
              </div>
              <div class="adds">
                <img
                  src="../../assets/image/icon_7.png"
                  style="width:100%;height:100%"
                  alt=""
                />
              </div> -->
						</div>
						<div class="handen">
							<div class="minls">
								播放次数: <span class="spanz">{{ detailData.view_count }}</span>
							</div>
							<div class="pepo">
								有<span class="spanv">{{ detailData.readers_count }}</span>人正在学此课程：

								<div
									class="namemg"
									@click="goToPersonPage(detailData.id)"
									v-if="
                    detailData.readers_list &&
                      detailData.readers_list.length > 0
                  ">
									<div
										class="mgs"
										v-for="(item, index) in detailData.readers_list"
										:key="index">
										<img
											:src="item.avatar"
											style="width: 100%; height: 100%; border-radius: 50%"
											alt="" />
									</div>
								</div>
								>
							</div>
						</div>
					</div>
				</div>
				<div class="hongb">
					<div class="qrcode">
						<div class="qr-code" id="qrcode_id" ref="qrcode_id"></div>
					</div>
					<div class="p1">微信学习</div>
					<div class="p2">{{ detailData.title }}</div>
				</div>
				<!-- <div class="zxsm">
          <img class="mlsa" src="../../assets/image/loms.png" alt="" />
          <p>扫码</p>
          <p>在线</p>
          <p>学习</p>
          <div class="saomaBg" v-if="detailData.qrcode_url">
            <div class="qrBg">
              <img class="saoma" src="../../assets/image/saoma.png" alt="" />
              <div class="qrcode" id="qrcode_id" ref="qrcode_id"></div>
              <div class="foas">
                <p class="pm">扫描二维码</p>
                <p class="pn">在线学习课程</p>
              </div>
            </div>
          </div>
        </div> -->
			</div>
			<div class="molaudio">
				<div class="audotop">
					<div class="divv">课程学习</div>
					<el-button
						@click="drawer = true"
						type="primary"
						style="background: #f7f7f7; border: 1px solid #f7f7f7;margin-bottom:10px">
						<div class="iconts">
							<img
								style="width: 25px; height: 25px"
								src="../../assets/image/icon_10.png"
								alt="" />
							<span>目录</span>
						</div>
					</el-button>
					<el-drawer
						title="我是标题"
						:visible.sync="drawer"
						:with-header="false"
						style="min-width:500px;">
						<div class="popupTitle">
							<div class="diva">目录</div>
							<div class="divb" @click="changeSortType">
								<div class="dism">排序</div>
								<div class="divimg">
									<img
										class="ilmge"
										:class="sort == 0 ? '' : 'sort2'"
										src="../../assets/image/colors.png"
										alt="" />
								</div>
							</div>
						</div>
						<div class="fenj"></div>
						<div class="catalogue">
							<div
								class="losl"
								v-for="(item, index) in resourceData.list"
								:key="index"
								:class="{ 'actived':item.resource_id == selectResource.resource_id }"
								@click="clickCatalogue(index)">
								<span class="spone">{{ item.title }}</span>
								<!-- <span class="sptow" v-if="item.is_try==1">试读</span> -->
							</div>
						</div>
					</el-drawer>
				</div>
				<!--  -->
				<div class="libys" v-if="nowResource && nowResource.resource_type==3">
					<div class="titlms">{{ nowResource.title }}</div>
					<div class="movis">
						<video-player
							class="video-player"
							ref="videoPlayer"
							:playsinline="true"
							:options="playerOptions"
							@canplay="canplayVideoPlayer"
							@play="playVideoPlayer"
							@pause="pauseVideoPlayer"
							@ended="stopVideoPlayer"
							@ready="readyVideoPlayer"
							@timeupdate="videoTimeupdate"
							@statechanged="videoStateChanged">
						</video-player>
					</div>
				</div>
				<div class="xingq">
					<h5>详情</h5>
					<div class="visa">
						<div
							class="vis1"
							v-for="(ite, inm) in detailData.product_classes"
							:key="inm"
							@click="goToAllBookPage(ite)">
							{{ ite.title }}
						</div>
					</div>
					<div class="coms" v-html="detailData.desc"></div>
				</div>
			</div>
		</div>

		<div class="aplayer" v-if="nowResource && nowResource.resource_type==2">
			<div class="box">
				<audio style="margin-top: -9999999; display: none;"
					ref="audio"
					@canplay="canplayFunc"
					@timeupdate="timeupdateFunc"
					@play="paused=false"
					@pause="paused=true"
					@ended="onendedFunc"
					@seeked="seek=false"
					@seeking="seek=true"
					:autoplay="playaudio"
					:src="nowResource.audio_url"
					controls
					oncontextmenu="return false"
					controlslist="nodownload">
				</audio>
				<div class="control">
					<img src="../../assets/image/up.png" alt="" width="15px" @click="prev" />
					<img src="../../assets/image/audio.png" alt="" width="40px" v-if="paused" @click="clickFunc('play')" />
					<img src="../../assets/image/pause.png" alt="" width="40px" v-if="!paused" @click="clickFunc('pause')" />
					<img src="../../assets/image/down.png" alt="" width="15px" @click="next" />
				</div>
				<div class="cover">
					<img :src="detailData.img_url_compressed" style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;" />
					<img class="icon" src="../../assets/image/ermai.png" alt="" />
				</div>
				<div class="wrapper">
					<div class="title">{{nowResource.title}}</div>
					<div class="slider" @mousedown="seek=true" @mouseup="seek=false">
						<span class="timebar">{{format(current)}}</span>
						<el-slider v-model="current" :min="0" :max="duration" :format-tooltip="format" @change="sliderChange"></el-slider>
						<span class="timebar" v-if="duration != null">{{format(duration)}}</span>
						<span class="timebar" v-if="duration == null && nowResource && nowResource.audio_length">{{nowResource.audio_length}}</span>
					</div>
				</div>
				<div class="btns">
					<el-popover placement="top" v-model="popover">
						<ul>
							<li v-for="item in playbackRates" @click="speedChange(item)" :class="{'actived':item == playSpeed}">
								<span v-if="item != '1.0'">{{item}}x</span>
								<span v-if="item == '1.0'">正常倍数</span>
							</li>
						</ul>
						<div class="play-rate" slot="reference">倍数 {{playSpeed}}x</div>
					</el-popover>
				</div>
				<div class="catalogR" @click="drawer = !drawer">
					<img src="../../assets/image/icon_10.png" alt="" />
					<span>共{{detailData.resource_count}}章</span>
				</div>

			</div>
		</div>

		<foo-ter />
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import { videoPlayer } from "vue-video-player";


	export default {
		data() {
			return {
				PopBoxheight: "",
				detailSearch: {
					id: null,
				},
				detailData: {
					status: {},
				},
				resourceSearch: {
					product_id: null,
					page_index: 1,
					page_size: 10000,
					order_by: "created_at:asc",
					resource_type: 0, //资源类型，0 全部，1 图文，2 音频，3 视频
					is_try: -1, //是否试读，0 否，1 是，-1 全部
				},
				resourceData: {
					list: [],
				}, //播放资源
				idPlan: 0,
				current: 0, //当前进度(s)
				duration: 0, //总时长(s)
				paused: true, //是否处于暂停状态
				seek: false, //是否处于拖动状态
				playSpeed: '1.0',
				continue: true,
				playaudio: false,
				playbackRates: ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2.0'], //播放速度
				autoplayBySrcChange: false,
				nowResource: {}, //正在播放资源
				yunAddIcon: "../../static/images/icon_7.png",
				yunDelIcon: "../../static/images/studyAC.png",
				selectResource: {},
				drawer: false,
				popover: false,
				sort: 0,
				// search: {
				bookId: "",
				// },
				detailData: [],
				sort: 0,
				playerOptions: {
					playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4",
						src: "", //你的视频地址
					}, ],
					poster: "", //你的封面地址
					width: document.documentElement.clientWidth,
					notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						// timeDivider: false,
						// durationDisplay: false,
						remainingTimeDisplay: true,
						fullscreenToggle: true, //全屏按钮
					},
					video_url: "",
				},
				read_timestamp: Date.parse(new Date()),
				videoPlayTime: 0,
				isVideoPlay: false,
				isLogin: false
			};
		},
		components: {
			fooTer,
			videoPlayer,
			qrcode: QRCode,
		},
		computed: {},
		created() {
			this.isLogin = sessionStorage.hasOwnProperty("userLogInInfo");
			document.title = "课程 - 机工新阅读";
			var option = {
				id: this.$route.query.id || "",
			};
			var that = this;
			if (option && option.id) {
				this.detailSearch.id = option.id;
				this.setupDetailData(() => {
					that.setupResourceData();
				});
			}
		},
		mounted() {
			//添加监听
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('监听 beforeunload')
		},
		destroyed() {
			//解除监听
			window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('解除 beforeunload')
		},
		methods: {
			//页面退出时提交下记录
			beforeunloadHandler(e) {
				this.stopPlayer();
			},
			clickFunc(val) {
				if (val == 'play') {
					this.paused = false;
					this.seek = false;
					if (this.nowResource && this.nowResource.curr_time > 0) {
						this.$refs.audio.currentTime = this.nowResource.curr_time;
						this.nowResource.curr_time = 0;
					}
					this.$refs.audio.play();
					this.read_timestamp = Date.parse(new Date());
				} else if (val == 'pause') {
					this.paused = true;
					this.$refs.audio.pause();
					this.stopPlayer();
				} else if (val == 'speed') { //减慢音频播放速度，速率范围[0.5，3]
					if (this.$refs.audio.playbackRate < 3) {
						this.$refs.audio.playbackRate += 0.5;
						var num = new Number(this.$refs.audio.playbackRate);
						this.playSpeed = num.toFixed(1);
					} else if (this.$refs.audio.playbackRate == 3) {
						this.$refs.audio.playbackRate = 0.5;
						var num = new Number(this.$refs.audio.playbackRate);
						this.playSpeed = num.toFixed(1);
					}
				} else if (val == 'refresh') {
					this.$refs.audio.playbackRate = 1;
					this.playSpeed = 1;
					this.$refs.audio.load();
					this.$refs.audio.play();
				}
			},
			timeupdateFunc(e) {
				if (!this.seek) {
					this.current = parseInt(e.target.currentTime);
				}
			},
			canplayFunc(e) {
				console.log("canplayFunc")
				var that = this;
				if (e.target.duration != null) {
					that.duration = parseInt(e.target.duration);
				} else {
					that.duration = that.nowResource.audio_length;
				}
				that.current = that.nowResource.curr_time;

				if (that.autoplayBySrcChange) {
					setTimeout(function() {
						that.clickFunc('play');
					}, 100);
				}
			},
			onendedFunc(e) {
				console.log("播放完毕")
				var that = this;
				if (that.continue) {
					that.current = 0;
					that.next();
				} else {
					that.paused = true
					that.current = 0;
				}
			},
			//格式化时长
			format(num) {
				if (num == 0) {
					return "00:00:00";
				} else {
					return '0'.repeat(2 - String(Math.floor(num / 3600)).length) + Math.floor(num / 3600) + ':' + '0'.repeat(2 - String(Math.floor((num % 3600) / 60)).length) + Math.floor((num % 3600) / 60) + ':' + '0'.repeat(2 - String(
						Math.floor((num % 3600) % 60)).length) + Math.floor((num % 3600) % 60)
				}
			},
			//播放倍数调整
			speedChange(value) {
				this.playSpeed = value;
				var num = new Number(value);
				this.$refs.audio.playbackRate = num;
				console.log("倍数：" + this.$refs.audio.playbackRate);
				this.popover = false;
			},
			//滑块变动
			sliderChange(value) {
				this.seek == false;
				this.current = value;
				this.$refs.audio.currentTime = this.current;
				this.$refs.audio.play();
			},
			canplayVideoPlayer(player) {
				let video = document.getElementsByClassName('vjs-tech')[0];
				//video.setAttribute('controls', 'controls');
				video.setAttribute('oncontextmenu', 'return false');
				video.setAttribute('controlslist', 'nodownload');
			},
			readyVideoPlayer(player) {
				player.currentTime(this.nowResource.curr_time);
				this.isVideoPlay = false;
			},
			//视频开始播放
			playVideoPlayer(player) {
				this.read_timestamp = Date.parse(new Date());
				this.isVideoPlay = true;
			},
			//视频暂停
			pauseVideoPlayer(player) {
				console.log("视频暂停")
				if (!this.videoPlayTime || this.videoPlayTime == "") return;
				this.stopPlayer();
				this.isVideoPlay = false;
			},
			//视频播放结束
			endVideoPlayer(player) {
				console.log("视频播放结束")
				this.next();
				this.current = 0;
			},
			videoTimeupdate(player) {
				this.videoPlayTime = parseInt(player.cache_.currentTime);
			},
			videoStateChanged(playerCurrentState) {
				console.log('player current update state', JSON.stringify(playerCurrentState))
			},
			//Page: AllBook
			goToAllBookPage(item) {
				localStorage.setItem("toPagePath", "course");
				var query = {
					type: 4,
					level: item.level,
					id: item.id,
				};
				this.GoToAllBook(query);
			},
			//Page: Read personList
			goToPersonPage() {
				localStorage.setItem("toPagePath", "course");
				var url = "seebook";
				var query = {
					product_type: this.detailData.product_type,
					product_name: this.detailData.title,
					id: this.detailData.id,
				};

				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				// this.$router.push({
				//   path: url,
				//   query: query,
				// });
			},
			//Detail
			setupDetailData(callback) {
				var that = this;
				var url = apiUrl.column_detail_get;
				var param = that.detailSearch;
				this.Ajax(url, param, (data) => {
					that.detailData = data;
					document.title = data.title + " - 课程 - 机工新阅读";
					if (data.qrcode_url && data.qrcode_url != "") {
						// setTimeout(() => {
						that.setupQrCode(data.qrcode_url);
						// }, 500);
					}
					
					if (callback) callback();

				});
			},
			//QrCode
			setupQrCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			//Resource
			setupResourceData(unAutoPlay) {
				//资源列表
				var that = this;
				that.resourceSearch.product_id = that.detailSearch.id;
				var url = apiUrl.resource_list_get;
				var param = that.resourceSearch;
				this.Ajax(url, param, (data) => {
					if (data) {
						if (that.resourceSearch.page_index == 1) {
							that.resourceData = data;
							if (unAutoPlay) {
								return
							}
							//历史记录
							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id != '') {
								for (let i = 0; i < that.resourceData.list.length; i++) {
									if ((that.resourceData.list[i].is_try == 1 || that.resourceData.list[i].is_available == 1) && that.resourceData.list[i].resource_id == that.detailData.resource_id) {
										that.nowResourceDataGet(i);
										return;
									}
								}
							}


							//试听内容
							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id == '') {
								for (let i = 0; i < that.resourceData.list.length; i++) {
									if (that.resourceData.list[i].is_try == 1 || that.resourceData.list[i].is_available == 1) {
										that.nowResourceDataGet(i);
										return;
									}
								}
								that.nowResourceDataGet(0);
							}
						} else {
							that.resourceData.list.push.apply(that.resourceData.list, data.list);
							if (that.detailData && that.detailData.resource_id && that.detailData.resource_id != '') {
								data.list.forEach((item, index) => {
									if ((item.is_try == 1 || item.is_available == 1) && item.resource_id == that.detailData.resource_id) {
										var num = parseInt(item.num) - 1;
										that.nowResourceDataGet(num);
										//that.loading = false;
										return;
									}
								});
							}
						}
					}
				});
			},
			//Current Resource Detail
			nowResourceDataGet(index) {
				//获取正在播放的资源
				var that = this;
				var resource = that.resourceData.list[index];
				if (!resource) return;
				this.selectResource = resource;
				var url = apiUrl.resource_detail_get;
				var param = {
					product_id: that.detailSearch.id,
					resource_id: resource.resource_id,
				};
				this.Ajax(url, param, (data) => {
					if (data) {
						if (data.is_try == 1 || data.is_available == 1) {
							that.idPlan = index;
							if (that.playaudio) {
								data.curr_time = 0;
							}
							that.nowResource = data;

							if (data.resource_type == 3) {
								that.playerOptions.poster = data.img_url;
								that.playerOptions.sources[0].src = data.video_url;
								that.playerOptions.video_url = data.video_url;
								if (that.autoplayBySrcChange) {
									that.playerOptions.autoplay = true;
								}
							}
						}
					}
				});
			},
			//Click
			clickAudioResource(index) {
				//试听目录切换试听
				var that = this;
				var resource = that.resourceData.list[index];

				if (resource.is_try != 1 && resource.is_available != 1) {
					//不可试听
					this.$alert('登录并订阅后才能播放', '错误', {
						confirmButtonText: '确定',
						callback: action => {
							// this.$message({
							//   type: 'info',
							//   message: `action: ${ action }`
							// });
						}
					});
				} else {
					that.autoplayBySrcChange = true;
					that.nowResourceDataGet(index);

					that.read_timestamp = Date.parse(new Date());
				}
			},
			clickCatalogue(index) {
				if (this.selectResource.resource_type == 2) {
					if (!this.paused) {
						this.stopPlayer();
					}
				} else if (this.selectResource.resource_type == 3) {
					if (this.isVideoPlay) {
						this.stopPlayer();
					}
				}
				this.clickAudioResource(index);
				this.drawer = false;
				this.playaudio = false;
			},
			//Prev
			prev() {
				this.stopPlayer();
				//上一章
				if (this.idPlan > 0) {
					this.playaudio = true;
					this.clickAudioResource(this.idPlan - 1);
				} else {
					// uni.showToast({
					// 	title: "已经是第一章了.",
					// 	icon: 'none',
					// 	duration: 2000
					// });
				}
			},
			//Next
			next() {
				if (this.selectResource.resource_type == 2) {
					this.stopPlayer();
				} else if (this.selectResource.resource_type == 3) {
					if (this.isVideoPlay) {
						this.stopPlayer();
					}
				}

				//下一章
				if (this.idPlan < this.resourceData.list.length - 1) {
					this.playaudio = true;
					this.clickAudioResource(this.idPlan + 1);
				} else {
					//已经是最后一章了
					// uni.showToast({
					// 	title: "已经是最后一章了.",
					// 	icon: 'none',
					// 	duration: 2000
					// });
				}
			},
			//Stop
			stopPlayer() {
				var that = this;
				if (this.isLogin) {
					if (this.selectResource && this.selectResource.resource_type && this.selectResource.product_id && this.selectResource.resource_id) {
						var now_timestamp = Date.parse(new Date()); //当前时间戳
						var duration = (now_timestamp - this.read_timestamp) / 1000; //阅读时间，单位秒
						var curr_time = 0;
						if (this.selectResource.resource_type == 2) {
							curr_time = this.$refs.audio.currentTime;
						} else if (this.selectResource.resource_type == 3) {
							curr_time = this.videoPlayTime;
						}
						var param = {
							product_id: this.selectResource.product_id || "",
							resource_id: this.selectResource.resource_id || "",
							curr_time: parseInt(curr_time) || 0,
							duration: duration,
						}
						this.Ajax(apiUrl.resource_progress_update, param, function(data, code) {
							if (code == 0) {
								that.read_timestamp = Date.parse(new Date());
								console.log("提交记录")
							}
						});
					}
				}
			},
			//Click: Sort
			changeSortType() {
				if (this.resourceData.list) {
					this.sort = this.sort == 0 ? 1 : 0;
					this.resourceData.list = this.resourceData.list.reverse();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.coraudio {
		width: 100%;
		min-width: 1157px;
		background: #f7f7f7;
		cursor: default;

		position: relative;

		.audmax {
			width: 1157px;
			margin: 0 auto;

			.mianb {
				width: 1157px;
				height: 71px;

				margin: 0 auto;
				display: flex;
				align-items: center;

				// background: #fff;
				// .el-breadcrumb {
				//   font-size: 18px;
				// }
				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #e6554d !important;
				}
			}

			.moltop {
				width: 100%;
				height: 273px;
				border: 1px solid #fff;
				background: #fff;
				// border: 1px solid red;

				position: relative;
				cursor: default;

				.cents {
					width: 1026px;
					// height: 330px;
					// border: 1px solid red;
					margin: 45px 0 0 51px;
					display: flex;

					.cLft {
						height: 100%;
						width: 142px;

						.lftmg {
							width: 100%;
							height: 190px;
						}
					}

					.ccent {
						height: 100%;

						margin-left: 48px;

						h3 {
							width: 380px;

							font-size: 24px;
							font-family: PingFang-SC-Bold, PingFang-SC;
							font-weight: bold;
							color: #2b2b2b;
							letter-spacing: 1px;
							margin-bottom: 3px;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
						}

						.span1 {
							font-size: 18px;
							width: 280px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #c5c5c5;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
							-webkit-line-clamp: 2;
							margin-bottom: 5px;
						}

						.whitx {
							width: 290px;
							height: 74px;
							background: #ffffff;
							box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
							border-radius: 8px;
							margin-bottom: 12px;

							.span4 {
								font-size: 24px;
								font-family: PingFang-SC-Bold, PingFang-SC;
								font-weight: bold;
								color: #e6564e;
								line-height: 74px;
								letter-spacing: 1px;
								margin: 0 4px 0 28px;
							}

							.span5 {
								font-size: 18px;
								font-family: SFUDINMitAlt;
								color: #cccccc;
								line-height: 74px;
								letter-spacing: 1px;
								text-decoration: line-through;
							}
						}

						.blak {
							width: 290px;
							height: 74px;
							background: #e6564e;
							box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
							border-radius: 8px;
							margin-bottom: 11px;
							font-size: 24px;
							font-family: PingFang-SC-Bold, PingFang-SC;
							font-weight: bold;
							color: #ffffff;
							line-height: 74px;
							text-align: center;
						}

						.span2 {
							font-size: 15px;
							font-family: SFUDINMitAlt;
							color: #e6564e;
							line-height: 20px;
						}
					}

					.cRgt {
						//   width: 338px;
						height: 100%;

						margin-left: 90px;

						.toplogo {
							width: 100%;
							height: 48px;
							margin-bottom: 50px;
							border-radius: 12px;

							.xue {
								width: 48px;
								height: 100%;
								background: #000000;
								border-radius: 12px;
								margin-right: 42px;
								display: inline-block;
								margin-left: 210px;
							}

							.adds {
								width: 29px;
								height: 29px;

								float: right;
								margin-top: 8px;
							}
						}

						.handen {
							// width: 100%;

							.minls {
								font-size: 18px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #c5c5c5;
								letter-spacing: 1px;
								margin-bottom: 14px;

								.spanz {
									color: #000;
								}
							}

							.pepo {
								font-size: 18px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #c5c5c5;
								letter-spacing: 1px;
								display: flex;
								align-items: center;

								.spanv {
									color: #000;
								}

								.namemg {
									// width: 30px;
									height: 30px;
									width: 120px;
									cursor: pointer;
									overflow: hidden;

									.mgs {
										display: inline-block;
										width: 30px;
										height: 30px;
										border-radius: 50%;
										margin-right: 7px;
									}

									// img {
									//   margin-right: 7px;
									// }
								}
							}
						}
					}
				}

				.hongb {
					width: 162px;
					height: 178px;
					// border: 1px solid red;
					position: absolute;
					right: 60px;
					top: -20px;
					background-image: url("../../assets/image/hongb.png");
					background-size: 100%;

					.qrcode {
						width: 120px;
						height: 120px;
						border: 5px solid #fff;
						margin: 9px 0px 0 14px;

						.qr-code {
							width: 100%;
							height: 100%;

							//
						}
					}

					div {
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ffffff;

						letter-spacing: 2px;
						text-align: center;
					}

					.p1 {
						margin-top: 7px;
						// border: 1px solid red;
					}

					.p2 {
						text-align: center;
						width: 130px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin: 0 auto;

						// border: 1px solid red;
					}
				}

				// .zxsm {
				//   width: 70px;
				//   height: 150px;
				//   background: #e6564e;
				//   border-radius: 8px;
				//   position: fixed;
				//   right: 300px;
				//   top: 270px;
				//   // display: flex;
				//   // align-items: center;
				//   // justify-content: center;
				//   z-index: 9;
				//   .saomaBg {
				//     height: 245px;
				//     width: 200px;
				//     position: fixed;
				//     right: 370px;
				//     top: 260px;
				//     display: none;
				//     z-index: 9;
				//     .qrBg {
				//       width: 100%;
				//       height: 100%;
				//       position: relative;

				//       .saoma {
				//         width: 100%;
				//         height: 100%;
				//       }

				//       .qrcode {
				//         position: absolute;
				//         top: 30px;
				//         left: 30px;
				//         width: 130px;
				//         height: 130px;
				//       }
				//       .foas {
				//         // border: 1px solid red;
				//         width: 100%;
				//         // border: 1px solid red;
				//         position: absolute;
				//         bottom: 20px;
				//         right: 5px;

				//         p {
				//           text-align: center;
				//           font-size: 16px;
				//           font-family: PingFang-SC-Medium, PingFang-SC;
				//           font-weight: 500;
				//           color: #000000;
				//           letter-spacing: 1px;
				//         }
				//       }
				//     }
				//   }

				//   .mlsa {
				//     width: 37.4px;
				//     height: 37.4px;
				//     margin-top: 17px;
				//     margin-left: 17px;
				//   }
				//   p {
				//     font-size: 18px;
				//     font-family: PingFang-SC-Heavy, PingFang-SC;
				//     font-weight: 800;
				//     color: #ffffff;
				//     margin-left: 16px;
				//     letter-spacing: 3px;
				//   }
				//   &:hover {
				//     .saomaBg {
				//       display: block;
				//     }
				//   }
				// }
			}

			.molaudio {
				width: 100%;

				background: #fff;
				margin-top: 20px;

				// border: 1px solid #f7f7f7;
				.audotop {
					width: 100%;
					height: 62px;
					background: #f7f7f7;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 30px;

					/deep/ .el-drawer__body {
						min-width: 500px;
					}

					.divv {
						width: 200px;
						height: 100%;
						font-size: 24px;
						font-family: PingFang-SC-Heavy, PingFang-SC;
						font-weight: 800;
						color: #2b2b2b;
						letter-spacing: 1px;
						line-height: 62px;
						text-align: center;
						border-right: 50px solid transparent;
						border-bottom: 62px solid #fff;
						// background: red;

						//   border-right: 50px solid yellow;
					}

					.iconts {
						width: 113px;
						height: 48px;
						background: #ffffff;
						border-radius: 24px;
						display: flex;
						align-items: center;

						// background: red;
						img {
							margin-left: 20px;
							margin-right: 10px;
						}

						span {
							font-size: 20px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							color: #333333;
						}
					}

					.iconts:hover {
						cursor: pointer;
					}

					.popupTitle {
						width: 100%;
						height: 45px;

						margin-top: 80px;
						display: flex;
						justify-content: space-between;

						.diva {
							font-size: 24px;
							font-family: PingFang-SC-Bold, PingFang-SC;
							font-weight: bold;
							color: #333333;
							margin-left: 65px;
						}

						.divb {
							display: flex;
							align-items: center;
							margin-right: 61px;

							.dism {
								font-size: 20px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #e6564e;
							}

							.divimg {
								width: 18px;
								height: 25px;
								margin-left: 10px;

								.ilmge {
									width: 100%;
									height: 100%;
								}

								.sort2 {
									transform: rotate(180deg);
								}
							}
						}
					}

					.fenj {
						width: 460px;
						height: 2px;
						background: #eeeeee;
						margin: 0 auto;
						margin-top: 25px;
					}

					.catalogue {
						width: 460px;
						// min-width: 460px;
						//height: 610px;
						height: 700px;
						margin-left: 55px;
						margin-top: 40px;
						// border: 1px solid red;
						overflow-y: auto;
						padding-bottom: 55px;

						.losl {
							width: 100%;
							max-height: 60px;
							display: flex;
							justify-content: space-between;
							margin-bottom: 24px;
							cursor: pointer;

							&.actived {
								color: #e6554d;
							}

							.spone {
								font-size: 20px;
								line-height: 1.5;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
							}

							.sptow {
								width: 45px;
								height: 32px;
								font-size: 22px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #e6564e;
								line-height: 32px;
							}
						}
					}

					::-webkit-scrollbar {
						width: 0px;
					}

					.catRg {
						height: 35px;
						display: flex;
						align-items: center;
						margin-right: 45px;

						.ms2 {
							width: 38.4px;
							height: 38.4px;
							margin-right: 16px;
						}

						.pan2 {
							font-size: 20px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							color: #333333;
							line-height: 35px;
						}
					}
				}

				.libys {
					width: 100%;
					// background: yellow;
					margin-top: 20px;

					// margin-bottom: -20px;
					.titlms {
						width: 986px;
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #333333;
						letter-spacing: 1px;
						// background: turquoise;
						margin: 0 auto;
						margin-bottom: 22px;
					}

					.titlms:hover {
						cursor: default;
					}

					.movis {
						margin: 0 auto;
						width: 986px;
						height: 553px;
						background: #000000;
						border-radius: 6px;
						margin-bottom: 62px;
						position: relative;

						.video-player {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}

				.xingq {
					width: 986px;
					// height: 1000px;

					margin: 0 auto;

					// margin-top: -40px;
					h5 {
						font-size: 27px;
						font-family: PingFang-SC-Heavy, PingFang-SC;
						font-weight: 800;
						color: #2b2b2b;
						border: 1px solid #fff;
						// padding: 30px 0 0 30px;
						letter-spacing: 1px;
						// background: red;
					}

					h5:hover {
						cursor: default;
					}

					.visa {
						display: flex;

						.vis1 {
							// width: 113px;
							height: 38px;
							background: #ffffff;
							border-radius: 24px;
							text-align: center;
							line-height: 38px;
							margin: 20px 0px 20px 25px;
							padding: 0 15px;
							background: #f7f7f7;
						}
					}

					.visa:hover {
						cursor: pointer;
					}

					.coms {
						width: 100%;
						// height: 800px;
						margin-bottom: 80px;
						padding-bottom: 80px;
						// background: chocolate;
					}
				}
			}
		}

		:focus {
			outline: 0;
		}

		.aplayer {
			width: 100%;
			height: 66px;
			position: fixed;
			bottom: 0;
			left: 0;
			background-color: #ffffff;
			box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 9999999;


			.box {
				width: 1037px;
				margin: 0 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				position: absolute;

				.control {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 150px;

					img {
						cursor: pointer;
					}
				}

				.cover {
					width: 50px;
					height: 50px;
					cursor: pointer;
					margin: 0 40px;
					position: relative;
					border-radius: 50%;
					box-shadow: 0 0 4px rgba(0, 0, 0, .2);

					.icon {
						width: 20px;
						height: 20px;
						position: absolute;
						top: 15px;
						left: 15px;
					}
				}

				.wrapper {
					width: 560px;
					display: flex;
					flex-direction: column;

					.title {
						font-size: 15px;
					}

					.slider {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;

						.timebar {
							font-size: 14px;
							padding-top: -4px;
						}
					}
				}

				.btns {
					margin-left: 40px;
					margin-right: 10px;

					.play-rate {
						border: 1px solid #333;
						padding: 2px 5px;
						border-radius: 5px;
						font-size: 12px;
						cursor: pointer;
					}
				}

				.catalogR {
					margin-left: 40px;
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 20px;
						height: 20px;
					}

					span {
						font-size: 12px;
						margin-top: 3px;
					}
				}

			}

			/deep/.el-slider__button-wrapper {
				top: -18px;
			}

			/deep/.el-slider__button {
				border: 0 !important;
				cursor: pointer;
				background-color: rgb(255, 255, 255);
				box-shadow: 0 0 4px rgba(0, 0, 0, .2);
			}

			/deep/.el-slider__runway {
				height: 3px !important;
				background-color: rgb(233, 233, 233);
				width: 400px;
			}

			/deep/.el-slider__bar {
				background-color: #E7584E !important;
				height: 3px !important;

			}




		}

	}
</style>
<style>
	.el-popper {
		z-index: 100000000 !important;
		min-width: 100px !important;
		width: 100px !important;
	}

	.el-popper ul {
		margin: 0;
		padding: 0;
		list-style: none !important;
		text-align: center;
		font-size: 14px;
	}

	.el-popper ul li {
		line-height: 1.75;
		color: #515a6e;
	}

	.el-popper ul li:hover,
	.el-popper ul li.actived {
		color: #E7584E;
	}
</style>
